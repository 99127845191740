@import "./../../../../css/variable.scss";

.blur {
  opacity: 0.5;
}
.spinner {
  text-align: center !important;
  color: $primaryColor !important;
}
.albums-tab {
  padding-top: 2%;
  padding-bottom: 2%;
}

.albums-tab-container {
  padding-top: 2%;
}

.album-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.album-item .shade {
  background-color: #c4c4c4;
  margin-right: 1px;
}

.album-item .album {
  width: 100%;
  border-radius: 6px;
  background-color: #c4c4c4;
}

.album-item .album img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
  pointer-events: none;
}

.albums-tab-container .info {
  padding: 2% 5%;
}

.albums-tab-container .info #name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  color: #010d18;
  margin: 2px 0;
}

.albums-tab-container .info #count {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #6d787e;
}

.albums-tab-container .nomore-data-info {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
  font-weight: bold;
  overflow: hidden !important;
}
@media (min-width: 0) {
  .album-item .shade {
    width: 2px;
  }
  .album-item #shade-1 {
    height: 50px;
  }
  .album-item #shade-2 {
    height: 70px;
  }
  .album-item #shade-3 {
    height: 90px;
  }

  .album-item .album {
    height: 100px;
  }

  .albums-tab-container .info #name {
    font-size: 14px;
  }
  .albums-tab-container .info #count {
    font-size: 12px;
  }
  .albums-tab-container .info {
    padding: 3px 6px;
  }
}

@media (min-width: 400px) {
  .album-item .shade {
    width: 2px;
  }
  .album-item #shade-1 {
    height: 115px;
  }
  .album-item #shade-2 {
    height: 125px;
  }
  .album-item #shade-3 {
    height: 140px;
  }
  .album-item .album {
    height: 150px;
  }
}

@media (min-width: 600px) {
  .album-item .shade {
    width: 4px;
  }
  .album-item #shade-1 {
    height: 140px;
  }
  .album-item #shade-2 {
    height: 160px;
  }
  .album-item #shade-3 {
    height: 180px;
  }

  .album-item .album {
    height: 200px;
  }
  .albums-tab-container .info {
    padding: 6px 13px;
  }
  .albums-tab-container .info #name {
    font-size: 16px;
  }
  .albums-tab-container .info #count {
    font-size: 14px;
  }
}

@media (min-width: 900px) {
  .albums-tab-container .info #name {
    font-size: 17px;
  }
  .albums-tab-container .info #count {
    font-size: 15px;
  }
}

@media (min-width: 1000px) {
  .albums-tab-container .info #name {
    font-size: 18px;
  }
  .albums-tab-container .info #count {
    font-size: 16px;
  }
}
