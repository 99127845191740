@import "./../../../../css/variable.scss";

.videos-grid-container .videos-grid {
  padding: 20px 0;
}

.videos-grid-container .video-item {
  position: relative;
  cursor: pointer;
  background-color: #010d18;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin: 1% 0;
}

.videos-grid-container .video-item video {
  width: 100%;
  height: 100px;
}

.videos-grid-container .video-item img {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
}
.spinner {
  text-align: center !important;
  color: $primaryColor !important;
}

.nomore-data-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
  font-weight: bold;
  overflow: hidden !important;
}

@media (min-width: 400px) {
  .video-item video {
    height: 150px !important;
  }
  .video-item img {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
  }
}
@media (min-width: 500px) {
  .video-item video {
    height: 190px !important;
  }
  .video-item img {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
  }
}
@media (min-width: 600px) {
  .video-item video {
    height: 230px !important;
  }
  .video-item img {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
  }
}
@media (min-width: 800px) {
  .video-item video {
    height: 240px !important;
  }
  .video-item img {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
  }
}
@media (min-width: 900px) {
  .video-item video {
    height: 200px !important;
  }
  .video-item img {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 45px;
  }
}

@media (min-width: 1000px) {
  .video-item video {
    height: 200px !important;
  }
  .video-item img {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 45px;
  }
}
