.pli-media-grid {
  margin-left: -24px;
  margin-right: -24px;
}
#backIcon {
  width: 38px;
  height: auto;
  padding-right: 8px;
  cursor: pointer;
  object-fit: cover;
}
#nextIcon {
  width: 38px;
  height: auto;
  padding-left: 8px;
  cursor: pointer;
  object-fit: cover;
  background-size: auto;
}
.card-media {
  position: relative;
}
#pli-user-media {
  aspect-ratio: 26 / 17;
  object-fit: contain;
  background: #000;
  cursor: pointer;
}
.card-media-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background: rgba(1, 13, 24, 0.5);
  border-radius: 0 0 8px 8px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  color: #ffffff;
}
.mediaContainer {
  border-radius: 8;
  object-fit: cover;
}
.arrowStyle {
  cursor: pointer;
}
@media (min-width: 0) {
  .card-media {
    padding-top: 5px;
    margin-bottom: 5px;
  }
}

@media (min-width: 600px) {
  #backIcon {
    width: 30px;
    height: auto;
  }
  #nextIcon {
    width: 30px;
    height: auto;
  }
}
@media (max-width: 570px) {
  #backIcon {
    width: 30px;
    height: auto;
  }
  #nextIcon {
    width: 30px;
    height: auto;
  }
}
@media (max-width: 800px) {
  .card-media {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
@media (max-width: 1000px) {
  .card-media {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
