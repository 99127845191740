.photos-grid-container img {
  border-radius: 6px;
  cursor: pointer;
}

.photos-grid-container #album-details img {
  border-radius: 0;
}

.photos-grid-container .details-album-container img {
  border-radius: 0;
}

.spinner {
  text-align: center !important;
}

.nomore-data-info {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
}

#loading-palette {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 0) {
  #profile-image-list #profile-image-list-item {
    height: 100px !important;
    cursor: pointer;
  }
}
@media (min-width: 400px) {
  #profile-image-list #profile-image-list-item {
    height: 150px !important;
  }
}
@media (min-width: 500px) {
  #profile-image-list #profile-image-list-item {
    height: 190px !important;
  }
}
@media (min-width: 600px) {
  #profile-image-list #profile-image-list-item {
    height: 230px !important;
  }
}
@media (min-width: 800px) {
  #profile-image-list #profile-image-list-item {
    height: 240px !important;
  }
}
@media (min-width: 900px) {
  #profile-image-list #profile-image-list-item {
    height: 200px !important;
  }
  #profile-image-list {
    margin-top: 0;
  }
}

@media (min-width: 1000px) {
  #profile-image-list #profile-image-list-item {
    height: 200px !important;
  }
}
