@import "./../../css/variable.scss";

/*  Header Section */
#attraction-details {
  background-color: #fff;
}

#common-back-icon {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 80px;
  left: 20px;
  cursor: pointer;
  z-index: 1000;
}

#heading {
  background: #e0ebf0;
  box-shadow: 0px 4px 4px rgba(1, 13, 24, 0.1);
}

#heading-grid-root {
  height: 100%;
}

#cover-img-wrap {
  width: 100%;
  height: 100%;
}

#cover-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 2;
  object-fit: cover;
  pointer-events: none !important;
  box-shadow: -5px 3px 4px rgb(1 13 24 / 20%);
}

#discription-grid {
  height: 100%;
  padding: 0 15px;
  position: relative;
}

#bottom-grid-item {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#description-title {
  width: 100%;
  font-style: normal;
  font-weight: bold;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #010d18;
}

#short-description {
  color: #010d18;
  font-weight: 400;
  font-style: italic;
  line-height: 25px;
  text-align: center;
  padding-left: 10px;
}

#views {
  // background: #fff;
  border-radius: 3px;
}

#view-label {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
}

#lang-label {
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #000000;
  flex: 1;
}

#header-icon-group {
  align-items: center;
}

#flag-icon {
  object-fit: cover;
  cursor: pointer;
}

#viewicon {
  color: $primaryColor !important;
}

.save-icon-button {
  width: 40px;
  height: 40px;
  background-color: $primaryColor !important;
}

.save-icon-style {
  color: #fff !important;
}

/* Attraction Overview Section */
.bg-attraction {
  background-color: #fff;
}

.attraction-info {
  margin: 0% 8% 0% 8%;
  padding-top: 2%;
  padding-bottom: 2%;
}

#attraction-overview-container {
  display: flex;
  flex-wrap: wrap;
}

#attraction-overview-items {
  max-width: 550px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  align-content: center;
  padding: 10px 0;
}

#overview-icon-container {
  display: flex;
}

#overview-icon {
  font-size: 30px;
  color: $primaryColor;
}

#info-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.04em;
  padding-left: 10px;
  color: #010d18;
  font-size: 16px;
}

/* Description Section */
.attraction-description {
  margin: 0% 8% 4% 8%;
}

.gallery {
  text-align: center;
  align-content: center;
}

.gallery-title {
  font-size: 25px;
  text-transform: initial;
  font-weight: bold;
  margin: 2% 0 1%;
}

.gallery-img {
  border-radius: 5px;
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.attr-desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.04em;
  color: #000000;
  text-align: justify;
}

/* Attraction Gallery Section */
.titles {
  text-align: center;
  font-weight: bold;
}

/* Visitors Info Section */
.collapse-details-root {
  background-color: #fff !important;
  padding: 8px 16px 8px !important;
}

.collapse-box-shadow {
  box-shadow: none !important;
}

#visitors-info-collapse-bar {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  margin-top: 2%;
}

#visitors-info-bar {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 135.69%;
  letter-spacing: 0.04em;
  color: #010d18;
  text-align: left;
}

.visitors-info-item {
  display: flex;
  align-items: center !important;
  padding: 8px 0;
  text-align: left;
}

.visitors-info-icon {
  font-size: 30px !important;
  color: $primaryColor;
}

.visitors-info-label {
  font-weight: bold !important;
}

.visitors-info-item span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #010d18;
  padding-left: 10px;
}

.visitors-info-item span:first-child {
  text-transform: capitalize;
}

.history {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.04em;
  color: #000000;
  text-align: justify;
}

/* Related Article Section */
.related-articles-section {
  background: #fff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding: 1% 8% 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  background-color: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}

#carousel-card {
  box-shadow: none;
  background: none;
  padding: 0px 0% 0px 0%;
  cursor: pointer;
  overflow: visible;
}

.carousel-div {
  border-radius: 10px;
}

#recommend-save-icon {
  position: absolute;
  top: 5%;
  z-index: 1;
  right: 5%;
  cursor: pointer;
  background: $primaryColor;
}

.related-article-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
}

#article-box {
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  width: "100%";
  height: "100%";
}

#article-box:hover {
  transform: scale(1.1);
  overflow: visible;
  border-radius: 5px;
  font-size: 12px;
}

#article-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // backdrop-filter: blur(4px);
  padding: 0 5px;
  text-align: center !important;
  margin-bottom: 5px !important;
  background: $cardColor;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

#article-box:hover #article-overlay {
  opacity: 1;
}

#article-overlay p {
  margin: 0;
  letter-spacing: 0.04em;
  color: #ffff;
  text-align: left !important;
  padding-top: 15px;
}

#related-article-box-img {
  aspect-ratio: 4 / 3;
  pointer-events: none;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  position: relative;
  padding: 0px !important;
}

#article-title {
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 5px;
  background: $cardColor;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #fff;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

#related-article-box {
  &:global(.MuiCardContent-root) {
    padding: 0 !important;
  }
}
#related-article-box:hover #article-title {
  opacity: 0;
}

#img-protected {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Map Section */
.map {
  position: relative;
  width: "100%";
  height: 600px;
  background-color: #f2f1ee;
  background-size: cover;
  overflow: hidden;

  .button {
    position: absolute;
    bottom: 5%;
    right: 8%;
    z-index: 2;
  }
}

.map a {
  cursor: pointer;
  color: #000000;
}

.landmark-map-title {
  position: absolute;
  top: 9%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-shadow {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15px;
  pointer-events: none;
  background: linear-gradient(180deg, #f2f1ee, transparent) !important;
}

.bottom-shadow {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15px;
  pointer-events: none;
  background: linear-gradient(180deg, transparent, #f2f1ee) !important;
}

/* Landmark By Attraction */
#landmark-by-attraction {
  div > button:nth-last-child(1) {
    right: calc(6% + 1px) !important;
  }
}

#landmark-by-attraction {
  div > button {
    background-color: $primaryColor !important;
  }
}

#landmark-item-title {
  padding-bottom: 5%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  color: #010d18;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;
}

#landmark-item-desc {
  padding: 2px 0px;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  color: #000000;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.MuiSelect-select:focus {
  background-color: transparent !important ;
}

.MuiDialog-container .MuiPaper-root {
  /*background-color: transparent !important;*/
  border-radius: 6px;
}

#review-grid {
  padding: 20px 0;
  box-shadow:
    inset 0 2px 2px -1px rgba(1, 13, 24, 0.1),
    inset 0 -2px 2px -1px rgba(1, 13, 24, 0.1);
}

.attraction-details-root .review-section {
  padding-top: 20px;
}

.carousel-pad {
  height: 100%;
}

.alert-success {
  background-color: $secondaryColor !important;
}

@media (max-width: 1280px) {
  #article-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  #shortDestriptionMy {
    line-height: 25px;
  }
}

@media (min-width: 0) {
  /*  Header Section */
  #heading {
    height: 100%;
  }

  #description-grid {
    padding: 10px 15px;
  }

  #views {
    padding: 3px 7px;
  }

  #description {
    padding-top: 60px;
  }

  #description-title {
    font-size: 23px;
  }

  #short-description {
    font-size: 12px;
  }

  #viewicon {
    width: 17px;
    height: 17px;
  }

  #view-label {
    font-size: 10px;
  }

  #lang-label {
    font-size: 11px;
    // font-weight: bold;
  }

  #flag-icon {
    width: 20px;
    height: 16px;
  }

  .description-section {
    font-size: 13px;
  }

  /* Attraction Overview Section */
  #info-text {
    font-size: 12px;
  }

  /* Description Section */
  .attr-desc {
    font-size: 13px;
  }
  #visitors-info-bar {
    font-size: 15px;
  }
  .visitors-info-item span {
    font-size: 12px;
  }
  .related-articles-section #title {
    font-size: 17px;
  }
  #article-title {
    font-size: 13px;
  }
  .history {
    font-size: 13px;
  }

  .carousel-pad {
    margin: 10px 5px;
  }

  #article-overlay p {
    font-size: 12px;
  }

  /* Map Section */
  .titles {
    font-size: 20px;
  }

  /* Landmark By Attraction */
  #landmark-item-title {
    font-size: 14px;
  }

  #landmark-item-desc {
    font-size: 13px;
  }

  .related-article-title {
    font-size: 16px;
  }

  .button {
    width: 120px;
    height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 441px) {
  #description {
    padding-top: 40px;
  }
  /* Header Session */
  #description-title {
    font-size: 25px;
  }

  #short-description {
    font-size: 16px;
  }

  #viewicon {
    width: 19px;
    height: 19px;
    color: $primaryColor;
  }

  #lang-label {
    font-size: 13px;
  }

  #flag-icon {
    width: 22px;
    height: 18px;
  }

  .description-section {
    font-size: 14px;
  }

  .attraction-card {
    height: 140px;
    margin: 0 5px 20px 5px;
  }

  .business-nearby {
    font-size: 16px !important;
  }

  .nearby-attraction-title {
    font-size: 16px !important;
  }

  #label {
    font-size: 13px;
  }
}

@media (min-width: 600px) {
  #description {
    padding-top: 60px;
  }
  /* Attraction Overview Section */
  #info-text {
    font-size: 13px;
  }

  /* Description Section */
  .attr-desc {
    font-size: 14px;
  }

  #visitors-info-bar {
    font-size: 16px;
  }
  .visitors-info-item span {
    font-size: 13px;
  }
  .related-articles-section #title {
    font-size: 18px;
  }
  #article-title {
    font-size: 14px;
  }

  .history {
    font-size: 14px;
  }

  #article-overlay p {
    font-size: 14px;
  }

  /* Landmark By Attraction */
  #landmark-item-title {
    font-size: 15px;
  }

  #landmark-item-desc {
    font-size: 14px;
  }

  .carousel-pad {
    margin: 20px 10px;
  }
}

@media (min-width: 900px) {
  // #description {
  //   padding-top: 40px;
  // }
  // #bottom-grid-item {
  //   margin-top: 25px;
  // }

  #description-grid {
    height: 100%;
    padding: 30px 15px 50px;
    position: relative;
  }

  #bottom-grid-item {
    position: absolute !important;
    bottom: 2% !important;
    right: 3% !important;
  }

  #short-description {
    margin-bottom: 50px;
  }
}

@media (min-width: 1000px) {
  /*  Header Section */
  #description-grid {
    height: 100%;
    padding: 30px 15px 50px;
    position: relative;
  }

  #description {
    padding-top: 30px;
  }

  #bottom-grid-item {
    position: absolute !important;
    bottom: 2% !important;
    right: 3% !important;
  }

  /* Attraction Overview Section */
  #info-text {
    font-size: 14px;
  }

  /* Description Section */
  .attr-desc {
    font-size: 15px;
  }
  #visitors-info-bar {
    font-size: 17px;
  }
  .visitors-info-item span {
    font-size: 14px;
  }
  .related-articles-section #title {
    font-size: 19px;
  }
  #article-title {
    font-size: 15px;
  }

  .history {
    font-size: 15px;
  }

  /* Map Section */
  .titles {
    font-size: 25px;
  }

  /* Landmark By Attraction */
  #landmark-item-title {
    font-size: 17px;
  }

  #landmark-item-desc {
    font-size: 15px;
  }

  .related-article-title {
    font-size: 18px;
  }

  .button {
    width: 160px;
    height: 40px;
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  #description {
    padding-top: 0;
  }
  #description-grid {
    height: 100%;
    padding: 10px 15px 60px;
    position: relative;
  }
}

@media (min-width: 1300px) {
  #description {
    padding-top: 0;
  }
  /* Header Session */
  #description-grid {
    height: 100%;
    padding: 70px 15px 50px;
  }

  #description-title {
    font-size: 30px;
  }

  #short-description {
    font-size: 18px;
  }

  #view-label {
    font-size: 13px;
  }

  #lang-label {
    font-size: 13px;
  }

  #flag-icon {
    width: 24px;
    height: 20px;
  }

  #save-icon {
    width: 16px;
    height: 20px;
  }

  #share-icon {
    width: 22px;
    height: 22px;
  }

  /* Attraction Overview Section */
  #info-text {
    font-size: 16px;
  }

  /* Description Section */
  .attr-desc {
    font-size: 16px;
  }

  .description-section {
    font-size: 16px;
  }

  .attraction-card {
    height: 230px;
    margin: 0 15px 30px 15px;
  }

  .nearby-attraction-title {
    font-size: 20px !important;
  }

  #label {
    font-size: 16px;
  }

  #business-list-item {
    height: 220px !important;
  }

  #business-title {
    font-size: 30px;
  }

  .lineHight {
    line-height: 50px !important;
  }

  #item-bar div {
    font-size: 18px;
  }

  .seemore {
    width: 160px;
    height: 41px;
    font-size: 16px;
  }

  .nomore-data-info span {
    font-size: 14px;
  }

  #visitors-info-bar {
    font-size: 18px;
  }
  .visitors-info-item span {
    font-size: 16px;
  }
  .related-articles-section #title {
    font-size: 20px;
  }
  #article-title {
    font-size: 16px;
  }

  .history {
    font-size: 16px;
  }

  /* Landmark By Attraction */
  #landmark-item-title {
    font-size: 18px;
  }

  #landmark-item-desc {
    font-size: 16px;
  }
}

@media (min-width: 1600px) {
  .carousel-pad {
    margin: 40px 20px;
  }

  #article-overlay p {
    font-size: 16px;
  }
}
