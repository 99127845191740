@import './../../../../../css/variable.scss';

#mui-card-header .MuiCardHeader-action {
  align-self: center;
}

#mui-card-header {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.article-media {
  width: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  object-fit: cover;
  pointer-events: none;
  aspect-ratio: 26 / 17;
}
#mui-card-header .MuiCardHeader-avatar {
  margin-right: 5px;
}
#mui-card-header #more-vert-icon {
  font-size: 1.25rem;
}

.article-header-title {
  font-family: 'roboto';
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: #000000;
}
.article-title {
  font-family: 'roboto';
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  color: #010d18;
  margin: 0;
  cursor: pointer;
}
#protected-img-box {
  position: relative;
  cursor: pointer;
}
#protected-img {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
#article-footer {
  padding-left: 0;
  padding-right: 0;
}
.save-icon-button {
  width: 40px;
  height: 40px;
  background-color: $primaryColor !important;
}
.mui-save-icon-style {
  color: #ffffff;
  cursor: pointer;
}

.mui-save-icon-style-filled {
  color: #fff;
  cursor: pointer;
}
#article-footer-span {
  font-family: 'roboto';
  font-style: normal;
  line-height: 18px;
}

.alertColor {
  width: 100%;
  background-color: #e68b2e !important;
}

.sharedStyle {
  padding-left: 8%;
  padding-right: 8%;
  margin-bottom: 10px;
  box-shadow: none !important;
  background-color: rgba(185, 209, 231, 0.15);
}
.root {
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 3%;
  border: 1px solid $muteColor;
  box-shadow: 0px 0px 4px rgba(1, 13, 24, 0.1) !important;
  //marginRight: theme.spacing(13),
}article-header-title

.dropdownStyle {
  margin-right: -12px;
  align-self: center;
}
.menuItemStyle {
  display: flex;
}
.menuIconSm {
  width: 13px;
}
.menuIconStyle {
  width: 17px;
}
.articleTitleContainer {
  text-align: center;
}
.actionIconSm {
  cursor: pointer;
}
.shareIcon {
  flex: 1;
  display: flex;
}
.content {
  padding: 10px 0 10px 0 !important;
  cursor: pointer;
}

@media (min-width: 0) {
  #mui-card-header {
    padding-top: 10px;
  }
  #pli-avatar {
    width: 35px;
    height: 35px;
    padding: 0 2px;
    background-color: $primaryColor;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  #pli-avatar .MuiAvatar-img {
    pointer-events: none;
  }
  .article-header-title {
    font-size: 13px;
  }
  #mui-typo {
    line-height: 1.2 !important;
  }
  .article-title {
    font-size: 13px;
  }
  #mui-rating {
    font-size: 1rem;
    align-self: flex-end;
    padding-top: 5px;
  }
  #article-footer {
    padding-top: 7px;
    padding-bottom: 12px;
  }
  #article-footer-span {
    font-size: 10px;
  }
  .content {
    font-size: 12px;
  }
}

@media (min-width: 441px) {
  .article-header-title {
    font-size: 14px;
  }
  .article-title {
    font-size: 14px;
  }
  #article-footer-span {
    font-size: 12px;
  }
  .content {
    font-size: 13px;
  }
}
@media (min-width: 600px) {
  #mui-card-header #more-vert-icon {
    font-size: 1.3rem;
  }
  #mui-card-header {
    padding-top: 15px;
  }
  #pli-avatar {
    width: 45px;
    height: 45px;
  }
  .article-header-title {
    font-size: 16px;
  }
  .article-title {
    font-size: 16px;
  }
  #mui-rating {
    font-size: 1.09rem;
  }
  #article-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #article-footer-span {
    font-size: 14px;
  }
  .content {
    font-size: 15px;
  }
}

@media (min-width: 800px) {
  #mui-card-header .MuiCardHeader-avatar {
    margin-right: 10px;
  }
  #mui-card-header #more-vert-icon {
    font-size: 1.5rem;
  }
  #pli-avatar {
    width: 56px;
    height: 56px;
  }
  .article-header-title {
    font-size: 18px;
  }
  #mui-typo {
    line-height: 1.5 !important;
  }
  .article-title {
    font-size: 18px;
  }
  #article-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #article-footer-span {
    font-size: 15px;
  }
  .content {
    font-size: 16px;
  }
}

@media (min-width: 900px) {
  #mui-card-header #more-vert-icon {
    font-size: 1.3rem;
  }
  #mui-card-header {
    padding-top: 15px;
  }
  #pli-avatar {
    width: 45px;
    height: 45px;
  }
  .article-header-title {
    font-size: 16px;
  }
  #mui-typo {
    line-height: 1.5 !important;
  }
  .article-title {
    font-size: 16px;
  }
  #mui-rating {
    font-size: 1.09rem;
  }
  #article-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #article-footer-span {
    font-size: 14px;
  }
}

@media (min-width: 1000px) {
  #mui-card-header .MuiCardHeader-avatar {
    margin-right: 10px;
  }
  #mui-card-header #more-vert-icon {
    font-size: 1.5rem;
  }
  #pli-avatar {
    width: 56px;
    height: 56px;
  }
  .article-header-title {
    font-size: 18px;
  }
  .article-title {
    font-size: 18px;
  }
  #article-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #article-footer-span {
    font-size: 15px;
  }
}
