@import './../../css/variable.scss';

.float-icon {
  cursor: pointer;
  color: white;
  font-size: 25px !important;
}

.root {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 10000;
  background: $primaryColor;
  padding: 7px 9px 5px 9px;
  border-radius: 22px;
}
.rootOnScroll {
  position: fixed;
  bottom: 100px;
  right: 40px;
  z-index: 10000;
  background: $primaryColor;
  padding: 6px 8px 4px 8px;
  border-radius: 22px;
}
