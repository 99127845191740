@import './../../css/variable.scss';

#mui-card-header .MuiCardHeader-action {
  align-self: center;
}

#mui-card-header {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}

#mui-card-header .MuiCardHeader-avatar {
  margin-right: 5px;
}

.smoove-icon-bg {
  box-sizing: border-box;
  padding: 4px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $primaryColor;
}

#badge {
  z-index: 0;
}

#mui-card-header #more-vert-icon {
  font-size: 1.25rem;
}
.pli-header-details {
  font-family: 'roboto';
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: #010d18;
  cursor: pointer;
}
.pli-header-date {
  font-family: 'roboto';
  line-height: 16px;
}

.userInfo {
  display: flex;
}
.avatarBox {
  flex: 0.1;
  padding-left: 5px;
  padding-right: 5px;
}
.avatar {
  cursor: pointer;
}
.actionContainer {
  display: flex;
  align-items: center;
}
.details {
  flex: 0.9;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.belowInfo {
  display: flex;
}
.privacyIcon {
  width: 12px;
  margin-left: 5px;
}
.blogIcon {
  width: 16px;
  margin-left: 5px;
}
.rightControls {
  display: flex;
  text-align: right;
}
.control {
  flex: 0.5;
}
.dropdownStyle {
  margin-right: -12px;
}
.menuItemStyle {
  display: flex;
}
.menuIconStyle {
  width: 17px;
}

.followBtn {
  //width: '82px',
  height: 25px;
  border: 1px solid #3794eb;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #f2f2ee;
  text-transform: capitalize;
  display: inline-flex;
}
.followingStatus {
  display: flex;
  align-items: center;
}
.privacy-box {
  display: flex;
  align-items: center;
  justify-content: left;
}
#business-avatar {
  border: 2px solid $primaryColor;
}
@media (min-width: 0) {
  .pli-header-details {
    font-size: 13px;
  }
  #mui-card-header {
    padding-top: 10px;
  }
  #pli-avatar {
    width: 35px;
    height: 35px;
  }
  #pli-avatar .MuiAvatar-img {
    pointer-events: none;
  }
  #pli-badge {
    width: 15px;
    height: 15px;
  }
  #blogger-avatar {
    width: 35px;
    height: 35px;
  }
  #business-avatar {
    width: 35px;
    height: 35px;
  }
  .pli-header-date {
    font-size: 11px;
  }
}

@media (min-width: 441px) {
  .pli-header-details {
    font-size: 14px;
  }
  .pli-header-date {
    font-size: 13px;
  }
}
@media (min-width: 600px) {
  .pli-header-details {
    font-size: 16px;
  }
  #mui-card-header #more-vert-icon {
    font-size: 1.3rem;
  }
  #mui-card-header {
    padding-top: 15px;
  }
  #pli-avatar {
    width: 45px;
    height: 45px;
  }
  #pli-badge {
    width: 18px;
    height: 18px;
  }
  #blogger-avatar {
    width: 45px;
    height: 45px;
  }
  #business-avatar {
    width: 45px;
    height: 45px;
  }
  .pli-header-date {
    font-size: 15px;
  }
}

@media (min-width: 800px) {
  #pli-avatar {
    width: 56px;
    height: 56px;
  }
  #pli-badge {
    width: 20px;
    height: 20px;
  }
  #blogger-avatar {
    width: 56px;
    height: 56px;
  }
  #business-avatar {
    width: 56px;
    height: 56px;
  }
  #mui-card-header .MuiCardHeader-avatar {
    margin-right: 10px;
  }
  #mui-card-header #more-vert-icon {
    font-size: 1.5rem;
  }
  .pli-header-details {
    font-size: 18px;
  }
  .pli-header-date {
    font-size: 14px;
  }
}

@media (min-width: 900px) {
  #pli-avatar {
    width: 45px;
    height: 45px;
  }
  #pli-badge {
    width: 18px;
    height: 18px;
  }
  #blogger-avatar {
    width: 45px;
    height: 45px;
  }
  #business-avatar {
    width: 45px;
    height: 45px;
  }
  #mui-card-header #more-vert-icon {
    font-size: 1.3rem;
  }
  #mui-card-header {
    padding-top: 15px;
  }
  .pli-header-details {
    font-size: 16px;
  }
  .pli-header-date {
    font-size: 15px;
  }
}

@media (min-width: 1000px) {
  #pli-avatar {
    width: 56px;
    height: 56px;
  }
  #pli-badge {
    width: 20px;
    height: 20px;
  }
  #blogger-avatar {
    width: 56px;
    height: 56px;
  }
  #business-avatar {
    width: 56px;
    height: 56px;
  }
  #mui-card-header .MuiCardHeader-avatar {
    margin-right: 10px;
  }
  #mui-card-header #more-vert-icon {
    font-size: 1.5rem;
  }
  .pli-header-details {
    font-size: 18px;
  }
  .pli-header-date {
    font-size: 14px;
  }
}
