@import "./../../../../../../css/variable.scss";

.exchange-rate-dialog {
  background-color: none !important;
  border-radius: 15px;
}

.close-button {
  position: absolute !important;
  right: 0;
  top: 0;
  color: #000 !important;
}

.exchange-rate-header {
  width: 100%;
  color: #000;
  font-weight: 600;
}

.exchange-rate-item {
  width: 100%;
}

.exchange-rate-country {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.country-code {
  text-align: left;
  width: 29px;
}

.flag {
  margin-right: 10px;
  border-radius: 3px;
}

.exchange-rate-content {
  padding: 4% 5%;
}

.exchange-rate-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 35px;
  letter-spacing: 0.04em;
  color: #010d18;
  margin-top: 50px;
}

.exchange-rate-table-container {
  padding: 3% 5%;
  text-align: center;
}

.exchange-rate-reference-title {
  font-size: 18px;
  padding: 15px 5px;
  font-weight: 600;
}

.exchange-rate-reference-bank {
  font-size: 18px;
  padding: 15px 5px 5px;
  font-weight: 600;
}

.exchange-rate-reference-subtitle {
  font-size: 18px;
  font-weight: 600;
}

.exchange-rate-unavailable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.myanmar-entral-bank {
  text-decoration: none;
  font-weight: 600;
  color: #000;
  padding-left: 4px;
}

.jpy-unit {
  padding-right: 22px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0) {
  .exchange-rate-title {
    font-size: 18px;
  }

  .exchange-rate-header {
    font-size: 14px;
  }

  .exchange-rate-item {
    font-size: 12px;
  }

  .flag {
    width: 30px;
    height: 15px;
  }

  .exchange-rate-reference-title {
    font-size: 14px;
  }

  .exchange-rate-reference-bank {
    font-size: 14px;
  }

  .exchange-rate-reference-subtitle {
    font-size: 14px;
  }

  .exchange-rate-unavailable-container {
    flex-direction: column;
  }

  .exchange-rate-unavailable {
    font-size: 16px;
  }

  .myanmar-entral-bank {
    font-size: 16px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .exchange-rate-title {
    font-size: 20px;
  }

  .myanmar-entral-bank {
    font-size: 18px;
  }

  .exchange-rate-unavailable {
    font-size: 18px;
  }

  .exchange-rate-header {
    font-size: 16px;
  }

  .exchange-rate-item {
    font-size: 14px;
  }

  .flag {
    width: 40px;
    height: 25px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .exchange-rate-title {
    font-size: 20px;
  }
  .exchange-rate-header {
    font-size: 18px;
  }
  .exchange-rate-item {
    font-size: 16px;
  }
  .exchange-rate-unavailable-container {
    flex-direction: row;
  }

  .exchange-rate-reference-title {
    font-size: 18px;
  }

  .exchange-rate-reference-bank {
    font-size: 18px;
  }

  .exchange-rate-reference-subtitle {
    font-size: 18px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .exchange-rate-title {
    font-size: 22px;
  }

  .exchange-rate-header {
    font-size: 20px;
  }

  .exchange-rate-item {
    font-size: 18px;
  }

  .exchange-rate-reference-title {
    font-size: 20px;
  }

  .exchange-rate-reference-bank {
    font-size: 20px;
  }

  .exchange-rate-reference-subtitle {
    font-size: 20px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .exchange-rate-title {
    font-size: 24px;
  }
}
