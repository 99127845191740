@import "./../../css/variable.scss";

#heading {
  background: #e0ebf0;
  box-shadow: 0px 4px 4px rgba(1, 13, 24, 0.1);
}

#heading_grid_root {
  height: 100%;
}

#common-back-icon {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 80px;
  left: 20px;
  cursor: pointer;
  z-index: 1000;
}

#cover_img_wrap {
  width: 100%;
  height: 100%;
}

#cover_img {
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 2;
  object-fit: cover;
  pointer-events: none !important;
  box-shadow: -5px 3px 4px rgb(1 13 24 / 20%);
}

#bottom_grid_item {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#description_title {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #010d18;
}

#short_description {
  font-style: italic;
  line-height: 25px;
  text-align: center;
  color: #010d18;
  padding-left: 10px;
}

#views {
  // background: #fff;
  border-radius: 3px;
}

#view_label {
  text-transform: uppercase;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
}

#lang_label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #000000;
  flex: 1;
}

.save_icon_button {
  width: 40px;
  height: 40px;
  background-color: $primaryColor !important;
}

.description-section {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  color: #242424;
  text-align: justify;
  padding: 20px;
  cursor: pointer;
}

.more {
  cursor: pointer;
}

.description-section::first-letter {
  font-size: 40px;
  font-weight: 600;
}

.description-section img {
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  pointer-events: none;
}

.attraction-section {
  background: #fff;
  box-shadow: 0px -4px 4px rgba(1, 13, 24, 0.1);
}

#article-details .hotels-section {
  padding: 50px 10% 20px;
}

#article-details .restaurants-section {
  padding: 0 10% 50px;
}

#back-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: fixed;
  z-index: 1000;
}

#flag_icon {
  object-fit: cover;
  cursor: pointer;
}

.save-icon-style {
  fill: #fff !important;
}

#recommend-save-icon {
  position: absolute;
  top: 5%;
  z-index: 1;
  right: 5%;
  cursor: pointer;
  background: $primaryColor;
}

.nearby-attraction-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  background-color: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}

.nearby-attraction-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.04em;
  color: #242424;
  margin: 0 !important;
}

#attraction-item {
  position: relative;
  cursor: pointer;
  aspect-ratio: 4 / 3;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

#img-protected {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#attraction-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  padding: 0 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: $cardColor;
  border-radius: 5px;
  opacity: 0 !important;
  transition: all 0.5s ease-in-out;
}

#attraction-overlay h2 {
  font-size: 18px !important;
  color: #ffff;
  margin: 0;
  text-align: left;
  font-weight: 400;
  line-height: 20px;
  display: none;
}

.attraction-short-description {
  margin: 0;
  letter-spacing: 0.04em;
  color: #ffffff;
  text-align: left !important;
  padding-top: 10px;
}

#attraction-item:hover {
  transform: scale(1.12);
}

#attraction-overlay {
  opacity: 1;
}

#attraction-item:hover #attraction-name {
  opacity: 0;
}

#attraction-item:hover #attraction-overlay {
  opacity: 1 !important;
}

.attraction-img {
  width: 100% !important;
  aspect-ratio: 4 / 3;
  background: #c4c4c4;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  object-fit: cover;
  position: absolute;
}

#label {
  position: absolute;
  bottom: 0;
  padding: 15px 0;
  background: $cardColor;
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  mix-blend-mode: normal;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

#attraction-name {
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: $cardColor;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: all 0.5s ease-in-out;
  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.04em;
    color: #fff;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

#business-section {
  background: #fff;
}

.business-img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
  cursor: pointer;
}

#item-bar {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: $cardColor;
}

#item-bar div {
  text-align: center;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

#business-title {
  width: 100%;
  height: 100%;
  background: $primaryColor;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: #fff;
}

.business-nearby {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0.04em;
  color: #242424;
  margin: 0 !important;
}

.lineHight {
  line-height: 50px !important;
}

#seemore-wrapper {
  display: flex;
  justify-content: flex-end;
}

.seemore {
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: $primaryColor;
  border-radius: 8px;
}

#protected-img-box-business {
  position: relative;
}

#protected-img-business {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.alert-success {
  background-color: $secondaryColor !important;
}

@media (max-width: 1020px) {
  #attraction-overlay h2 {
    display: block;
  }

  .carousel-container {
    height: auto !important;
  }
}
@media (max-width: 540px) {
  #attraction-overlay h2 {
    display: none;
  }
}

/* 
 * xs
 */
@media (min-width: 0) {
  #description {
    padding-top: 50px;
  }

  #article-details #heading {
    height: 100%;
  }

  #description_grid {
    padding: 10px 15px;
  }

  #views {
    padding: 3px 7px;
  }

  #article-details #heading #description {
    padding-top: 50px;
  }

  #description_title {
    font-size: 23px;
  }

  #short_description {
    font-size: 12px;
  }

  #viewicon {
    width: 17px;
    height: 17px;
  }

  #view_label {
    font-size: 10px;
  }

  #lang_label {
    font-size: 11px;
    font-weight: bold;
  }

  #flag_icon {
    width: 20px;
    height: 16px;
  }

  .description-section {
    font-size: 13px;
  }

  .button {
    width: 120px;
    height: 35px;
    font-size: 14px;
  }

  .attraction-card {
    height: 140px;
    margin: 0 5px 20px 5px;
    position: relative;
  }

  #attraction-overlay {
    left: 0;
  }

  .nearby-attraction-title,
  .business-nearby {
    font-size: 16px;
  }

  #label {
    font-size: 12px;
  }

  .seemore {
    width: 120px;
    height: 35px;
    font-size: 14px;
    margin-right: 16px;
  }

  #business-mobile {
    display: block;
  }

  #business-desktop {
    display: none;
  }

  #attraction-item {
    margin: 10px 5px;
  }

  .attraction-short-description {
    font-size: 12px;
  }
}

@media (min-width: 441px) {
  #description_title {
    font-size: 25px;
  }

  #short_description {
    font-size: 15px;
  }

  #viewicon {
    width: 19px;
    height: 19px;
    color: $primaryColor;
  }

  #lang_label {
    font-size: 13px;
  }

  #flag_icon {
    width: 22px;
    height: 18px;
  }

  .description-section {
    font-size: 14px;
  }

  .attraction-card {
    height: 140px;
    margin: 0 5px 20px 5px;
  }

  #label {
    font-size: 13px;
  }
}

/* 
 * sm
 * md
 */
@media (min-width: 600px) {
  #description_title {
    font-size: 26px;
  }

  #short_description {
    font-size: 16px;
  }

  .description-section {
    font-size: 16px;
  }

  .attraction-card {
    height: 185px;
    margin: 0 5px 20px 5px;
  }

  #label {
    font-size: 13px;
  }

  #business-list-item {
    height: 120px !important;
  }

  #business-title {
    font-size: 20px;
  }

  .lineHight {
    line-height: 35px !important;
  }

  #item-bar div {
    font-size: 12px;
  }

  #business-mobile {
    display: none;
  }

  #business-desktop {
    display: block;
  }

  .nomore-data-info span {
    font-size: 12px;
  }

  #attraction-item {
    margin: 20px 10px 35px;
  }

  .attraction-short-description {
    font-size: 14px;
  }

  .attraction-container {
    box-shadow: 0 2px 2px -1px rgba(1, 13, 24, 0.1);
  }

  #attraction-name span {
    font-size: 14px;
  }
}

@media (min-width: 700px) {
  .attraction-card {
    height: 185px;
    margin: 0 5px 20px 5px;
  }

  #business-list-item {
    height: 150px !important;
  }

  #business-title {
    font-size: 22px;
  }

  .lineHight {
    line-height: 35px !important;
  }

  #item-bar div {
    font-size: 14px;
  }
}

/*
 * lg
 */
@media (min-width: 800px) {
  .attraction-card {
    height: 175px;
    margin: 0 5px 20px 5px;
  }
}

/*
*md
*/
@media (min-width: 900px) {
  #description {
    padding-top: 70px;
  }
  #bottom_grid_item {
    margin-top: 25px;
  }
}

/* 
 * md
 */
@media (min-width: 1200px) {
  #description {
    padding-top: 0;
  }

  // #description_grid {
  //   height: 100%;
  //   padding: 10px 15px;
  // }

  #description_title {
    font-size: 26px;
  }

  #short_description {
    font-size: 16px;
  }

  .description-section {
    font-size: 16px;
  }

  .button {
    width: 160px;
    height: 40px;
    font-size: 18px;
  }

  .attraction-card {
    height: 200px;
    margin: 0 5px 20px 5px;
  }

  #label {
    font-size: 13px;
  }

  #business-list-item {
    height: 170px !important;
  }

  #business-title {
    font-size: 28px;
  }

  .lineHight {
    line-height: 35px !important;
  }

  #item-bar div {
    font-size: 16px;
  }

  .seemore {
    width: 160px;
    height: 40px;
    font-size: 18px;
    margin-right: 0;
  }

  .nomore-data-info span {
    font-size: 13px;
  }

  .attraction-container {
    padding-bottom: 0px;
    box-shadow: 0 2px 2px -1px rgba(1, 13, 24, 0.1);
  }

  #description_grid {
    height: 100%;
    padding: 0px 15px;
    position: relative;
  }

  #bottom_grid_item {
    position: absolute;
    bottom: 2%;
    right: 3%;
  }

  #attraction-name span {
    font-size: 15px;
  }

  .nearby-attraction-title,
  .business-nearby {
    font-size: 18px;
  }
}

/* 
 * lg
 */
@media (min-width: 1300px) {
  #description_grid {
    height: 100%;
    padding: 10px 15px;
  }

  #description_title {
    font-size: 30px;
  }

  #short_description {
    font-size: 18px;
  }

  #view_label {
    font-size: 13px;
  }

  #lang_label {
    font-size: 13px;
  }

  #flag_icon {
    width: 24px;
    height: 20px;
  }

  #save-icon {
    width: 16px;
    height: 20px;
  }

  #share-icon {
    width: 22px;
    height: 22px;
  }

  .description-section {
    font-size: 16px;
  }

  .attraction-card {
    height: 230px;
    margin: 0 15px 30px 15px;
  }

  #label {
    font-size: 16px;
  }

  #business-list-item {
    height: 220px !important;
  }

  #business-title {
    font-size: 30px;
  }

  .lineHight {
    line-height: 50px !important;
  }

  #item-bar div {
    font-size: 18px;
  }

  .nomore-data-info span {
    font-size: 14px;
  }

  #attraction-overlay {
    left: 50%;
  }

  #attraction-name span {
    font-size: 16px;
  }
}

@media (min-width: 1600px) {
  #attraction-item {
    margin: 40px 20px;
  }

  .attraction-short-description {
    font-size: 16px;
  }
}
