@import "../../css/variable.scss";

.reactIcon{
    // margin-top: 11px;
    margin-right: 8px;
    width: 40px;
    height: 40px;
}

.reactionPopup{
    border: 1px solid $grayColor;
    display: flex;
    background: white;
    padding: 3px 5px 3px 5px;
    border-radius: 10px;
    .reactionIconSm,.reactionIcon{
        width: 36px;
        height: 36px;
        padding: 3px;
    }
}
