$primaryColor: #468172;
$secondaryColor: #d77819;
$grayColor: #757575;
$muteColor: #bebebe;
$successColor: #86beb1;
$errorColor: #f03049;
$cardColor: rgba(70, 129, 114, 0.5);
$whiteColor: #ffffff;
$seeMoreColor: #3794eb;
$disableBgColor: #e0e0e0e0;
$blackColor: #000000;

:export {
    primaryColor: $primaryColor;
    secondaryColor: $secondaryColor;
    whiteColor: $whiteColor;
    grayColor: $grayColor;
    blackColor: #000000;
}
