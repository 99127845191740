@import "@fontsource/sarina";
@import "@fontsource/roboto";
@import "./../../css/variable.scss";

#all-search-result-button,
#business-suggestion-submit-button {
  border-radius: 6px;
  background-color: $primaryColor;
  text-transform: capitalize;
  border: 2px solid $primaryColor;
  font-size: 15px;
}

#all-search-result-button-flag {
  border-radius: 6px;
  background-color: $primaryColor;
  text-transform: capitalize;
  border: 2px solid $primaryColor;
  font-size: 15px;
  margin-bottom: 3%;
}

#search-result-root {
  min-height: 100vh;
  padding-top: 80px;
  background: #fff;
}

#all-result-tab-root {
  padding-bottom: 4%;
}

#all-result-tab-root-filter {
  padding: 0;
}

#list-box {
  padding: 15px 0 15px 0;
}

/* Header Section */
#search-result-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #000000;
}

/* All Result - Title */
#search-list-item-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #000000;
}

#seemore-box {
  text-align: right;
}

#search-result-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #010d18;
  text-align: center;
  margin-right: 4px;
}

/* Search Result - Tab Bar Section */
#search-result-tab-desktop {
  button {
    text-transform: capitalize;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    color: $grayColor;
    min-width: 0 !important;
    line-height: 1 !important;
    word-break: break-word;

    &:global(.Mui-selected) {
      color: #fff !important;
      background: $primaryColor;
      box-shadow: 0px 0px 4px rgba(1, 13, 24, 0.25);
    }
  }
}

#search-result-tab-mobile {
  button {
    text-transform: capitalize;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    color: $grayColor;

    &:global(.Mui-selected) {
      color: #fff !important;
      background: $primaryColor;
      box-shadow: 0px 0px 4px rgba(1, 13, 24, 0.25);
    }
  }
}

#filtered-result-description {
  font-family: "Roboto";
  font-style: italic;
  font-weight: normal;
  color: #010d18;
  text-align: left;
  padding-top: 2%;
}

#filtered-result-business-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #000000;
  line-height: 1.2;
  cursor: pointer;
  padding-top: 1%;
}

.spinner {
  text-align: center;
}

.nomore-data-info {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3794eb;
}

.data-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
  padding-top: 15px;
}

.nomore-post-info {
  padding-left: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
}

.business-suggestion {
  // padding-top: 15px;
  padding-bottom: 2%;
  text-align: center;
}

.business-suggestion-data {
  padding-top: 15px;
  padding-bottom: 4%;
}

/* Business Suggestion Section */
.business-suggestion-root {
  border-radius: 0 !important;
  background-color: $primaryColor !important;
  flex-direction: row !important;
  justify-content: center;
}
#no-result-business-title {
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 20px !important;
  color: #000000;
  padding-bottom: 8px;
}

#no-result-business-title-flag {
  padding: 15px 0;
}

#business-suggestion-close-button {
  position: absolute !important;
  right: 1vw;
  top: 1vw;
  color: #fff !important;
}

#business-suggestion-title {
  min-width: 30%;
  text-align: center;
  position: absolute;
  top: 10.6vh;
  padding: 0 20px 10px 20px;
  background-color: $primaryColor;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  z-index: 1000 !important;
}

#business-suggestion-card {
  flex: 1;
  height: 88vh;
  margin-top: 12vh;
  border-radius: 0 !important;
  border-top-right-radius: 30px !important;
  border-top-left-radius: 30px !important;
  background-color: #fff !important;
  box-shadow: 0px -4px 4px rgb(0 0 0 / 25%) !important;
  overflow: scroll !important;
}

#business-suggestion-body-box {
  padding: 3% 0;
}

#business-suggestion-sub-title {
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 25px !important;
  color: #010d18 !important;
}

#business-suggestion-label {
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #010d18 !important;
}

#business-suggestion-icon {
  width: 20px;
  height: 20px;
  color: $primaryColor;
}

#business-suggestion-submit-button {
  min-width: 12vw !important;
  text-transform: capitalize !important;
}

/* Business Suggestion - Thanks Dialog Content */
.business-suggestion-thanks-img {
  width: 50%;
  aspect-ratio: 4 / 3;
}
.ratingDiv {
  padding: 20px 0 20px 0;
}
#business-suggestion-thanks-msg {
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #000000 !important;
}

#business-suggestion-dialog-actions {
  padding: 0 20px 10px 20px !important;
}

#business-suggestion-ok-button {
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #010d18 !important;
  text-decoration: underline;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  cursor: pointer;
}

/* No Result Section */
#no-result-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#no-result-inner-box-all {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0 0 0;
  width: 300px;
}

#no-result-inner-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  width: 300px;
}

.no-result-img {
  width: 100%;
}

#no-result-info {
  position: absolute;
  top: 17%;
  width: 75%;
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  color: $grayColor;
  text-align: center;
}

#no-result-business-box {
  // position: absolute;
  margin-top: 20px;
  bottom: 4%;
}

#no-result-business-box-filter {
  // position: absolute;
  text-align: center;
  bottom: 10%;
}

@media (min-width: 0) {
  /* Search Result - Tab Bar Section */
  #search-result-tab-desktop {
    display: none;
  }

  #search-result-tab-mobile {
    display: flex;
  }

  #search-result-tab-desktop {
    button {
      font-size: 15px;
    }
  }
  #search-result-tab-mobile {
    button {
      font-size: 15px;
    }
  }

  #no-result-info {
    font-size: 13px !important;
  }

  #search-result-user-img {
    height: 160px;
  }
  #search-result-name {
    font-size: 12px;
  }

  /* Search Result - Header Section */
  #search-result-title {
    font-size: 21px;
    padding-bottom: 5px;
  }

  /* All Result - Title */
  #search-list-item-title {
    font-size: 16px;
    padding-bottom: 5px;
  }
  #seemore-box {
    padding-bottom: 10px;
  }

  .nomore-post-info {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .no-result-img {
    width: 60vw;
  }
}

@media (min-width: 600px) {
  /* Search Result - Tab Bar Section */
  #search-result-tab-desktop {
    button {
      font-size: 18px;
    }
  }

  #search-result-tab-mobile {
    button {
      font-size: 17px;
    }
  }

  #search-result-user-img {
    height: 175px;
  }

  #search-result-name {
    font-size: 12px;
  }

  /* Search Result - Header Section */
  #search-result-title {
    font-size: 22px;
  }

  /* All Result - Title */
  #search-list-item-title {
    font-size: 17px;
  }

  .nomore-post-info {
    font-size: 12px;
  }
}

@media (min-width: 900px) {
  /* Search Result - Tab Bar Section */
  #search-result-tab-desktop {
    display: flex;
  }

  #search-result-tab-mobile {
    display: none;
  }

  #search-result-tab-desktop {
    button {
      font-size: 18px;
    }
  }

  #search-result-tab-mobile {
    button {
      font-size: 19px;
    }
  }

  #search-result-user-img {
    height: 180px;
  }
  #search-result-name {
    font-size: 13px;
  }

  /* Search Result - Header Section */
  #search-result-title {
    font-size: 24px;
    padding-bottom: 15px;
  }

  /* All Result - Title */
  #search-list-item-title {
    font-size: 19px;
    padding-bottom: 15px;
  }
  #seemore-box {
    padding-bottom: 15px;
  }

  .nomore-post-info {
    font-size: 13px;
  }
}

@media (min-width: 1000px) {
  /* Search Result - Tab Bar Section */
  #search-result-tab-desktop {
    button {
      font-size: 19px;
    }
  }

  #search-result-tab-mobile {
    button {
      font-size: 20px;
    }
  }

  #search-result-user-img {
    height: 200px;
  }

  #search-result-name {
    font-size: 14px;
  }

  /* Search Result - Header Section */
  #search-result-title {
    font-size: 25px;
  }

  /* All Result - Title */
  #search-list-item-title {
    font-size: 20px;
  }

  .nomore-post-info {
    font-size: 14px;
  }
}

/* Business Suggestion - Media Query */
@media screen and (max-width: 1200px) {
  #business-suggestion-card {
    padding-top: 30px !important;
  }

  #business-suggestion-sub-title {
    padding-top: 20px;
    font-size: 25px !important;
  }
}
@media screen and (max-width: 320px) {
  #business-suggestion-card {
    width: "100vw";
  }

  #business-suggestion-sub-title {
    padding-top: 20px;
    font-size: 20px !important;
  }
}
