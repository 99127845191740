@import "./../../../../../../css/variable.scss";

.calculator-content {
  display: flex;
  position: relative;
  width: 100%;
}

.calculator-dialog {
  background-color: #fff;
  position: relative;
}

.close-button {
  position: absolute !important;
  top: 0;
  right: 0;
  color: #000 !important;
  z-index: 999;
}

.content-left {
  text-align: left;
  width: 100% !important;
  align-items: center;
  padding: 40px 0 20px 0;
  background: #f2f2ee;
  border-radius: 15px;
}

.content-right {
  width: 100% !important;
  background: #ffffff;
  box-shadow: -5px 0px 15px rgba(16, 16, 11, 0.2);
  border-radius: 15px;
  padding: 40px 20px 10px 20px;
  position: relative;
  text-align: left;
}

.calculator-title {
  text-align: center;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #010d18;
  padding: 0 20px;
}

#stepper {
  background: #f2f2ee;
}

.labels {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 19px;
}

#box-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.stepper-row {
  padding: 0;
  text-align: left;
}

.stepper-label {
  align-items: center;
  display: flex;
  height: 30px;
  font-size: 16px;
  position: absolute;
  font-weight: 300;
}

.form-control-lg {
  width: 100%;
}

#radio-group {
  flex-direction: row;
  span {
    &:global(.Mui-checked) {
      color: $primaryColor;
    }
  }
}

.styled-radio {
  color: "#979da0";
}

#activities-checkbox {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}

.activities-img-container {
  display: flex;
}

.activities-img {
  width: 30px;
  margin: 0 auto;
}

.activities-name {
  font-size: 14px;
  text-align: center;
}

.bottom-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}

.activities-icon {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 20px;
  box-sizing: border-box;
  background: $primaryColor;
  color: #fff;
  width: 100px;
  padding-top: 25px;
  word-break: break-all;
  margin: 5px;
  border: 1px solid #ffffff;
  position: relative;
}

#unchecked-icon {
  fill: #fff !important;
}

#checked-icon {
  fill: #fff !important;
}

.title {
  font-weight: 600;
  padding: 20px 0;
  text-align: center;
}

.plan-icon-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.plan-label {
  padding-left: 10px;
}

#plan-icon {
  color: $primaryColor;
}

.from-to {
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
}

.source {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 15px;
}

.align-center {
  text-align: left;
  font-size: 15px;
  padding-left: 10px;
}

#plan-box-items {
  position: relative;
  width: 100%;
  max-height: 500px;
  height: 100%;
  align-items: center;
  padding-top: 5px;
  text-align: left;
}

#tm-boxs-right {
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0 !important;
}

.plan-box-items-labels {
  display: flex;
  flex-direction: column;
}

.plan-label-align-right {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.plans-label-align-left {
  text-align: right;
  display: flex;
  justify-content: right;
  width: 100%;
}

#tm-boxs-left {
  width: 200px !important;
}

#tm-boxs-left {
  span {
    display: flex;
    align-content: center;
    align-items: center;
  }
}

.shalgo-trip-budget {
  padding-top: 24px;
}

#plan-title {
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #010d18;
}

.plan-btn-right {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  align-items: center;
}

.message-left {
  color: #ff0303;
}

.save-to-gallery {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
  padding-left: 5px;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.button {
  height: 40px;
  background-color: $primaryColor !important;
  margin-bottom: 10px;
  color: #ffffff !important;
  margin-right: 5px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
}

.button-my {
  height: 40px;
  background-color: $primaryColor !important;
  margin-bottom: 10px;
  color: #ffffff !important;
  margin-right: 5px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  width: 200px;
}

.btn-left,
.btn-right {
  button {
    &:global(.Mui-disabled) {
      border: 1px solid $primaryColor;
      color: #979da0 !important;
      background-color: #e0e0e0 !important;
    }
  }
  button:hover {
    background-color: $primaryColor;
  }
}

.total-cost-box {
  margin-top: 10px;
  text-align: center;
}

.total-cost-text {
  font-weight: 500;
  color: #000;
}

.total-cost-number {
  color: $primaryColor;
  padding-right: 4px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0px) {
  .calculator-content {
    flex-direction: column;
  }

  .calculator-title {
    font-size: 20px;
  }

  .title {
    font-size: 20px;
  }

  .labels {
    font-size: 18px;
  }

  #tm-boxs-right {
    margin-left: 34px !important;
    width: 200px !important;
  }

  .button {
    width: 147px;
  }

  .total-cost-text {
    font-size: 18px;
  }

  .total-cost-number {
    font-size: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .calculator-title {
    font-size: 25px;
  }

  .title {
    font-size: 25px;
  }

  .labels {
    font-size: 20px;
  }

  .button {
    width: 150px;
  }
}
@media only screen and (max-width: 600px) {
  .plan-btn-right {
    flex-direction: column-reverse !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .plan-btn-right {
    flex-direction: row;
  }

  .total-cost-text {
    font-size: 18px;
  }

  .total-cost-number {
    font-size: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .calculator-content {
    flex-direction: row;
  }
  #plan-box-items {
    font-size: 16px;
  }
  .align-center {
    font-size: 16px;
  }
  .source {
    font-size: 16px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #tm-boxs-right {
    width: 270px !important;
  }
}
