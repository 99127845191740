@import "@fontsource/sarina";
@import "@fontsource/roboto";
@import "./../../../../css/variable.scss";

.user-profile-container #back-icon {
  position: fixed;
  top: 70px;
  left: 2%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1000;
}

.user-profile-container .banner {
  width: 100%;
  position: relative;
  background: #f2f2ee;
}

.user-profile-container .banner .shading {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(1, 13, 24, 0) 76.79%, rgba(1, 13, 24, 0.85) 100%);
}

.user-profile-container .banner .cover-img-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-profile-container .banner #cover-img {
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.user-profile-container .banner #upload-cover-img {
  z-index: 10;
  cursor: pointer;
}

.user-profile-container .banner #left-grid {
  width: 100%;
  height: 100%;
}

.user-profile-container .banner .left {
  width: 100%;
  height: 100%;
  position: relative;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.user-profile-container .banner .left .shading {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(145.48deg, rgba(1, 13, 24, 0.12) 0%, rgba(1, 13, 24, 0.08) 99.31%);
  backdrop-filter: blur(3px);
}

.user-profile-container .banner .left .user-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-profile-container .banner .left .user-info #profile-avatar {
  cursor: pointer;
}

.user-profile-container .banner .left .user-info #profile-badge {
  cursor: pointer;
}

.user-profile-container .banner .left .user-info .info {
  width: 100%;
  z-index: 1;
  text-align: center;
  padding-top: 2%;
}

.user-profile-container .banner .left .user-info .info .name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
}

.user-profile-container .banner .left .user-info .info .location {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

.user-profile-container .banner .left .user-info .info .follows {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #ffffff;
}

.user-profile-container .banner .left .user-info .info .divider {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-profile-container .banner .left .user-info .info .divider > div {
  width: 1px;
  height: 22px;
  background-color: #ffffff;
}

.user-profile-container .banner .left .user-info .button {
  border: 1px solid #ffb703;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  background-color: #ffb703;
  color: #fff;
}

.user-profile-container .banner .left .user-info .action-button {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 7%;
}

.user-profile-container .main-content-container {
  background-color: #f2f2ee;
  min-height: 100vh;
}

.user-profile-container .main-content-container .paper {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(1, 13, 24, 0.25);
  border-radius: 6px;
  padding: 10px;
}

.user-profile-container .main-content-container .side #title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: #010d18;
}

.user-profile-container .main-content-container .side .bio {
  word-wrap: break-word;
}

.user-profile-container .main-content-container .side .bio #bio-text {
  padding: 10px 0 22px 10px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: #010d18;
}

.user-profile-container .main-content-container .side .bio #about {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #010d18;
}

.user-profile-container .main-content-container .side .bio #about .work-edu {
  align-items: flex-start !important;
}

.user-profile-container .main-content-container .side .bio #about .info {
  display: flex;
  align-items: center;
  padding: 1vw 0 0 1vw;
  word-break: break-word;
}

.user-profile-container .main-content-container .side .bio #about .info .icon {
  width: 16px;
  margin-right: 2%;
}

.user-profile-container .main-content-container .side .bio #about .info .date {
  color: #6d787e;
}

.user-profile-container .main-content-container .side .interests .interests-list {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #010d18;

  padding: 1vw;
}

.user-profile-container .main-content-container .side .interests .interests-list .item {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1% 3%;
  word-break: break-all;
}

.user-profile-container .main-content-container .side .interests .interests-list .item .icon {
  width: 20px;
}

.user-profile-container .main-content-container .side .visited {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  color: #010d18;
}

.user-profile-container .main-content-container .side .visited .info {
  padding: 1vw 0;
}

.user-profile-container .main-content-container .side .visited .date {
  color: #6d787e;
}

.user-profile-container .main-content-container #main-tab-c > .MuiTabs-root {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(1, 13, 24, 0.1);
  border-radius: 6px;
  margin-bottom: 3%;
}

.user-profile-container #mobile-tabs {
  min-height: 35px;
}

.user-profile-container #mobile-tabs .MuiTabScrollButton-root {
  width: 25px !important;
  min-width: 25px !important;
}

.user-profile-container #mobile-tabs .MuiButtonBase-root {
  min-width: 75px;
  min-height: 35px;
  text-transform: capitalize;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  text-align: center;
  border: 1px solid #e0e0e0;

  padding: 0 !important;
}

.user-profile-container #mobile-tabs .Mui-selected {
  background: #ffb703;
  border-radius: 5px;
  color: #000000;
}

.user-profile-container #mobile-tabs .MuiTabs-indicator {
  background-color: transparent;
}

.user-profile-container #tabs .MuiButtonBase-root {
  text-transform: capitalize;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  text-align: center;
  border: 1px solid #e0e0e0;

  padding: 0 !important;
}

.user-profile-container #tabs .Mui-selected {
  background: #ffb703;
  border-radius: 5px;
  color: #000000;
}

.user-profile-container #tabs .MuiTabs-indicator {
  background-color: transparent;
}

.gallery-tab-container .albums-tab-container .album-item .album #create-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-tab-container .albums-tab-container .info #create-album-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  color: #3794eb;
}

.details-album-container #venus {
  flex: 1;
  display: flex;
  align-items: center;
}

.details-album-container #mars {
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: flex;
  align-items: center;
  text-align: right;
  color: #3794eb;
}

.details-ablum-top-section {
  display: flex;
  align-items: flex-start;
  padding-top: 2%;
}

.details-ablum-top-section .left-side {
  flex: 1;
  text-align: center;
}

.details-ablum-top-section .left-side #album-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 1.5;
  color: #112a3a;
}

.details-ablum-top-section .left-side #description {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  color: #010d18;
}

.details-ablum-top-section .left-side #updated-date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: #6d787e;
}

.photos-grid-container #album-details img {
  border-radius: 0;
}

.photos-grid-container .details-album-container img {
  border-radius: 0;
}

.create-album-dialog .create-album-container {
  text-align: center;
}

.create-album-dialog .create-album-container .create-album-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  text-align: center;
  color: #010d18;
}

.create-album-dialog .create-album-container .create-album-form #label {
  text-align: left;
  padding: 4% 0 1.5% 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #010d18;
}

.create-album-dialog .create-album-container .create-album-form .drop-area {
  width: 100%;
  min-width: 0;
  max-width: none;
  border: 2px dashed #6d787e;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f2f2f2;
  cursor: pointer;
}

.create-album-dialog .create-album-container .create-album-form .disabled {
  cursor: auto;
}

.create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 7px;
}

.create-album-dialog .drop-zone-container #message {
  position: absolute;
  bottom: 0;
  font-family: "Roboto";
  color: #ffb703;
}

.create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone #upload-img {
  width: 50px;
}

.create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone #label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #e0e0e0;
}

.create-album-dialog .preview-section {
  width: 100%;
  height: 100%;
  text-align: left;
  overflow-y: auto;
}

.create-album-dialog .preview-section .item {
  position: relative;
}

.create-album-dialog .preview-section .item > svg {
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 2;
}

.create-album-dialog .preview-section .item > svg > path {
  fill: #f50057;
  color: #f50057;
}

.create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone .preview-img {
  object-fit: cover;
  border-radius: 5px;
}

.create-album-dialog #upload-palette {
  border-radius: 5px;
  background: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.create-album-dialog .footer {
  display: flex;
  justify-content: center;
}

.create-album-dialog .footer .button {
  width: 12vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffb703;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffb703;
}

.create-album-dialog .footer .inactive {
  cursor: not-allowed;
}

.create-album-dialog .footer .active-button {
  background-color: #ffb703;
  color: #ffffff;
}

.create-album-dialog .footer #cancel-button:hover {
  background-color: #ffb703;
  color: #ffffff;
}

#loading-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: linear-gradient(145.48deg, rgba(1, 13, 24, 0.12) 0%, rgba(1, 13, 24, 0.08) 99.31%);
}

.menu-item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: #6d787e;
}

.edit-album-dialog .edit-album-container {
  text-align: center;
}

.edit-album-dialog .edit-album-container .edit-album-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  text-align: left;
  color: #010d18;
}

.edit-album-dialog .edit-album-container .edit-album-form #label {
  text-align: left;
  padding: 4% 0 1.5% 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #010d18;
}

.edit-album-dialog .footer {
  margin-top: 2.5vw;
  display: flex;
  justify-content: center;
}

.edit-album-dialog .footer .button {
  width: 15vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffb703;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffb703;
  text-transform: uppercase;
}

.edit-album-dialog .footer .inactive {
  cursor: not-allowed;
}

.edit-album-dialog .footer .active-button {
  background-color: #ffb703;
  color: #ffffff;
}

.edit-album-dialog #loading-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: linear-gradient(145.48deg, rgba(1, 13, 24, 0.12) 0%, rgba(1, 13, 24, 0.08) 99.31%);
}

#loading-palette {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-dialog-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #010d18;
}

#login-alert #positive-button {
  width: 80px;
  height: 30px;
  background: #ffb703;
  border-radius: 8px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

#login-alert #negative-button {
  width: 80px;
  height: 30px;
  border: 1px solid #ffb703;
  box-sizing: border-box;
  border-radius: 8px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffb703;
  background-color: transparent;
}

.saved-tab-loading {
  text-align: center;
}

.saved-tab-container #filter-box {
  display: flex;
  justify-content: right;
}

.saved-tab-container #filter-box .MuiSelect-select {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  color: #6d787e;
}

.saved-tab-container #filter-box .MuiOutlinedInput-root {
  min-width: 125px;
}

#user-profile-saved-filter-menu {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  color: #6d787e;
}

.saved-tab-container #filter-box fieldset {
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 8px;
}

.saved-tab-container .main-content {
  margin: 4vh 0;
}

.saved-tab-container .main-content .item > img {
  width: 100%;
  height: auto;
  aspect-ratio: 11 / 9;
  object-fit: cover;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  pointer-events: none;
}

.saved-tab-container .main-content .item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  color: #000000;
  cursor: pointer;
  word-break: break-word;
}

.item #more {
  color: #3794eb;
}

.item .bold {
  font-weight: bold;
}

.item .black {
  color: #010d18;
}

.item .gray {
  color: #6d787e;
}

.user-review-container .review-item {
  background: #f9f9f3;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  width: 100%;
}

.user-review-container .review-item .entity-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 0 0 5px;
}

.user-review-container .review-item .entity-info-div {
  padding: 1vh 0;
}
.user-review-container .review-item .edit-icon {
  top: 1vh;
  position: absolute;
  right: 1vh;
  width: 16px;
}

.user-review-container .review-item .entity-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  color: #010d18;
}

.user-review-container .review-item .entity-info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  color: #6d787e;
}

.user-review-container #divider {
  border-bottom: 1px solid #979da0;
  margin-top: -2%;
}

.user-review-container .user-review-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 6vh;
  color: #010d18;
}

.user-review-desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  text-align: justify;
  color: #000000;
}

.friends-tab-container #friends-tabs {
  margin-top: -2%;
}

.friends-tab-container #friends-tabs .MuiTabs-indicator {
  background-color: #010d18;
  opacity: 0.5;
}

.friends-tab-container #friends-tabs .MuiButtonBase-root {
  text-transform: capitalize;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  text-align: center;
  color: #6d787e;
  opacity: 1;
}

.friends-tab-container #friends-tabs .Mui-selected {
  font-weight: bold;
  color: #010d18;
}

.friend-list-container .MuiInputBase-root {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
}

.friend-list-container .friends-count-label {
  padding-top: 10px;
  padding-bottom: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  color: #000000;
}

.friend-list-container .other-friends-count-label {
  padding-bottom: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  color: #000000;
}

.friend-list-container #count {
  color: #ffb703;
}

.friend-list-container .list-item {
  margin-top: 1vh;
}

.friend-list-container .list-item #avatar {
  width: 2vw;
  height: 2vw;
}

.friend-requests-container {
  margin-top: 15px;
}

#item-card-avatar {
  cursor: pointer;
}

#item-card-blogger-avatar {
  cursor: pointer;
  border: 2px solid #fa3f58;
}

.edit-profile-container {
  background: #fff;
}

.edit-profile-container #edit-profile-tabs .MuiTabs-indicator {
  display: none;
}

// .edit-profile-container #edit-profile-tabs .MuiButtonBase-root {
//     text-transform: capitalize;
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: normal;
//     letter-spacing: normal;
//     color: #6d787e;
// }

#edit-profile-tabs {
  button {
    &:global(.MuiButtonBase-root) {
      text-transform: capitalize;
      font-size: 18px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      color: #6d787e;
      width: 320px;
      align-items: self-start;
      margin-bottom: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

// .edit-profile-container #edit-profile-tabs .Mui-selected {
//     font-weight: bold;
//     color: #010d18;
//     text-decoration: underline;
// }

#edit-profile-tabs {
  button {
    &:global(.Mui-selected) {
      font-size: 18px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      color: #010d18;
      font-weight: bold;
      border-right: 2px solid $primaryColor;
      background-color: #f2f2f2;
    }
  }
}

.edit-profile-container #edit-profile-tabs-mobile .MuiTabs-indicator {
  display: none;
}

// .edit-profile-container #edit-profile-tabs-mobile .MuiButtonBase-root {
//     min-width: 0 !important;
//     text-transform: capitalize;
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: normal;
//     letter-spacing: normal;
//     color: #6d787e;
// }

#edit-profile-tabs-mobile {
  button {
    &:global(.MuiButtonBase-root) {
      min-width: 0 !important;
      text-transform: capitalize;
      font-size: 18px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      padding: 5px;
    }
  }
}

// .edit-profile-container #edit-profile-tabs-mobile .Mui-selected {
//     font-weight: bold;
//     color: #010d18;
//     text-decoration: underline;
// }

#edit-profile-tabs-mobile {
  button {
    &:global(.Mui-selected) {
      font-size: 18px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      color: #010d18;
      font-weight: bold;
      background-color: #f2f2f2;
      border-bottom: 1px solid $primaryColor;
      padding: 5px;
    }
  }
}

.edit-profile-container #tabs-box {
  display: flex;
  flex-grow: 1;
}

.edit-profile-container #label-box {
  height: 100%;
  display: flex;
  align-items: center;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #010d18;
}

.edit-profile-container #email-box {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  color: #6d787e;
}

.edit-profile-container #interests-box {
  border: 1px solid $muteColor;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2vw;
}

.edit-profile-container .interest-item {
  position: relative;
  background: $primaryColor;
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: none;
  color: #fff;
  word-break: break-all;
}

#interest-item-unchecked-icon {
  color: #fff;
}

#interest-item-checked-icon {
  color: #fff;
}

.edit-profile-container .interest-item > span {
  position: absolute;
  top: -5px;
  right: -5px;
}

.edit-profile-container #accordion {
  background-color: transparent;
  box-shadow: none;
}

.edit-profile-container #accordion::before {
  height: 0;
}

.edit-profile-container #accordion-sum {
  background: #fcbf29;
  box-shadow: 0px 4px 4px rgba(1, 13, 24, 0.2);
  border-radius: 6px;
}

.edit-profile-container #accordion-sum .MuiTypography-root {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700 !important;
  line-height: 23px;
  color: #010d18;
}

.edit-profile-container .work-n-education-form {
  border: 1px solid #010d18;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2%;
  position: relative;
}

.edit-profile-container .work-n-education-form p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  color: #010d18;
  padding-bottom: 1%;
}

.edit-profile-container .work-n-education-form #delete-icon {
  position: absolute;
  top: 3%;
  right: 1%;
  cursor: pointer;
}

.edit-profile-container #tip-check-box {
  text-align: right;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #010d18;
}

.edit-profile-container #add-new-box {
  border: 1px solid #010d18;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2%;
  display: flex;
  align-items: center;
  cursor: pointer;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  color: #979da0;
}

.edit-profile-container #submit-button-box {
  width: 100%;
}

.updating {
  opacity: 0.5;
}

.updating-form {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(145.48deg, rgba(1, 13, 24, 0.12) 0%, rgba(1, 13, 24, 0.08) 99.31%);
}

.edit-profile-container #location-address {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #010d18;
}

.edit-profile-container #visited-at {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #6d787e;
}

.edit-profile-container #password-form {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #010d18;
}

.edit-profile-container #settings #label-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #242424;
}

.edit-profile-container #settings #label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.04em;
  color: #000000;
  line-height: 2;
}

.edit-profile-container #settings #blocked-icon {
  width: 28px;
}

.edit-profile-container #settings-accordion {
  background-color: transparent;
  box-shadow: none;
}

.edit-profile-container #settings-accordion::before {
  height: 0;
}

.edit-profile-container #settings-accordion-sum {
  background: transparent;
  padding: 0;
}

.edit-profile-container #settings-accordion-sum > div {
  margin: 0;
  align-self: flex-start;
}

.edit-profile-container #settings-accordion-details {
  padding: 0;
}

.edit-profile-container #settings-accordion-details > div {
  padding: 3% 0 0 0;
}

.edit-profile-container #avatar-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  color: #010d18;
}

.edit-profile-container .view-more-blocked {
  text-align: left;
  padding: 0 16px !important;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #3794eb;
}

.edit-profile-container .no-blocked-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #6d787e;
}

.oops-page-container .back-icon {
  position: absolute;
  margin: 2%;
}

.oops-page {
  background: #f2f2ee;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.oops-page .wrapper {
  position: relative;
}

.oops-page .filler {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #979da0;
  opacity: 0.5;
  border-radius: 50%;
}

.oops-page img {
  height: 100%;
  border-radius: 50%;
}

.oops-page .message {
  padding-top: 2%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

#create-album-dialog-container {
  max-width: none !important;
  max-height: none !important;
}

#media-viewer-dialog {
  max-width: none !important;
  max-height: none !important;
}

#button {
  min-width: 100px;
  margin-right: -30%;
  border-radius: 6px;
  color: #fff;
  border: 2px solid $primaryColor;
  background-color: $primaryColor;
  text-transform: none;
  &:hover {
    background-color: $primaryColor;
    border: 2px solid $primaryColor;
    border-radius: 6px;
  }
}

@media (max-width: 899px) {
  #button {
    margin-right: 0px;
  }
}

.friends-tab-container #friend-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
}

#edit-profile-switch .MuiTypography-root {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
}

#settings-language-select {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  text-align: right;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #242424;
}

#saved-item-menu {
  position: absolute;
  right: 0;
  color: #f2f2ee;
  cursor: pointer;
}

#profile-image-list {
  margin-top: 26px;
}

// .edit-profile-container #edit-profile-tabs .MuiTabs-flexContainer {
//     align-items: flex-start;
// }

#edit-profile-tabs {
  div {
    &:global(.MuiTabs-flexContainer) {
      align-items: flex-start;
    }
  }
}

.form-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

@media (min-width: 0) {
  .alert-dialog-description {
    font-size: 17px;
  }
  .edit-album-dialog .edit-album-container {
    width: 70vw;
    padding: 2vw;
  }
  .edit-album-dialog .edit-album-container .edit-album-title {
    font-size: 18px;
  }
  .create-album-dialog .footer {
    margin-top: 10px;
  }
  .create-album-dialog .drop-zone-container #message {
    font-size: 11px;
  }
  .create-album-dialog .create-album-container .create-album-form {
    padding: 0;
  }
  .create-album-dialog #upload-palette {
    width: 100%;
    height: 65px;
  }
  .create-album-dialog #upload-palette > img {
    width: 25px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone .preview-img {
    width: 100%;
    height: 65px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area {
    height: 230px;
  }
  .create-album-dialog .create-album-container {
    width: 75vw;
    height: 500px;
    padding: 20px;
  }
  .create-album-dialog .create-album-container .create-album-title {
    font-size: 18px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone #label {
    font-size: 15px;
  }
  #settings-language-select {
    font-size: 13px;
  }
  .edit-profile-container #settings #label-title {
    font-size: 13px;
  }
  #edit-profile-switch .MuiTypography-root {
    font-size: 13px;
  }
  .edit-profile-container #settings #label {
    font-size: 12px;
  }
  .edit-profile-container #avatar-title {
    font-size: 13px;
  }
  .edit-profile-container .interest-item {
    height: 75px;
    font-size: 9px;
  }
  .edit-profile-container .interest-item > img {
    width: 35px;
  }

  .edit-profile-container #tabs-box {
    flex-direction: column;
  }
  .edit-profile-container #edit-profile-tabs {
    display: none;
  }
  .edit-profile-container #edit-profile-tabs-mobile {
    display: flex;
    border-bottom: 1px solid $muteColor;
  }
  .edit-profile-container #visited-at {
    font-size: 13px;
  }
  .edit-profile-container #location-address {
    font-size: 13px;
  }
  .edit-profile-container #add-new-box {
    font-size: 15px;
  }
  .edit-profile-container #accordion-sum {
    font-size: 17px;
  }
  .edit-profile-container .work-n-education-form p {
    font-size: 15px;
  }
  .edit-profile-container #email-box {
    font-size: 15px;
  }
  .edit-profile-container #label-box {
    font-size: 17px;
    padding-bottom: 10px;
  }
  .edit-profile-container #edit-profile-tabs .MuiButtonBase-root {
    font-size: 16px;
  }
  .edit-profile-container #edit-profile-tabs-mobile .MuiButtonBase-root {
    font-size: 16px;
  }
  .ui-filler {
    padding-bottom: 50px;
  }
  .user-review-container .review-item .entity-title {
    font-size: 14px;
  }
  .user-review-container .review-item .entity-info {
    font-size: 12px;
  }
  .user-review-container .user-review-title {
    font-size: 16px;
  }
  .user-review-desc {
    font-size: 14px;
  }
  .saved-tab-container .main-content .item {
    font-size: 13px;
  }
  .item .gray {
    font-size: 12px;
  }
  .user-profile-container .banner {
    height: 100%;
  }
  .user-profile-container .banner #desktop {
    display: none;
  }
  .user-profile-container .banner #mobile {
    display: block;
    margin-top: -75px;
  }
  .user-profile-container .banner .shading {
    z-index: -1;
  }
  .user-profile-container .banner .cover-img-loading {
    height: 300px;
    position: relative;
  }
  .user-profile-container .banner #cover-img {
    height: 100%;
    position: relative;
  }
  .user-profile-container .banner .left .shading {
    display: none;
  }
  .user-profile-container .banner .left .user-info .action-button {
    width: 100%;
    text-align: center;
    position: inherit;
    padding-top: 20px;
    background: #f2f2ee;
  }
  .user-profile-container .banner .left .user-info .info .divider > div {
    background-color: #000000;
  }
  .user-profile-container .banner .left .user-info .info {
    background: #f2f2ee;
  }
  .user-profile-container .banner .left .user-info .info .name {
    font-size: 20px;
    color: #000000;
  }
  .user-profile-container .banner .left .user-info .info .location {
    font-size: 12px;
    color: #000000;
  }
  .user-profile-container .banner .left .user-info .info .follows {
    font-size: 13px;
    color: #000000;
    padding-top: 0;
  }
  .user-profile-container .banner .left .user-info .info .follows .follow-count {
    font-size: 22px;
    color: #000000;
  }
  #button {
    font-size: 13px;
  }
  .user-profile-container .banner .left .user-info #profile-avatar {
    width: 110px;
    height: 110px;
  }
  .user-profile-container .banner .left .user-info #profile-badge {
    width: 25px;
    height: 25px;
  }
  .user-profile-container .banner #upload-cover-img {
    position: absolute;
    bottom: 190px;
    right: 10px;
    width: 25px;
  }
  .user-profile-container #mobile-tabs .MuiButtonBase-root {
    font-size: 14px;
  }
  .user-profile-container #tabs .MuiButtonBase-root {
    font-size: 14px;
  }
  .user-profile-container #tabs {
    display: none;
    margin-top: 15px;
  }
  .user-profile-container #mobile-tabs {
    display: flex;
    margin-top: 15px;
  }
  .user-profile-container .main-content-container .side #title {
    font-size: 15px;
  }
  .user-profile-container .main-content-container .side .bio #bio-text {
    font-size: 13px;
  }
  .user-profile-container .main-content-container .side .bio #about {
    font-size: 13px;
  }
  .user-profile-container .main-content-container .side .visited {
    font-size: 13px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .user-profile-container .main-content-container .side .interests .interests-list {
    font-size: 11px;
  }
  .user-profile-container .main-content-container .side .bio {
    margin-top: 15px;
  }
  .user-profile-container .main-content-container .side .interests {
    margin-top: 15px;
  }
  .gallery-tab-container .albums-tab-container .album-item .album #create-icon > img {
    width: 25px;
    height: 25px;
  }
  .gallery-tab-container .albums-tab-container .info #create-album-label {
    font-size: 14px;
  }
  #profile-image-list #profile-image-list-item {
    height: 100px !important;
    cursor: pointer;
  }
  .gallery-tab-container .videos-grid-container .video-item video {
    height: 100px;
  }
  .gallery-tab-container .videos-grid-container .video-item img {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
  }
  .friends-tab-container #friends-tabs .MuiButtonBase-root {
    font-size: 13px;
    min-height: 25px;
    min-width: 80px;
  }
  .friends-tab-container #friends-tabs {
    min-height: 25px;
  }
  .friend-list-container {
    margin-top: 15px;
  }
  .friend-list-container .MuiInputBase-root {
    font-size: 13px;
    width: 100%;
  }
  .friend-list-container .MuiInputBase-root svg {
    font-size: 16px;
  }
  .friend-list-container .friends-count-label {
    font-size: 13px;
  }
  .friend-list-container .other-friends-count-label {
    font-size: 13px;
  }
  #item-card-avatar {
    width: 35px;
    height: 35px;
  }
  #item-card-blogger-avatar {
    width: 35px;
    height: 35px;
    border: 2px solid #fa3f58;
  }
  #frilist-profile-badge {
    width: 18px;
    height: 18px;
  }
  #more-vert-icon {
    font-size: 1.2rem;
    color: #010d18;
  }
  .friends-tab-container #friend-name {
    font-size: 12px;
  }
  .friends-tab-container #action-button {
    font-size: 12px;
  }
  .details-ablum-top-section .left-side #album-name {
    font-size: 16px;
  }
  .details-ablum-top-section .left-side #updated-date {
    font-size: 12px;
  }
  .details-album-container #mars {
    font-size: 13px;
  }
  .menu-item {
    font-size: 13px;
  }
  .menu-item > img {
    width: 14px;
    margin-right: 5px;
  }
  #long-menu .MuiMenuItem-root {
    min-height: 30px;
    font-size: 14px;
  }
  .friend-requests-container #action-mobile {
    display: block;
  }
  .friend-requests-container #action-desktop {
    display: none;
  }
  .saved-tab-container #filter-box .MuiSelect-select {
    font-size: 14px;
  }
  #user-profile-saved-filter-menu {
    font-size: 14px;
    min-height: 30px;
  }

  #tab-panel {
    padding-left: 0;
    border-left: 0;
    padding-top: 30px;
  }
}

@media (min-width: 400px) {
  .edit-profile-container .interest-item {
    height: 95px;
    font-size: 9px;
  }
  .edit-profile-container .interest-item > img {
    width: 45px;
  }
  #profile-image-list #profile-image-list-item {
    height: 150px !important;
  }
  .gallery-tab-container .videos-grid-container .video-item video {
    height: 150px;
  }
  .gallery-tab-container .videos-grid-container .video-item img {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
  }
  .friend-list-container .MuiInputBase-root {
    font-size: 14px;
    width: auto;
  }
  .friend-list-container .MuiInputBase-root svg {
    font-size: 18px;
  }
  .friends-tab-container #friends-tabs .MuiButtonBase-root {
    min-height: 38px;
  }
  .friend-requests-container #action-mobile {
    display: none;
  }
  .friend-requests-container #action-desktop {
    display: block;
  }
  .user-profile-container .banner #upload-cover-img {
    position: absolute;
    bottom: 220px;
    right: 10px;
    width: 25px;
  }
  .user-profile-container .banner .left .user-info #profile-avatar {
    width: 130px;
    height: 130px;
  }
  .user-profile-container .banner .left .user-info .info .follows {
    padding-top: 5px;
  }
}

@media (min-width: 500px) {
  .user-profile-container #tabs {
    display: flex;
  }
  .user-profile-container #mobile-tabs {
    display: none;
  }
  #profile-image-list #profile-image-list-item {
    height: 190px !important;
  }
  .gallery-tab-container .videos-grid-container .video-item video {
    height: 190px;
  }
  .gallery-tab-container .videos-grid-container .video-item img {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
  }
  .user-profile-container .banner .left .user-info .info .follows {
    padding-top: 10px;
  }
  .user-profile-container .banner #upload-cover-img {
    position: absolute;
    bottom: 225px;
    right: 10px;
    width: 25px;
  }
}

@media (min-width: 600px) {
  .alert-dialog-description {
    font-size: 18px;
  }
  .edit-album-dialog .edit-album-container {
    width: 60vw;
    padding: 2vw;
  }
  .edit-album-dialog .edit-album-container .edit-album-title {
    font-size: 26px;
  }
  .create-album-dialog .footer {
    margin-top: 15px;
  }
  .create-album-dialog .drop-zone-container #message {
    font-size: 13px;
  }
  .create-album-dialog .create-album-container .create-album-form {
    padding: 0 5vw;
  }
  .create-album-dialog #upload-palette {
    width: 100%;
    height: 65px;
  }
  .create-album-dialog #upload-palette > img {
    width: 25px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone .preview-img {
    width: 100%;
    height: 65px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area {
    height: 220px;
  }
  .create-album-dialog .create-album-container {
    width: 70vw;
    height: 500px;
    padding: 2vw;
  }
  .create-album-dialog .create-album-container .create-album-title {
    font-size: 26px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone #label {
    font-size: 16px;
  }
  #settings-language-select {
    font-size: 14px;
  }
  .edit-profile-container #settings #label-title {
    font-size: 14px;
  }
  #edit-profile-switch .MuiTypography-root {
    font-size: 14px;
  }
  .edit-profile-container #settings #label {
    font-size: 12px;
  }
  .edit-profile-container #avatar-title {
    font-size: 14px;
  }
  .edit-profile-container .interest-item {
    height: 110px;
    font-size: 11px;
  }
  .edit-profile-container .interest-item > img {
    width: 60px;
  }
  .edit-profile-container {
    margin-top: 0;
    padding-top: 25px;
    display: flex;
    justify-content: center;
  }
  .edit-profile-container #visited-at {
    font-size: 14px;
  }
  .edit-profile-container #location-address {
    font-size: 14px;
  }
  .edit-profile-container #add-new-box {
    font-size: 16px;
  }
  .edit-profile-container #accordion-sum {
    font-size: 18px;
  }
  .edit-profile-container .work-n-education-form p {
    font-size: 16px;
  }
  .edit-profile-container #email-box {
    font-size: 16px;
  }
  .edit-profile-container #label-box {
    font-size: 18px;
  }
  .edit-profile-container #edit-profile-tabs .MuiButtonBase-root {
    font-size: 17px;
  }
  .edit-profile-container #edit-profile-tabs-mobile .MuiButtonBase-root {
    font-size: 17px;
  }
  .ui-filler {
    padding-bottom: 0;
  }
  .user-review-container .review-item .entity-title {
    font-size: 15px;
  }
  .user-review-container .review-item .entity-info {
    font-size: 13px;
  }
  .user-review-container .user-review-title {
    font-size: 17px;
  }
  .user-review-desc {
    font-size: 15px;
  }
  .saved-tab-container .main-content .item {
    font-size: 14px;
  }
  .item .gray {
    font-size: 13px;
  }
  .user-profile-container .banner {
    height: 400px;
  }
  .user-profile-container .banner #desktop {
    display: block;
    height: 100%;
  }
  .user-profile-container .banner #mobile {
    display: none;
  }
  .user-profile-container .banner .shading {
    z-index: 0;
  }
  .user-profile-container .banner .cover-img-loading {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .user-profile-container .banner #cover-img {
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
  .user-profile-container .banner .left .shading {
    display: block;
  }
  .user-profile-container .banner .left .user-info .info {
    background: transparent;
  }
  .user-profile-container .banner .left .user-info .info .divider > div {
    background-color: #ffffff;
  }
  .user-profile-container .banner .left .user-info .action-button {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 7%;
    background: transparent;
  }
  .user-profile-container .banner .left .user-info .info .name {
    font-size: 22px;
    color: #ffffff;
  }
  .user-profile-container .banner .left .user-info .info .location {
    font-size: 13px;
    color: #ffffff;
  }
  .user-profile-container .banner .left .user-info .info .follows {
    font-size: 14px;
    color: #ffffff;
  }
  .user-profile-container .banner .left .user-info .info .follows .follow-count {
    font-size: 23px;
    color: #ffffff;
  }
  #button {
    font-size: 14px;
  }
  .user-profile-container .banner .left .user-info #profile-avatar {
    width: 135px;
    height: 135px;
  }
  .user-profile-container .banner .left .user-info #profile-badge {
    width: 28px;
    height: 28px;
  }
  .user-profile-container .banner #upload-cover-img {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 28px;
  }
  .user-profile-container #tabs {
    margin-top: 25px;
  }
  .user-profile-container #mobile-tabs {
    margin-top: 25px;
  }
  .user-profile-container #tabs .MuiButtonBase-root {
    font-size: 15px;
  }
  .user-profile-container .main-content-container .side #title {
    font-size: 16px;
  }
  .user-profile-container .main-content-container .side .bio #bio-text {
    font-size: 14px;
  }
  .user-profile-container .main-content-container .side .bio #about {
    font-size: 14px;
  }
  .user-profile-container .main-content-container .side .visited {
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .user-profile-container .main-content-container .side .interests .interests-list {
    font-size: 12px;
  }
  .user-profile-container .main-content-container .side .bio {
    margin-top: 50px;
  }
  .user-profile-container .main-content-container .side .interests {
    margin-top: 25px;
  }
  .gallery-tab-container .albums-tab-container .album-item .album #create-icon > img {
    width: 30px;
    height: 30px;
  }
  .gallery-tab-container .albums-tab-container .info #create-album-label {
    font-size: 15px;
  }
  #profile-image-list #profile-image-list-item {
    height: 230px !important;
  }
  .gallery-tab-container .videos-grid-container .video-item video {
    height: 230px;
  }
  .gallery-tab-container .videos-grid-container .video-item img {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
  }
  .friends-tab-container #friends-tabs .MuiButtonBase-root {
    font-size: 15px;
    min-width: 90px;
  }
  .friend-list-container .MuiInputBase-root {
    font-size: 15px;
  }
  .friend-list-container .MuiInputBase-root svg {
    font-size: 19px;
  }
  .friend-list-container .friends-count-label {
    font-size: 15px;
  }
  .friend-list-container .other-friends-count-label {
    font-size: 15px;
  }
  #item-card-avatar {
    width: 40px;
    height: 40px;
  }
  #item-card-blogger-avatar {
    width: 40px;
    height: 40px;
    border: 2px solid #fa3f58;
  }
  #frilist-profile-badge {
    width: 20px;
    height: 20px;
  }
  #more-vert-icon {
    font-size: 1.2rem;
  }
  .friends-tab-container #friend-name {
    font-size: 15px;
  }
  .friends-tab-container #action-button {
    font-size: 14px;
  }
  .details-ablum-top-section .left-side #album-name {
    font-size: 17px;
  }
  .details-ablum-top-section .left-side #updated-date {
    font-size: 13px;
  }
  .details-album-container #mars {
    font-size: 14px;
  }
  .menu-item {
    font-size: 14px;
  }
  .menu-item > img {
    width: 16px;
    margin-right: 5px;
  }
  #long-menu .MuiMenuItem-root {
    min-height: 38px;
    font-size: 15px;
  }
  .saved-tab-container #filter-box .MuiSelect-select {
    font-size: 15px;
  }
  #user-profile-saved-filter-menu {
    font-size: 15px;
  }
}

@media (min-width: 800px) {
  .edit-album-dialog .edit-album-container {
    width: 50vw;
    padding: 2vw;
  }
  .create-album-dialog .create-album-container {
    width: 60vw;
    height: 500px;
    padding: 2vw;
  }
  #profile-image-list #profile-image-list-item {
    height: 240px !important;
  }
  .gallery-tab-container .videos-grid-container .video-item video {
    height: 240px;
  }
  .gallery-tab-container .videos-grid-container .video-item img {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
  }
}

@media (min-width: 900px) {
  .user-profile-container #tabs {
    margin-top: -25px;
  }
  .alert-dialog-description {
    font-size: 19px;
  }
  .edit-album-dialog .edit-album-container {
    width: 45vw;
    padding: 2vw;
  }
  .edit-album-dialog .edit-album-container .edit-album-title {
    font-size: 28px;
  }
  .create-album-dialog #upload-palette {
    width: 100%;
    height: 65px;
  }
  .create-album-dialog #upload-palette > img {
    width: 25px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone .preview-img {
    width: 100%;
    height: 65px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area {
    height: 220px;
  }
  .create-album-dialog .create-album-container {
    width: 50vw;
    height: 500px;
    padding: 2vw;
  }
  .create-album-dialog .create-album-container .create-album-title {
    font-size: 28px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone #label {
    font-size: 18px;
  }
  #settings-language-select {
    font-size: 15px;
  }
  .edit-profile-container #settings #label-title {
    font-size: 15px;
  }
  #edit-profile-switch .MuiTypography-root {
    font-size: 15px;
  }
  .edit-profile-container #settings #label {
    font-size: 13px;
  }
  .edit-profile-container #avatar-title {
    font-size: 15px;
  }
  .edit-profile-container .interest-item {
    height: 75px;
    font-size: 9px;
  }
  .edit-profile-container .interest-item > img {
    width: 35px;
  }
  .edit-profile-container #tabs-box {
    flex-direction: row;
  }
  .edit-profile-container #edit-profile-tabs {
    display: flex;
  }
  .edit-profile-container #edit-profile-tabs-mobile {
    display: none;
  }
  .edit-profile-container #visited-at {
    font-size: 15px;
  }
  .edit-profile-container #location-address {
    font-size: 15px;
  }
  .edit-profile-container #add-new-box {
    font-size: 17px;
  }
  .edit-profile-container #accordion-sum {
    font-size: 19px;
  }
  .edit-profile-container .work-n-education-form p {
    font-size: 17px;
  }
  .edit-profile-container #email-box {
    font-size: 17px;
  }
  .edit-profile-container #label-box {
    font-size: 19px;
  }
  .edit-profile-container #edit-profile-tabs .MuiButtonBase-root {
    font-size: 19px;
  }
  .edit-profile-container #edit-profile-tabs-mobile .MuiButtonBase-root {
    font-size: 19px;
  }
  .user-review-container .review-item .entity-title {
    font-size: 16px;
  }
  .user-review-container .review-item .entity-info {
    font-size: 14px;
  }
  .user-review-container .user-review-title {
    font-size: 18px;
  }
  .user-review-desc {
    font-size: 16px;
  }
  .saved-tab-container .main-content .item {
    font-size: 15px;
  }
  .item .gray {
    font-size: 14px;
  }
  .user-profile-container .main-content-container .side {
    position: sticky;
    top: -99%;
  }
  .user-profile-container .main-content-container .side-sx {
    position: sticky;
    top: 10%;
  }
  .user-profile-container .main-content-container .main > .MuiTabs-root {
    margin-top: -20px;
  }
  .user-profile-container .banner .left .user-info .info .name {
    font-size: 24px;
  }
  .user-profile-container .banner .left .user-info .info .location {
    font-size: 15px;
  }
  .user-profile-container .banner .left .user-info .info .follows {
    font-size: 15px;
  }
  .user-profile-container .banner .left .user-info .info .follows .follow-count {
    font-size: 24px;
  }
  #button {
    font-size: 15px;
  }
  .user-profile-container .banner {
    height: 400px;
  }
  .user-profile-container .banner .left .user-info #profile-avatar {
    width: 140px;
    height: 140px;
  }
  .user-profile-container .banner .left .user-info #profile-badge {
    width: 30px;
    height: 30px;
  }
  .user-profile-container .banner #upload-cover-img {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
  }
  .user-profile-container #tabs .MuiButtonBase-root {
    font-size: 17px;
  }
  .user-profile-container .main-content-container .side #title {
    font-size: 17px;
  }
  .user-profile-container .main-content-container .side .bio #bio-text {
    font-size: 15px;
  }
  .user-profile-container .main-content-container .side .bio #about {
    font-size: 15px;
  }
  .user-profile-container .main-content-container .side .visited {
    font-size: 15px;
  }
  .user-profile-container .main-content-container .side .interests .interests-list {
    font-size: 12px;
  }
  .gallery-tab-container .albums-tab-container .album-item .album #create-icon > img {
    width: 35px;
    height: 35px;
  }
  .gallery-tab-container .albums-tab-container .info #create-album-label {
    font-size: 17px;
  }
  #profile-image-list #profile-image-list-item {
    height: 200px !important;
  }
  .gallery-tab-container .videos-grid-container .video-item video {
    height: 200px;
  }
  .gallery-tab-container .videos-grid-container .video-item img {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 45px;
  }
  .friends-tab-container #friends-tabs .MuiButtonBase-root {
    font-size: 17px;
    min-height: 48px;
  }
  .friend-list-container {
    margin-top: 25px;
  }
  .friend-list-container .MuiInputBase-root {
    font-size: 16px;
  }
  .friend-list-container .MuiInputBase-root svg {
    font-size: 20px;
  }
  .friend-list-container .friends-count-label {
    font-size: 17px;
  }
  .friend-list-container .other-friends-count-label {
    font-size: 17px;
  }
  #item-card-avatar {
    width: 45px;
    height: 45px;
  }
  #item-card-blogger-avatar {
    width: 45px;
    height: 45px;
    border: 2px solid #fa3f58;
  }
  #frilist-profile-badge {
    width: 21px;
    height: 21px;
  }
  #more-vert-icon {
    font-size: 1.2rem;
  }
  .friends-tab-container #friend-name {
    font-size: 17px;
  }
  .friends-tab-container #action-button {
    font-size: 15px;
  }
  .details-ablum-top-section .left-side #album-name {
    font-size: 18px;
  }
  .details-ablum-top-section .left-side #updated-date {
    font-size: 14px;
  }
  .details-album-container #mars {
    font-size: 15px;
  }
  .menu-item {
    font-size: 15px;
  }
  .menu-item > img {
    width: 17px;
    margin-right: 10px;
  }
  #long-menu .MuiMenuItem-root {
    min-height: 48px;
    font-size: 16px;
  }
  .saved-tab-container #filter-box .MuiSelect-select {
    font-size: 17px;
  }
  #user-profile-saved-filter-menu {
    font-size: 17px;
  }

  #tab-panel {
    padding-left: 25px;
    border-left: 1px solid $muteColor;
    padding-top: 0;
  }
}

@media (min-width: 1000px) {
  .alert-dialog-description {
    font-size: 20px;
  }
  .edit-album-dialog .edit-album-container {
    width: 40vw;
    padding: 2vw;
  }
  .edit-album-dialog .edit-album-container .edit-album-title {
    font-size: 30px;
  }
  .create-album-dialog #upload-palette {
    width: 100%;
    height: 65px;
  }
  .create-album-dialog #upload-palette > img {
    width: 25px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone .preview-img {
    width: 100%;
    height: 65px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area {
    height: 300px;
  }
  .create-album-dialog .create-album-container {
    width: 50vw;
    height: 600px;
    padding: 2vw;
  }
  .create-album-dialog .create-album-container .create-album-title {
    font-size: 30px;
  }
  .create-album-dialog .create-album-container .create-album-form .drop-area .drop-zone #label {
    font-size: 20px;
  }
  #settings-language-select {
    font-size: 16px;
  }
  .edit-profile-container #settings #label-title {
    font-size: 16px;
  }
  #edit-profile-switch .MuiTypography-root {
    font-size: 16px;
  }
  .edit-profile-container #settings #label {
    font-size: 14px;
  }
  .edit-profile-container #avatar-title {
    font-size: 16px;
  }
  .edit-profile-container .interest-item {
    height: 85px;
    font-size: 12px;
  }
  .edit-profile-container .interest-item > img {
    width: 40px;
  }

  .edit-profile-container #visited-at {
    font-size: 16px;
  }
  .edit-profile-container #location-address {
    font-size: 16px;
  }
  .edit-profile-container #add-new-box {
    font-size: 18px;
  }
  .edit-profile-container #accordion-sum {
    font-size: 20px;
  }
  .edit-profile-container .work-n-education-form p {
    font-size: 18px;
  }
  .edit-profile-container #email-box {
    font-size: 18px;
  }
  .edit-profile-container #label-box {
    font-size: 18px;
  }

  .edit-profile-container #edit-profile-tabs .MuiButtonBase-root {
    font-size: 20px;
  }
  .edit-profile-container #edit-profile-tabs-mobile .MuiButtonBase-root {
    font-size: 20px;
  }
  .user-review-container .review-item .entity-title {
    font-size: 16px;
  }
  .user-review-container .review-item .entity-info {
    font-size: 14px;
  }
  .user-review-container .user-review-title {
    font-size: 18px;
  }
  .user-review-desc {
    font-size: 16px;
  }
  .saved-tab-container .main-content .item {
    font-size: 16px;
  }
  .item .gray {
    font-size: 14px;
  }
  .user-profile-container .banner .left .user-info .info .name {
    font-size: 25px;
  }
  .user-profile-container .banner .left .user-info .info .location {
    font-size: 16px;
  }
  .user-profile-container .banner .left .user-info .info .follows {
    font-size: 16px;
  }
  .user-profile-container .banner .left .user-info .info .follows .follow-count {
    font-size: 25px;
  }
  #button {
    font-size: 16px;
  }
  .user-profile-container .banner {
    height: 440px;
  }
  .user-profile-container .banner .left .user-info #profile-avatar {
    width: 150px;
    height: 150px;
  }
  .user-profile-container .banner .left .user-info #profile-badge {
    width: 30px;
    height: 30px;
  }
  .user-profile-container .banner #upload-cover-img {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
  }
  .user-profile-container #tabs .MuiButtonBase-root {
    font-size: 18px;
  }
  .user-profile-container .main-content-container .side #title {
    font-size: 18px;
  }
  .user-profile-container .main-content-container .side .bio #bio-text {
    font-size: 15px;
  }
  .user-profile-container .main-content-container .side .bio #about {
    font-size: 16px;
  }
  .user-profile-container .main-content-container .side .visited {
    font-size: 16px;
  }
  .user-profile-container .main-content-container .side .interests .interests-list {
    font-size: 12px;
  }
  .gallery-tab-container .albums-tab-container .album-item .album #create-icon > img {
    width: 40px;
    height: 40px;
  }
  .gallery-tab-container .albums-tab-container .info #create-album-label {
    font-size: 18px;
  }
  #profile-image-list #profile-image-list-item {
    height: 200px !important;
  }
  .gallery-tab-container .videos-grid-container .video-item video {
    height: 200px;
  }
  .gallery-tab-container .videos-grid-container .video-item img {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 45px;
  }
  .friends-tab-container #friends-tabs .MuiButtonBase-root {
    font-size: 18px;
  }
  .friend-list-container {
    margin-top: 25px;
  }
  .friend-list-container .MuiInputBase-root {
    font-size: 17px;
  }
  .friend-list-container .MuiInputBase-root svg {
    font-size: 21px;
  }
  .friend-list-container .friends-count-label {
    font-size: 20px;
  }
  .friend-list-container .other-friends-count-label {
    font-size: 20px;
  }
  #item-card-avatar {
    width: 50px;
    height: 50px;
  }
  #item-card-blogger-avatar {
    width: 50px;
    height: 50px;
    border: 2px solid #fa3f58;
  }
  #frilist-profile-badge {
    width: 22px;
    height: 22px;
  }
  #more-vert-icon {
    font-size: 1.2rem;
  }
  .friends-tab-container #friend-name {
    font-size: 18px;
  }
  .friends-tab-container #action-button {
    font-size: 16px;
  }
  .details-ablum-top-section .left-side #album-name {
    font-size: 19px;
  }
  .details-ablum-top-section .left-side #updated-date {
    font-size: 15px;
  }
  .details-album-container #mars {
    font-size: 16px;
  }
  .menu-item {
    font-size: 16px;
  }
  .menu-item > img {
    width: 18px;
    margin-right: 10px;
  }
  .saved-tab-container #filter-box .MuiSelect-select {
    font-size: 18px;
  }
  #user-profile-saved-filter-menu {
    font-size: 18px;
  }

  .edit-profile-container #label-box {
    padding-bottom: 0;
  }
}
.feature_done_icon {
  font-size: 17px !important;
  color: $primaryColor;
}
