@import "@fontsource/sarina";
@import "@fontsource/roboto";
@import "./../../../../../../css/variable.scss";

.for-shalgoers-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-wrap {
  width: 100%;
  padding: 0 10%;
}

.contacts-dialog {
  width: auto;
}

.smoove-ic-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryColor;
  border-radius: 50%;
  margin: 4px auto;
}

.contacts-content-container {
  padding: 3% 4%;
}

.contacts-header {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #010d18;
  margin-top: 20px;
}

.contact-style {
  margin-bottom: 20px;
  background-color: white;
  border: 0.5px solid $primaryColor;
  border-radius: 5px;
  text-align: center;
  align-items: center;
  padding: 10px 15px 10px 5px;
  word-break: break-all;
}

.contact-details-container {
  display: flex;
  align-items: center;
}

.tabs > div > div {
  div {
    &:global(.MuiTabs-flexContainer) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

.tabs {
  span {
    &:global(.MuiTab-wrapper) {
      font-family: "Roboto";
      font-style: normal;
      font-size: 15px;
      text-transform: capitalize;
      color: #000000;
      font-weight: bold;
    }
  }
}

.contacts-state {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contacts-selectState {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #6d787e;
  margin: 15px 0px;
}

.contacts-selectBox {
  margin-left: 10px;
  margin-top: 10px;
}

#police-list {
  width: 100%;
}

#emergency-list {
  width: 100%;
}

.list-title {
  display: flex;
  align-items: center;
  padding: 20px 20%;
  justify-content: center;
}

.police-label {
  flex: 0.5;
  text-align: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
}

.contact-details {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #010d18;
  flex: 0.8;
  text-align: left;
}

.tab-panel {
  height: auto;
  overflow: hidden;
}

.close-button {
  position: absolute !important;
  right: 0;
  top: 0;
  color: #000 !important;
}

.police-icon {
  width: 40px;
  height: auto;
}

.icon {
  flex: 0.5;
  text-align: center;
}

.small-icon {
  color: $primaryColor;
}

.icon-side {
  flex: 0.2;
}

.indicator {
  background-color: $primaryColor !important;
}

.menu-style {
  max-height: calc(95% - 170px);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.contact-grid {
  height: 50vh;
  padding-bottom: 15px;
  overflow-y: scroll;
}

/* Extra small devices (phones; 600px and down) */
@media only screen and (min-width: 0) {
  .police-label {
    font-size: 15px;
  }

  .contact-details {
    font-size: 13px;
  }

  #help {
    font-size: 12px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .contact-details {
    font-size: 13px;
  }
  #help {
    font-size: 12px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 500px) {
  .contact-wrap {
    padding: 0 !important;
  }
}
@media only screen and (max-width: 600px) {
  .contacts-state {
    display: block !important;
    margin-bottom: 20px !important;
    text-align: center !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .police-label {
    font-size: 19px;
  }

  .contact-details {
    font-size: 16px;
  }

  #help {
    font-size: 14px;
  }
}
