@import "../../css/variable.scss";

#panel1a-header {
  div {
    justify-content: center;
    align-items: center;
  }
}

.more-review-icon {
  width: 20px;
}

.expand {
  display: flex;
  flex-direction: column;
}

#expand-label {
  text-transform: capitalize;
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.preview-text-light {
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  cursor: pointer;
}

.bubble-light {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  border: 1px solid #bebebe;
}

.bubble-light:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #ffffff transparent;
  border-width: 35px 24px 0;
  bottom: -34px;
  left: 6%;
  margin-left: 0px;
}

.bubble-light:before {
  content: "";
  position: absolute;
  width: 0;
  z-index: 0;
  border-style: solid;
  border-color: #bebebe transparent;
  border-width: 35px 24px 0;
  bottom: -35.5px;
  left: 6%;
  margin-left: 0px;
  display: block;
}

.rating-icons {
  display: flex;
  justify-content: center;
}

.review-description {
  padding-top: 10px;
}

.left-quotation,
.right-quotation {
  width: 10px;
}

.right-quotation {
  position: absolute;
}

.more {
  color: $seeMoreColor;
  padding-left: 5px;
  cursor: pointer;
}

#create-post-badge {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

#review-profile-avatar {
  width: 50px;
  height: 50px;
}

#create-post-blogger-avatar {
  cursor: pointer;
  border: 2px solid #fa3f58;
}

#create-post-avatar {
  cursor: pointer;
  border: 2px solid #86beb1;
}

.person-info {
  padding-top: 37px;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.info {
  padding-left: 10px;
}

.info .name {
  color: #000;
  font-weight: 500;
}

.info .city,
.time {
  font-size: 12px;
  color: $grayColor;
}

.no-review-label {
  text-align: center;
}

#seemore-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
}

#seemore {
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: "roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: $primaryColor;
  border-radius: 8px;
  text-transform: none;
  width: 160px;
  height: 40px;
  font-size: 16px;
}

.review-item-image-container {
  text-align: center;
  padding: 20px;
}

.review-item-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin: 0 3px;
  border-radius: 5px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .review-description {
    font-size: 16px;
  }
  .info .name {
    font-size: 16px;
  }
}
