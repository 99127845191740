#business-media {
  margin: 10px 0 0 0 !important;
  cursor: pointer;
}

#business-media-lg {
  margin: 10px 0 0 0 !important;
  cursor: pointer;
}

#business-media {
  img {
    border-radius: 8px;
    pointer-events: none;
  }
}

#business-media video {
  width: 100%;
  border-radius: 8px;
  height: 100%;
  object-fit: scale-down;
}

#business-media-lg img {
  border-radius: 8px;
}

#more-media-icon {
  background: rgba(224, 224, 224, 0.5);
  width: 30px;
  height: 30px;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #010d18;
}

@media (min-width: 0) {
  #business-media {
    height: 100px;
  }
  #business-media-lg {
    height: 150px;
  }
  #business-media #normal-item {
    height: 96px !important;
  }
  #business-media-lg #normal-item {
    height: 150px !important;
  }
  #business-media-lg #three-grid-item {
    height: 73px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 98px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 48px !important;
  }
}

@media (min-width: 261px) {
  #business-media {
    height: 140px;
  }
  #business-media-lg {
    height: 190px;
  }
  #business-media #normal-item {
    height: 136px !important;
  }
  #business-media-lg #normal-item {
    height: 190px !important;
  }
  #business-media-lg #three-grid-item {
    height: 93px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 124.7px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 61.3px !important;
  }
}

@media (min-width: 361px) {
  #business-media {
    height: 170px;
  }
  #business-media-lg {
    height: 220px;
  }
  #business-media #normal-item {
    height: 166px !important;
  }
  #business-media-lg #normal-item {
    height: 220px !important;
  }
  #business-media-lg #three-grid-item {
    height: 108px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 144.7px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 71.3px !important;
  }
}

@media (min-width: 441px) {
  #business-media {
    height: 210px;
  }
  #business-media-lg {
    height: 260px;
  }
  #business-media #normal-item {
    height: 206px !important;
  }
  #business-media-lg #normal-item {
    height: 260px !important;
  }
  #business-media-lg #three-grid-item {
    height: 128px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 171.3px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 84.6px !important;
  }
}

@media (min-width: 541px) {
  #business-media {
    height: 230px;
  }
  #business-media-lg {
    height: 280px;
  }
  #business-media #normal-item {
    height: 226px !important;
  }
  #business-media-lg #normal-item {
    height: 280px !important;
  }
  #business-media-lg #three-grid-item {
    height: 138px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 184.7px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 91.3px !important;
  }
}

@media (min-width: 600px) {
  #business-media {
    height: 270px;
  }
  #business-media-lg {
    height: 320px;
  }
  #business-media #normal-item {
    height: 266px !important;
  }
  #business-media-lg #normal-item {
    height: 320px !important;
  }
  #business-media-lg #three-grid-item {
    height: 158px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 211.3px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 104.6px !important;
  }
}

@media (min-width: 700px) {
  #business-media {
    height: 300px;
  }
  #business-media-lg {
    height: 350px;
  }
  #business-media #normal-item {
    height: 296px !important;
  }
  #business-media-lg #normal-item {
    height: 350px !important;
  }
  #business-media-lg #three-grid-item {
    height: 173px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 231.3px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 114.7px !important;
  }
}

@media (min-width: 800px) {
  #business-media {
    height: 350px;
  }
  #business-media-lg {
    height: 380px;
  }
  #business-media #normal-item {
    height: 346px !important;
  }
  #business-media-lg #normal-item {
    height: 380px !important;
  }
  #business-media-lg #three-grid-item {
    height: 188px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 251.3px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 124.7px !important;
  }
}

@media (min-width: 900px) {
  #business-media {
    height: 270px;
  }
  #business-media-lg {
    height: 320px;
  }
  #business-media #normal-item {
    height: 266px !important;
  }
  #business-media-lg #normal-item {
    height: 320px !important;
  }
  #business-media-lg #three-grid-item {
    height: 158px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 211.3px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 104.6px !important;
  }
}

@media (min-width: 1000px) {
  #business-media {
    height: 350px;
  }
  #business-media-lg {
    height: 400px;
  }
  #business-media #normal-item {
    height: 346px !important;
  }
  #business-media-lg #normal-item {
    height: 400px !important;
  }
  #business-media-lg #three-grid-item {
    height: 198px !important;
  }
  #business-media-lg #multi-grid-first {
    height: 264.7px !important;
  }
  #business-media-lg #multi-grid-normal {
    height: 131.3px !important;
  }
}
