@import "../../../css/variable.scss";

.spinner {
  text-align: center;
  padding-top: 10px;
}

#spinner-style {
  color: $primaryColor;
}

.notification-list:nth-last-child(1) {
  margin-bottom: 10px;
}

.noti-list-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.notification-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.noti-list-item-text-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.notificaion-text {
  padding-left: 10px;
  font-size: 16px;
}

#notification-delete {
  float: right;
  width: 50px;
  height: 50px;
}

.date-time {
  color: $grayColor;
  font-size: 14px;
}

.nomore-data-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
}

/* xs */
@media (min-width: 0) {
  #noti-avatar {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 600px) {
  #noti-avatar {
    width: 45px;
    height: 45px;
  }
}

@media (min-width: 1000px) {
  #noti-avatar {
    width: 56px;
    height: 56px;
  }
}
