@import "../../css/variable.scss";

.root {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionIcon {
  width: 26px;
  cursor: pointer;
}

.footer-icon-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactionInfo {
  cursor: pointer;
}

.commentSection {
  display: flex;
  padding-top: 8px;
  padding-bottom: 15px;
  align-items: center;
}
.badge {
  width: 20px;
  height: 20px;
}

.bloggerAvatar {
  cursor: pointer;
  border: 2px solid #fa3f58;
}
.popover {
  pointer-events: none;
}

.reactionPopup {
  display: "flex";
  align-items: "center";
  background-color: "#F2F2EE";
  box-shadow: "0px 0px 4px rgba(1, 13, 24, 0.5)";
  border-radius: "8px";
  padding: "6px 0px 6px 12px";
}

.reactionIconContainer {
  padding-right: "12px";
}

.reactionListModal {
  width: "100%";
}

.text-footer {
  display: inline-block;
  color: $grayColor;
  font-size: 16px;
  cursor: pointer;
}

.reactIconBox {
  display: "flex";
  align-items: "center";
}

.divider {
  background-color: "black";
  margin: "10px 0px 10px 0px";
}

.alertColor {
  width: 100%;
  background-color: #e68b2e !important;
}

#recommend-save-icon {
  margin-left: 8px;
  cursor: pointer;
  background: $primaryColor;
  width: 40px;
  height: 40px;
}

.save-icon-style {
  color: #fff !important;
}

.iconSection {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.reactionSection {
  display: flex;
}
.reactionListOpen {
  display: block;
  position: absolute;
  bottom: 51px;
  right: -181px;
}
.reactionListClose {
  display: none;
}
