@import "../../css/variable.scss";

#rating{
    color: $secondaryColor;
    align-self: flex-end;
    padding-top: 5px;
}

@media  screen and (min-width: 0) {
    #rating{
        font-size: 1rem;
    }
}

@media screen and (min-width: 600) {
    #rating {
        font-size: 1.09rem;
      }
}