@import './../../css/variable.scss';

#common-back-icon {
  cursor: pointer;
  color: white;
  font-size: 28px !important;
}
.root {
  position: fixed;
  top: 80px;
  left: 20px;
  cursor: pointer;
  z-index: 1000;
  background-color: $primaryColor;
  padding: 7px 7px 1px 7px;
  border-radius: 22px;
}
