@import '@fontsource/sarina';
@import '@fontsource/roboto';
@import './../../../../css/variable.scss';
.media-menu {
  font-family: 'roboto';
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: #6d787e;
}
.media-menu > svg {
  margin-right: 0.5vw;
}
.saved-tab-loading {
  text-align: center;
}

#filter-box {
  display: flex;
  justify-content: right;
}

.select_box {
  &:global(.MuiOutlinedInput-root) {
    min-width: 125px;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #000 !important;
  }
  fieldset {
    border: 1px solid $primaryColor;
    box-sizing: border-box;
    border-radius: 8px;
  }
  svg{
    color: #000 !important;
  }
}

.select_box:hover{
  fieldset{
    border: 1px solid $primaryColor;
  }
  
}

.saved-tab-container .main-content {
  margin: 4vh 0;
}

.saved-tab-container .main-content .item > img {
  width: 100%;
  height: auto;
  aspect-ratio: 11 / 9;
  object-fit: cover;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  pointer-events: none;
}

.saved-tab-container .main-content .item {
  font-family: 'roboto';
  font-style: normal;
  font-weight: normal;
  color: #000000;
  cursor: pointer;
  word-break: break-word;
}

#user-profile-saved-filter-menu {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  color: #000;
}

.item #more {
  color: #3794eb;
}

.item .bold {
  font-weight: bold;
}

.item .black {
  color: #010d18;
}

.item .gray {
  color: $grayColor;
}

.saved-icon {
  position: absolute;
  top: 19px;
  right: 3px;
  color: $primaryColor;
  cursor: pointer;
  border-radius: 10px;
}

.nomore-data-info{
  text-align: center;
  margin-top: 10px;
}
@media (min-width: 0) {
  .media-menu {
    font-size: 12px;
  }
  .saved-tab-container .main-content .item {
    font-size: 13px;
  }
  .saved-tab-container #filter-box .MuiSelect-select {
    font-size: 14px;
  }
  #user-profile-saved-filter-menu {
    font-size: 14px;
    min-height: 30px;
  }

  .item .gray {
    font-size: 12px;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 600px) {
  .media-menu {
    font-size: 14px;
  }
  .saved-tab-container .main-content .item {
    font-size: 14px;
  }
  .saved-tab-container #filter-box .MuiSelect-select {
    font-size: 15px;
  }
  #user-profile-saved-filter-menu {
    font-size: 15px;
  }
  .item .gray {
    font-size: 13px;
  }
}

@media (min-width: 800px) {
}

@media (min-width: 900px) {
  .media-menu {
    font-size: 15px;
  }
  .saved-tab-container .main-content .item {
    font-size: 15px;
  }
  .saved-tab-container #filter-box .MuiSelect-select {
    font-size: 17px;
  }
  #user-profile-saved-filter-menu {
    font-size: 17px;
  }
  .item .gray {
    font-size: 14px;
  }
}

@media (min-width: 1000px) {
  .media-menu {
    font-size: 16px;
  }
  .saved-tab-container .main-content .item {
    font-size: 16px;
  }
  .saved-tab-container #filter-box .MuiSelect-select {
    font-size: 18px;
  }
  #user-profile-saved-filter-menu {
    font-size: 18px;
  }
  .item .gray {
    font-size: 14px;
  }
}
