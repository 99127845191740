@import "./../../../../css/variable.scss";

#create-password-container {
  letter-spacing: 0.5px;
}

.form-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

#create-password-container #form-root form {
  padding: 20px 50px;
}

#form-root {
  width: 100%;
  padding: 0;
  display: inline;
}

.input-item-label {
  margin-left: -12px;
  color: #000 !important;
  span {
    color: red;
  }
}
#form-root .MuiInputBase-input.Mui-disabled {
  color: #010d18;
}

#password-tab-btn {
  text-align: center;
  margin-top: 20px;
  text-transform: capitalize;
}

.submitButton {
  background-color: $primaryColor !important;
  color: #fff !important;
  width: 160px;
  height: 40px;
  transition: 0.7s ease-in-out;
  border-radius: 5px;
  span {
    text-transform: capitalize;
    font-size: 16px;
  }
}

.submitButton:hover {
  background-color: $primaryColor;
  color: #fff !important;
  width: 160px;
  height: 40px;
  transition: 0.7s ease-in-out;
}

.btnDisable {
  background-color: #e0e0e0 !important;
  border: 1px solid $primaryColor !important;
  color: #979da0 !important;
  width: 160px;
  height: 40px;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  font-size: 16px;
}

#password-tab-btn .MuiButton-contained {
  background-color: #ffb703;
  color: #010d18;
  width: 160px;
  height: 40px;
}

#password-tab-btn .css-sghohy-MuiButtonBase-root-MuiButton-root .Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.title {
  text-align: left;
  color: #010d18 !important;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 30px;
  letter-spacing: 0.04em !important;
  font-weight: bold !important;
}

.input_root {
  width: "100%";
  border-radius: 5;
  border-color: #010d18 !important;
  //   border: 1px solid #010d18 !important;
}
.api_error {
  padding: 0;
}

.inbox {
  width: 100%;
}

.labelStyle {
  align-items: center;
  display: flex;
  font-weight: 500;
  color: #000;
}

.inboxStyle {
  padding: 15px 0px;
}

.form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.form-item-label {
  color: #000 !important;
  margin-left: -12px;
  overflow: visible !important;
  span {
    color: red;
  }
}

.error-message {
  margin: 0 !important;
}

#form-root form {
  padding: 0 !important;
}
@media (min-width: 900px) {
  .labelStyle {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {
  .check_div {
    margin: 0px 10px 0px 10px !important;
  }
}

@media (max-width: 870px) {
  title {
    font-size: 30px !important;
    line-height: 30px !important;
  }
  .labelStyle {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .tab_label {
    min-width: 60px !important;
  }
}

@media (max-width: 570px) {
  title {
    font-size: 27px !important;
  }
}

@media (max-width: 570px) {
  title {
    font-size: 25px !important;
  }
}
