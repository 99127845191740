@import '../../css/variable.scss';

.share-icon-button {
  width: 40px;
  height: 40px;
  background-color: $primaryColor !important;
}

.share-icon-button > span {
  width: 24px !important;
}

.share-icon-button svg {
  fill: #fff;
}
