@font-face {
    font-family: 'Smoove Travel';
    src: url("../../fonts/smoove-icons.ttf") format("truetype"),
    url("../../fonts/smoove-icons.eot") format("embedded-opentype"),
    url("../../fonts/smoove-icons.woff") format("woff");
}
@import "../../css/variable.scss";
    
.ico {
    font-family: 'Smoove Travel' !important;
    font-style:normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $whiteColor;
}

.profile-badge {
    font-size: 24px;
}

.ic-service {
    display: flex;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.ic-police,
.ic-emergency-car {
    font-size: 25px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .ic-service {
        font-size: 25px;
    }
  }
    
$icons: (
    "ic_travel_money_calculator": "\ea01",
    "ic_hotel-profile": "\ea02",
    "ic_emengency_contact": "\ea03",
    "ic_resturant-profile": "\ea04",
    "ic_exchange_rate": "\ea05",
    "ic-weather": "\ea06",
    "ic-emergency-car": "\ea07",
    "ic-police": "\ea08",
    "ic-hotel": "\ea09",
    "ic-resturant": "\ea0a",
    "ic-attraction-map": "\ea0b"
);

@each $name, $glyph in $icons {
    .#{$name}:before { content: $glyph; }
}