@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import "./../../css/variable.scss";

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.apple_btn {
  margin: 0 0 0 5px;
  padding: 0 !important;
  height: 37px;
  border: 1.5px solid $primaryColor !important;
  svg {
    width: 34px;
    height: 64px;
    g {
      path {
        fill: $primaryColor !important;
      }
    }
  }
}

.logo img {
  width: 150px !important;
  overflow: unset !important;
  object-fit: contain;
  margin-right: 4px;
}

/* Interest Screen */
#interest {
  min-height: 100vh;
  background-size: cover;
  background-image: url("../../assets/authentication/Interest.jpg");
  background-position: center;
}

#circular-progress {
  color: $whiteColor;
}

.resend_otp {
  #circular-progress {
    color: $primaryColor;
  }
}

.interest-logo {
  display: flex;
  align-items: center;
  margin: 20px;
}

.interest-logo img {
  width: 136px !important;
  height: 30px !important;
  cursor: pointer;
}

.left-div {
  text-align: center;
  align-content: center;
}

.interest-sub-title {
  font-size: 25px;
  color: $whiteColor;
  padding: 3% 3% 0;
  font-weight: 600;
  letter-spacing: 1.1px;
}

#interest-grid-container {
  text-align: center;
}

.interest-box {
  width: 120px;
  cursor: pointer;
  border-radius: 10px;
  min-height: 110px;
  border: 1px solid #ffffff;
  background-color: rgba(142, 202, 230, 0.3);
  margin: 10%;
  &:hover {
    transform: scale(1.1) !important;
    transition: all ease 500ms !important;
  }
}

.interest-checkbox-root {
  padding: 9px 9px 0px 9px;
  display: flex;
  justify-content: flex-end;
}

#interest-checked-icon {
  border: 2px solid $primaryColor;
  border-radius: 50px;
  background-color: $whiteColor;
  fill: $primaryColor !important;
  font-size: 20px !important;
}

#interest-check-icon {
  border: 2px solid $primaryColor;
  border-radius: 50px;
  background-color: $whiteColor;
  fill: $whiteColor !important;
  font-size: 20px !important;
}

#interest-icon {
  font-size: 45px !important;
  width: 45px !important;
}

.interest-name {
  font-size: 12px;
  color: $whiteColor;
  padding: 2% 0% 2% 0%;
  margin-bottom: 2%;
}

.interest-left-message {
  color: $whiteColor;
  text-align: left;
  font-size: 20px;
  padding: 2% 15%;
}

.interest-left-message span {
  color: red;
}

.interest-left-error-message {
  text-align: center;
}

#interest-right-grid {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #3794eb;
  min-height: 100vh;
}

.interest-right-title {
  font-size: 25px;
  color: $whiteColor;
  font-weight: 600;
  letter-spacing: 1.1px;
}

.interest-right-sub-title {
  font-size: 20px;
  color: $whiteColor;
  padding: 4% 12%;
}

#interest-grid-item-right {
  margin-bottom: 3%;
}

#interest-inbox {
  width: 350px;
  border-radius: 10px;
  outline: none !important;
}

.interest-inbox-focus {
  background-color: none !important;
}

.interest-inbox-label {
  color: $whiteColor !important;
  line-height: 1.555 !important;
}

.interest-date-label {
  color: $whiteColor !important;
}

.interest-input-center {
  text-align: start !important;
  color: #fff !important;
}

.interest-input-base {
  color: #fff !important;
  border-radius: 10px !important;
  height: 100% !important;
  background-color: black;
  opacity: 0.7;
}

.required-mark {
  color: red;
  padding-left: 2px;
}

.interest-inbox-error {
  border: 1px solid red !important;
  border-radius: 10px;
}

.interest-input-root {
  border-radius: 10px;
}

#interst-today-fill {
  fill: $whiteColor !important;
  position: absolute;
}

.interest-logo .app-name {
  font-family: knewave;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  padding-left: 5px;
  cursor: pointer;
  color: #fff;
}

.logo {
  font-size: 30px;
  font-family: knewave;
  font-weight: 400;
  padding: 5%;
  color: #fff;
  cursor: pointer !important;
  display: flex;
  margin: 0;
}

.container.sign-up-mode .logo {
  color: #000000;
  pointer-events: all;
  z-index: 999;
}

.container.sign-in-mode .logo-green {
  display: none;
}

.container.sign-up-mode .logo .logo-white {
  display: none;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 55%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 3;
}

#signup-screen-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 370px !important;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

#phone-number-box {
  display: flex;
  width: 100% !important;
}

.otp-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container:before {
  content: "";
  position: absolute;
  min-height: 100vh;
  width: 2000px;
  right: 48%;
  top: 50%;
  transform: translateY(-50%);
  background-color: $primaryColor;
  box-shadow: 0px 0px 6px 10px rgba(1, 13, 24, 0.2);
  transition: 1.5s ease-in-out;

  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel-inside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.panel-center {
  position: absolute;
  top: 50%;
  left: 26%;
  transform: translate(-75%, -50%);
  z-index: 6;
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.panel-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.panel {
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 0rem 10% 2rem 12%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 35px;
  margin-top: 20px;
}

#sign-up-btn {
  min-width: 150px;
  background: none !important;
  border: 1px solid #fff;
  outline: none;
  overflow: hidden;
  height: 40px;
  border-radius: 5px;
  color: #ffffff;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 60px;
  font-size: 18px;
  text-transform: capitalize;
  padding: 6px 16px;
}

.alert-success {
  background-color: $secondaryColor !important;
}

.alert-error {
  background-color: $errorColor !important;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 48%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.sign-in-form .MuiFormHelperText-contained {
  margin: 0 !important;
}

.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container.sign-up-mode .right-panel {
  pointer-events: all;
}

.check-box-policy {
  width: 10px !important;
  height: 10px !important;
  margin: 0px 5px 0px 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 1px solid gray;
  box-shadow: none;
  font-size: 0.8em;
  text-align: center;
  line-height: 1em;
  background: white;
}

.check-box-policy:checked:after {
  content: "✔";
  color: grey;
}

.img-lock {
  width: 300px;
  margin-right: 20px;
}

.img-lock-first {
  width: 300px;
}

/***** SIGN IN *********/

@media (min-width: 1400px) {
  .panel-center {
    left: 30%;
  }
}

@media (max-width: 1400px) {
  .signin-signup {
    left: 66% !important;
  }
  .container.sign-up-mode .signin-signup {
    left: 31% !important;
  }
  .container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 40% !important;
  }

  .container:before {
    right: 60% !important;
    border-radius: 8px !important;
    width: 650px !important;
  }

  .left-panel {
    padding: 0rem 16% 2rem 4% !important;
  }
}

@media (min-width: 1200px) {
  #forgot-password-signup {
    width: 50% !important;
  }
}
@media (max-width: 1200px) {
  .signin-signup {
    left: 76% !important;
    width: 60% !important;
  }
  .container.sign-up-mode .signin-signup {
    left: 28% !important;
  }
  .panel-center {
    left: 33%;
  }
  .container:before {
    right: 48% !important;
  }
  .left-panel {
    pointer-events: all;
    padding: 0rem 10% 2rem 5% !important;
  }
  .right-panel {
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .container.sign-up-mode .signin-signup {
    left: 30% !important;
  }

  form {
    padding: 0 15px !important;
  }
}
@media screen and (min-width: 770px) and (max-width: 869px) {
  .right-panel {
    pointer-events: none;
  }
}
@media (max-width: 870px) {
  /* Signup Screen */
  .container {
    min-height: 920px !important;
    height: 100vh !important;
  }
  .panel-center {
    position: absolute;
    top: 20% !important;
    z-index: 6;
    left: 57%;
  }
  .logo {
    font-size: 25px;
    padding: 2%;
  }

  .img-lock {
    width: 150px;
  }
  .signin-signup {
    width: 100% !important;
    top: 95% !important;
    transform: translate(-50%, -100%) !important;
    transition: 1s 0.8s ease-in-out !important;
  }
  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50% !important;
  }
  .panels-container {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 2fr 1fr !important;
  }
  .panel {
    flex-direction: row;
    justify-content: space-around !important;
    align-items: center !important;
    padding: 1rem 3% !important;
    grid-column: 1 / 2 !important;
  }
  .right-panel {
    grid-row: 3 / 4;
  }
  .left-panel {
    grid-row: 1 / 2;
  }
  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }
  .panel .content {
    transition: transform 0.9s ease-in-out !important;
    transition-delay: 0.8s !important;
  }
  .panel h3 {
    font-size: 1.2rem;
  }
  .container:before {
    width: 1500px !important;
    height: 1500px !important;
    transform: translateX(-50%);
    left: 47% !important;
    bottom: 66% !important;
    right: initial;
    top: initial;
    transition: 2s ease-in-out !important;
    border-radius: 50% !important;
  }
  .container.sign-up-mode:before {
    transform: translate(-48%, 100%);
    bottom: 30% !important;
    right: initial !important;
  }
  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateY(-400px) !important;
  }
  .container.sign-up-mode .right-panel .image,
  .container.sign-up-mode .right-panel .content {
    transform: translateY(0px) !important;
    padding: 0 !important;
  }
  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px) !important;
  }
  .container.sign-up-mode .signin-signup {
    top: 20% !important;
    transform: translate(-50%, -10%) !important;
  }
  #sign-up-btn {
    margin-top: 20px;
  }

  .signup-instruction {
    z-index: 0;
  }
}

@media (max-width: 769px) {
  .signin-signup {
    top: 95% !important;
  }
  .container.sign-up-mode .signin-signup {
    top: 15% !important;
    transform: translate(-50%, -10%) !important;
  }
}
@media (max-width: 540px) {
  .panel-center {
    top: 18% !important;
  }
}
@media (max-width: 587px) {
  .container.sign-up-mode:before {
    bottom: 33% !important;
  }
}
@media (max-width: 570px) {
  /* Signup Screen */
  .container {
    padding: 1.5rem !important;
  }
  .container.sign-up-mode .signin-signup {
    top: 8% !important;
    transform: translate(-50%, 0) !important;
  }
  .signin-signup {
    top: 93% !important;
  }
  .otp-div {
    padding: 0 1.5rem !important;
  }
  .image {
    display: none;
  }

  .container:before {
    bottom: 73% !important;
    left: 50% !important;
  }
  .container.sign-up-mode:before {
    bottom: 25% !important;
    left: 40% !important;
  }

  .img-lock-first {
    width: 200px;
  }

  .panel-center {
    width: 200px;
    left: 64%;
  }
  .panel-title {
    font-size: 14px;
  }
  .email-info-text {
    font-size: 14px !important;
  }
  .btn-disabled {
    font-size: 14px !important;
  }
  #sign-up-btn {
    font-size: 14px !important;
  }

  #interest-inbox {
    width: 300px;
  }
}

@media (max-width: 400px) {
  #signup-screen-group {
    width: 300px !important;
  }
  .img-lock-first {
    width: 150px;
  }
}

@media (max-width: 320px) {
  .panel-center {
    position: absolute;
    top: 16% !important;
    left: 75%;
    width: 300px !important;
    text-align: center;
  }

  .panel h3 {
    font-size: 16px !important;
  }
  .panel p {
    font-size: 16px !important;
    margin: 0 !important;
    padding: 0.5rem 0 !important;
  }
  .panel-inside {
    width: 200px !important;
  }
  #sign-up-btn {
    margin-top: 10px;
  }
  .panel .content {
    padding-right: 7% !important;
  }
  .right-panel {
    pointer-events: none;
  }
}

@media (max-width: 280px) {
  .signin-signup {
    top: 85% !important;
  }
  #signup-screen-group {
    width: 250px !important;
  }
  .container::before {
    bottom: 76% !important;
  }
}

/* Singin Singup Form */

.container {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  //height: 100vh;
}

.sign-up-form {
  opacity: 0;
  z-index: 1;
}

.sign-in-form {
  z-index: 2;
}

.container.sign-up-mode .sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container.sign-up-mode .sign-in-form {
  opacity: 0;
  z-index: 1;
}

.signin-sigup {
  z-index: 20 !important;
}
.signin-signup-form-title {
  text-align: center;
  color: #010d18;
  font-weight: 600;
}

.tab-indicator {
  height: 0;
}

#tab-root {
  border-radius: 5px !important;
  border: 1px solid $primaryColor;
  min-height: 0;
  margin-left: 43%;
  margin-bottom: 15px;

  button {
    min-width: 72px;
    height: 25px;
  }
}

.tab-label {
  border-radius: 5px !important;
  min-height: 0 !important;
  padding: 0px 2px 0px 2px !important;
  text-transform: none !important;
}

.selected-tab {
  background-color: $primaryColor !important;
  color: #fff !important;
}

#api-error {
  padding: 8px 0px 8px 0px;
}

#inbox {
  width: 100%;
  margin-bottom: 15px;
  &:global(.Mui-focused) {
    border-color: $grayColor !important;
  }
}

#inbox-password {
  width: 100%;
  &:global(.Mui-focused) {
    border-color: $grayColor !important;
  }
}

#inbox-label {
  background: "white";
  &:global(.Mui-focused) {
    color: $grayColor !important;
  }
}

#recaptcha {
  width: 100% !important;
  margin-top: 15px;
}

#phone-inbox-select {
  padding-right: 3px;
  width: 160px !important;
}

#phone-inbox {
  width: 100%;
  margin-bottom: 15px;
}

.flag-img {
  width: 20px;
  padding-right: 2px;
}

.flag-img-nepal {
  width: 13px;
  padding-right: 2px;
}

.inbox-label {
  padding: 0 3px !important;
  line-height: 1.555 !important;
  background: #fff;
  &:global(.Mui-focused) {
    color: $primaryColor !important;
  }
}

#inbox-label-error {
  color: red;
  &:global(.Mui-focused) {
    color: red !important;
  }
}

.forgot-password {
  cursor: pointer;
  color: $grayColor !important;
  font-size: 14px;
  border: none;
  background: none;
}

.timer {
  padding-top: 5px;
}

.hold-time {
  font-size: 16px;
  color: #fff;
  background: $primaryColor;
  border-radius: 5px;
  padding: 3px;
}

#signup-terms-info {
  font-size: 14px;
  font-family: "Roboto";
  color: #000;
  padding-top: 10px !important;
}

#signup-terms-info-bold {
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.btn {
  min-width: 150px;
  background-color: $primaryColor;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 5px !important;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 10px !important;
  font-size: 18px;
  text-transform: capitalize;
  padding: 6px 16px;
}

.btn:hover {
  background-color: $primaryColor;
}

.btn-disabled {
  min-width: 150px;
  outline: none;
  height: 40px;
  border-radius: 5px;
  margin-top: 10px !important;
  cursor: pointer;
  transition: 0.5s;
  background: none;
  border: 1px solid $primaryColor;
  color: $muteColor;
  background-color: #e0e0e0;
  font-size: 18px;
  text-transform: capitalize;
  padding: 6px 16px;
}

/* Social Login */
.social-ul {
  list-style-type: none;
  padding: 1em 0;
  margin: 0;
}

.social-li {
  display: inline;
}

.facebook-icon {
  width: 37px;
  margin: 0 5px 0 5px;
  cursor: pointer;
}

.google {
  width: 37px;
  cursor: pointer;
}

/* Signup Form */
.email-info-text {
  text-align: center;
  color: $grayColor;
  padding: 0px 0px 15px 0px;
  font-size: 17px;
}

/* Signup Instruction Section */
.panel-title {
  color: #fff;
}

.panel-sub-title {
  color: #fff;
}

/* Singin Instruction Section */
.signin-instruction-panel-title {
  color: #fff;
}

/* OTP Screen */
.otp_info_text {
  font-size: 20px;
  color: $grayColor;
  padding-right: 5px;
}

.otp_text {
  font-size: 18px;
  color: #fff;
  background: $primaryColor;
  border-radius: 5px;
  padding: 5px;
}

.otp-exp-div {
  padding: 5% 0px 5% 0px;
}

.expired {
  font-size: 18px;
  color: red;
}

.resend_otp {
  font-size: 18px;
  color: $grayColor;
  padding: 3% 0px 0% 0px;
}

.disabled_resend {
  pointer-events: none;
  font-weight: 150;
  color: $grayColor;
  border-bottom: none;
  text-decoration: underline !important;
}

.resend_text {
  font-size: 18;
  color: $primaryColor !important;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.otp-sub-title {
  font-size: 20px;
  color: #000;
  text-align: center;
}

.otp-email {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.otp-input-error {
  border: 1px solid red;
  width: 43px !important;
  height: 43px !important;
  font-size: 35;
  color: #000;
  outline: red !important;
  text-align: center;
}

.otp-input-box {
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid #8ecae6;
  width: 43px !important;
  height: 43px !important;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.otp-focus-style {
  outline: none !important;
}

.otp-container {
  padding: 7% 0px 0px 0px;
}

/* Forgot Password */
.forgot-pass-titel-box {
  text-align: center;
  display: flex;
  flex-direction: column;
}

#phone-number-box-select {
  width: 160px;
  margin-right: 3px;
}

.forgot-password-sub-title {
  font-size: 16px;
  color: #010d18 !important;
  font-weight: 400 !important;
  padding: 10px 50px 40px 50px;
  margin: 0 !important;
}

/* Change Password Screen */
.change-pass-sub-title {
  font-size: 18;
  text-align: center;
  color: $grayColor;
  padding: 5% 0px 5% 0px;
}

#change-pass-inbox {
  padding-bottom: 15px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0) {
  /* Singin Singup Form Section */
  .sign-in-form {
    left: 75% !important;
  }

  .signin-signup-form-title {
    font-size: 20px;
  }

  .tab-label {
    width: 50px;
  }

  /* Signup Instruction */

  .panel-sub-title {
    font-size: 16px;
  }

  /* Signin Instruction */
  .signin-instruction-panel-title {
    font-size: 16px;
  }

  #change-pass-inbox {
    width: 90%;
  }

  #interest-grid-container {
    padding: 2% 12% 2% 12% !important;
  }

  .interest-right-title {
    padding: 4% 0% 0% 0%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tab-label {
    min-width: 70px !important;
  }
  .panel-title {
    font-size: 20px;
  }
  .signin-instruction-panel-title {
    font-size: 25px;
  }
  .panel-sub-title {
    font-size: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* Singin Singup Form Section */
  .signin-signup-form-title {
    font-size: 30px;
  }

  #change-pass-inbox {
    width: 380px;
  }
}

@media only screen and (min-width: 870px) {
  .container {
    min-height: 720px;
  }

  .container::before {
    height: 100%;
  }

  .right-panel {
    padding: 2rem 0% 2rem 20% !important;
    pointer-events: none;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* Singin Singup Form Section */
  .signin-signup-form-title {
    font-size: 35px;
  }

  #interest-grid-container {
    padding: 2% 12% 0% 12% !important;
  }

  .interest-right-title {
    padding: 15% 5% 0% 5%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* Singin Singup Form Section */
  .sign-in-form {
    left: 50% !important;
  }

  .signin-signup-form-title {
    font-size: 35px;
  }

  /* Signup Instruction */
  .panel-title {
    font-size: 30px;
  }

  .right-panel {
    padding: 2rem 0% 2rem 25% !important;
  }

  /* Signin Instruction */
  .signin-instruction-panel-title {
    font-size: 30px;
  }

  .panel-sub-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    min-height: 100vh;
  }

  .right-panel {
    padding: 0rem 12% 2rem 17% !important;
  }
}
