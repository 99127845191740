@import "../../css/variable.scss";

.pli-badge-icon-container {
    box-sizing: border-box;
    padding: 4px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $primaryColor;
}