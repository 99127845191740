@import "./../../css/variable.scss";

.review-content {
  text-align: center;
  padding-top: 20px;
}

#icon-remove-media {
  width: 20px;
  height: 20px;
}

#dialog-close-icon {
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 12px !important;
}

#create-review-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 35px;
}

#card-header-style {
  padding: 16px 0 !important;
}

#create-post-avatar {
  width: 56px;
  height: 56px;
  border: 2px solid #86beb1;
}

#create-post-blogger-avatar {
  width: 56px;
  height: 56px;
  border: 2px solid #fa3f58;
}

#create-post-badge {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

#create-post-profile-name {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.preview-text {
  background: #f2f2ee;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.create-review-sub-title {
  font-size: 16px;
  color: #000;
}

#create-review-photo-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

#create-review-photo-wrap {
  display: grid;
  gap: 10px;
}

#create-review-photo-box {
  position: relative;
  width: 100%;
  height: 100px;
}

#close-button-style {
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 4px !important;
}

#create-review-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#create-review-button {
  margin: 20px;
  background-color: $primaryColor;
}

#create-review-dialog {
  padding: 0;
  h2 {
    padding: 0;
  }
}

#create-review-dialog {
  &:global(.MuiCardHeader-avatar) {
    margin-right: 5px !important;
  }
}

#loading-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: linear-gradient(145.48deg, rgba(1, 13, 24, 0.12) 0%, rgba(1, 13, 24, 0.08) 99.31%);
}

.closeButtonStyle {
  position: absolute !important;
  right: 0;
  top: 0;
  color: #010d18 !important;
  z-index: 1000;
}

.cardHeaderStyle {
  padding-top: 0 !important;
}

#footer-container {
  justify-content: center;
  margin-bottom: 10px;
}

#button {
  background-color: $primaryColor;
  border-radius: 6px !important;
  text-transform: none;
  font-size: 16px;
  color: #fff;
}

#disable-button {
  background-color: $disableBgColor;
  border: 1px solid $primaryColor;
  color: $muteColor;
  text-transform: none;
}

#button:hover {
  border: 0px !important;
  border-radius: 6px !important;
}

@media (min-width: 0) {
  #create-review-title {
    font-size: 16px;
  }
  #create-review-dialog .MuiDialogContent-root .MuiCardHeader-root {
    padding: 0 0 16px 0;
  }
  #create-review-dialog .MuiDialogTitle-root {
    padding: 13px 10px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 10px;
  }
  #create-review-dialog .MuiDialogActions-root {
    justify-content: center;
    padding: 13px 10px;
  }
  #create-review-photo-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 400px) {
  #create-review-dialog .MuiDialogTitle-root {
    padding: 15px 20px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 20px;
  }
  #create-review-dialog .MuiDialogActions-root {
    padding: 15px 20px;
  }
}
@media (min-width: 600px) {
  #create-review-title {
    font-size: 18px;
  }
  #create-review-dialog .MuiDialogTitle-root {
    padding: 20px 25px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 25px;
  }
  #create-review-dialog .MuiDialogActions-root {
    padding: 20px 25px;
  }
  #create-review-photo-wrap {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 900px) {
  #create-review-title {
    font-size: 19px;
  }
  #create-review-dialog .MuiDialogTitle-root {
    padding: 25px 35px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 35px;
  }
  #create-review-dialog .MuiDialogActions-root {
    justify-content: center;
    padding: 25px 35px;
  }
}
@media (min-width: 1000px) {
  #create-review-title {
    font-size: 20px;
  }
  #create-review-dialog .MuiDialogTitle-root {
    padding: 30px 40px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 40px;
  }
  #create-review-dialog .MuiDialogActions-root {
    padding: 30px 40px;
  }
}
