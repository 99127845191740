/* flex justify-center items-center pt-20 */
@import './../../../../css/variable.scss';

.new_user_avatar_container {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
}

.new_user_avatar_wapper {
  position: relative;
}

.new_user_avatar {
  width: 108px !important;
  height: 108px !important;
  border: 2px solid $primaryColor;
  filter: drop-shadow(0px 0px 4px $primaryColor);
}

.new_user_camera_icon_div {
  width: 36px;
  height: 36px;
  background-color: $primaryColor !important;
  //   border: 2px solid $primaryColor !important;
  position: absolute !important;
  bottom: 0;
  right: 2px;
}

.new_user_camera_icon_div:hover {
  background: white;
}

.new_user_camera_icon {
  color: white;
}

.new_user_info_div {
  text-align: center;
}
.new_user_name {
  font-size: 25px;
  font-weight: bold;
  margin: 5px 0px 5px 0px;
}
.new_user_address {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0px 5px 0px;
  color: #000;
}
