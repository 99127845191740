@import './../../../../css/variable.scss';

.new_user_edit_div {
  text-align: center;
  margin-top: 10px;
}
.new_user_eidt_btn {
  border-radius: 8px !important;
  color: #ffffff !important;
  border: 1px solid $primaryColor !important;
  background-color: $primaryColor !important;
  text-transform: Capitalize !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
}
.new_user_tab_grid {
  padding: 10px;
}

.profile_tab {
  text-transform: capitalize !important;
  color: black !important;
  font-weight: bold !important;
}

#profile-info-root{
  height: calc(100vh - 10px);
  overflow: 'auto';
}

#profile-tabs-wappper div > span {
  display: none !important;
}

#indicator {
  border-bottom: 2px solid $primaryColor;
}
