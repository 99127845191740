@import './../../../../../../css/variable.scss';

.new_profile_info {
  display: flex;
  padding-bottom: 15px;
}

.new_profile_info_icon {
  padding-right: 20px;
  color: $primaryColor;
}

.new_profile_info_text {
  padding-top: 2px;
}
.new_profile_info_link {
  text-decoration: none;
  color: black;
}

.capitalize {
  text-transform: capitalize;
}

.interests .interests_list {
  font-family: 'roboto';
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #010d18;
}

.new_user_profile_item {
  border: 1px solid $muteColor;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1% 3%;
  word-break: break-all;
  padding: 5px;
}

.new_user_profile_item .icon {
  width: 40px;
}
.new_profile_info_interests_title {
  font-size: 16px;
}

.profile_tab {
  text-transform: capitalize !important;
  color: black !important;
  font-weight: bold !important;
}
