@import './../../../../../css/variable.scss';

.titleBox {
  display: flex;
  align-items: center;
  padding: 0 2%;
}

.seeMoreBox {
  text-align: right;
}
.seeMoreBtn {
  width: 8vw;
  height: 2.5vw;
}
#random-seemore {
  border-radius: 6px;
  background-color: $primaryColor;
  text-transform: capitalize;
  border: 2px solid $primaryColor;
}
.title {
  flex: 1;
  font-family: 'roboto';
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  color: #000000;
  margin: 0 !important;
}
.random-section {
  //   margin-bottom: 3%;
  //   border: 1px solid #ced2d9;
  border-radius: 6px;
  box-sizing: border-box;
  border-right: none;
}

.random-section > span {
  padding-left: 20px;
}
.random-section .hotels-image {
  width: 100%;
  position: absolute;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  object-fit: cover;
  border: 1px solid #ced2d9;
  pointer-events: none;
}
.alertColor {
  width: 100%;
  background-color: #e68b2e !important;
}
@media (min-width: 0) {
  .random-section .title {
    font-size: 15px !important;
  }
  .random-section {
    padding: 5px 0 5px 0;
  }
  .random-section .list {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .random-section .hotels-image {
    top: 5px;
    height: calc(100% - 45px);
  }
  #random-seemore {
    height: 20px;
    font-size: 11px;
  }
}

@media (min-width: 600px) {
  .random-section .title {
    font-size: 18px !important;
  }
  .random-section {
    padding: 5px 0 5px 0;
  }
  .random-section .list {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .random-section .hotels-image {
    top: 5px;
  }
  #random-seemore {
    height: 25px;
    font-size: 14px;
  }
}

@media (min-width: 800px) {
  .random-section .title {
    font-size: 20px !important;
  }
  .random-section {
    padding: 10px 0 0 0;
  }
  .random-section .list {
    padding-top: 10px;
    padding-bottom: 24px;
  }
  .random-section .hotels-image {
    top: 9px;
    height: calc(100% - 55px);
  }
  #random-seemore {
    height: 30px;
    font-size: 16px;
  }
}

@media (min-width: 900px) {
  .random-section .title {
    font-size: 18px !important;
  }
  .random-section {
    padding: 5px 0 5px 0;
  }
  .random-section .list {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .random-section .hotels-image {
    top: 5px;
    height: calc(100% - 55px);
  }
  #random-seemore {
    height: 25px;
    font-size: 14px;
  }
}

@media (min-width: 1000px) {
  .random-section .title {
    font-size: 20px !important;
  }
  .random-section {
    padding: 5px 0 0 0;
  }
  .random-section .list {
    padding-top: 10px;
    padding-bottom: 24px;
  }
  .random-section .hotels-image {
    top: 9px;
    height: calc(100% - 55px);
  }
  #random-seemore {
    height: 30px;
    font-size: 16px;
  }
}
