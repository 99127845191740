.review-count-label{
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
}

.nomore-data-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    color: #3794eb;
}

.nomore-data-info span {
    padding-left: 5px;
    font-family: 'roboto';
    font-style: normal;
    font-weight: bold;
}

.spinner{
    text-align: center;
    padding-top: 20px;
}