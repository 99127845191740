@import "@fontsource/sarina";
@import "@fontsource/roboto";
@import "../../css/variable.scss";

.MuiCardHeader-avatar {
  margin-right: 10px;
}

#create-post-dialog .MuiCardHeader-avatar {
  margin-right: 10px !important;
}

#create-review-dialog .MuiCardHeader-avatar {
  margin-right: 5px !important;
}

#create-post-dialog {
  max-width: none;
  background-color: #f2f2ee;
  border-radius: 8px;
  padding: 0;
}

#edit-post-dialog {
  background-color: #f2f2ee;
  border-radius: 8px;
}

#create-post-media-caption-input {
  padding: 0;
  background-color: #f2f2ee;
}

#create-post-media-caption-input .MuiOutlinedInput-root {
  padding: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
}

#icon-remove-media {
  width: 20px;
  height: 20px;
}

#create-post-avatar {
  width: 50px;
  height: 50px;
}

#create-post-footer-info {
  position: absolute;
  left: 10px;
  color: #ffb703;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}

#create-post-text-input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
}

#create-post-blogger-avatar {
  cursor: pointer;
  border: 2px solid #fa3f58;
}

#business-avatar-lg {
  cursor: pointer;
  border: 2px solid #ffb703;
}

#close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
}

#create-post-avatar {
  cursor: pointer;
  border: 2px solid #86beb1;
}

#create-post-profile-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  color: #010d18;
  line-height: 1;
}

#create-review-dialog {
  max-width: 550px;
  padding: 0;
}

#review-header {
  padding: 52px 32px 20px;
}

#create-post-badge {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

#rating {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#create-review-title {
  text-align: center;
  font-family: "Roboto";
  text-align: center;
  color: #000;
  padding: 20px 16px;
  font-weight: 500;
}

#review-description {
  padding: 0 16px;
}

#create-review-photo-icon {
  cursor: pointer;
}

#first-image-container {
  display: flex;
  justify-content: center;
}

#review-detail-grid {
  padding: 16px;
}

#first {
  height: 320px;
  width: 100%;
  object-fit: contain;
}

#second {
  width: 100%;
  object-fit: cover;
}

#portrait {
  border-radius: 8px;
}

#review-details-media img {
  border-radius: 8px;
  width: 100%;
}

#review-details-media {
  margin: 0 32px 20px;
}

.gallery-length-5 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

#reply-header {
  padding: 16px 32px;
}

.styled-avatar {
  border: 2px solid $primaryColor;
  border-radius: 50%;

  #create-post-avatar {
    margin: 0 !important;
  }
}

#badge-img {
  width: 18px;
}

.reply-description {
  margin: 0 32px 16px 98px;
  background-color: $disableBgColor;
  border-radius: 8px;
  padding: 10px;
}

@media (min-width: 0) {
  #no-comment-box .MuiTypography-root {
    font-size: 13px;
    width: 75%;
  }
  #no-comment-img {
    width: 90%;
  }
  #comment-dialog-avatar {
    width: 30px;
    height: 30px;
  }
  #add-comment-input {
    font-size: 14px;
  }
  #comment-reaction-info .MuiTypography-root {
    font-size: 13px;
  }
  #comment-item-box #username {
    font-size: 15px;
  }
  #comment-item-box {
    padding: 4px;
  }
  #comment-dialog-paper .MuiDialogContent-root {
    width: 75vw;
    padding: 10px 5px;
  }
  .pli-dropdown-menu {
    font-size: 13px;
  }
  #review-details-dialog-container {
    min-width: 83vw;
  }
  #create-review-subheader {
    font-size: 12px;
  }
  #create-review-photo-icon {
    width: 30px;
    height: 30px;
  }
  #create-review-dialog .MuiDialogContent-root .MuiCardHeader-root {
    padding: 0 0 16px 0;
  }
  #create-review-dialog .MuiDialogTitle-root {
    padding: 13px 10px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 10px;
  }
  #create-review-dialog .MuiDialogActions-root {
    justify-content: center;
    padding: 13px 10px;
  }
  #create-review-title {
    font-size: 16px;
  }
  #second {
    height: 100px;
  }

  #second-sm {
    height: 60px;
  }
}

@media (min-width: 400px) {
  #no-comment-img {
    width: 65%;
  }
  #comment-item-box {
    padding: 9px;
  }
  #comment-dialog-paper .MuiDialogContent-root {
    padding: 10px 20px;
  }
  #review-details-dialog-container {
    min-width: 80vw;
  }
  #create-review-dialog .MuiDialogTitle-root {
    padding: 15px 20px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 20px;
  }
  #create-review-dialog .MuiDialogActions-root {
    padding: 15px 20px;
  }
  #create-review-photo {
    height: 95px;
  }
  #create-review-photo-q {
    height: 95px;
    padding-right: 5px;
  }
  #create-review-photo-wrap {
    grid-template-columns: auto auto auto;
  }
}

@media (min-width: 600px) {
  #no-comment-img {
    width: 60%;
  }
  #no-comment-box .MuiTypography-root {
    font-size: 13px;
    width: 45%;
  }
  #comment-dialog-avatar {
    width: 40px;
    height: 40px;
  }
  #add-comment-input {
    font-size: 14px;
  }
  #comment-reaction-info .MuiTypography-root {
    font-size: 14px;
  }
  #comment-item-box #username {
    font-size: 16px;
  }
  #comment-dialog-paper .MuiDialogContent-root {
    width: 60vw;
  }
  .pli-dropdown-menu {
    font-size: 14px;
  }
  #review-details-dialog-container {
    min-width: 500px;
  }
  #create-review-subheader {
    font-size: 12px;
  }
  #create-review-photo-icon {
    width: 34px;
    height: 34px;
  }
  #create-review-dialog .MuiDialogTitle-root {
    padding: 20px 25px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 25px;
  }
  #create-review-dialog .MuiDialogActions-root {
    padding: 20px 25px;
  }
  #create-review-title {
    font-size: 18px;
  }
  #portrait {
    height: 320px;
    width: 250px !important;
    object-fit: contain;
  }
  #review-details-media #second {
    height: 130px;
  }
}

@media (min-width: 900px) {
  #no-comment-box .MuiTypography-root {
    font-size: 14px;
  }
  #add-comment-input {
    font-size: 15px;
  }
  #comment-reaction-info .MuiTypography-root {
    font-size: 15px;
  }
  #comment-item-box #username {
    font-size: 17px;
  }
  #comment-dialog-paper .MuiDialogContent-root {
    width: 40vw;
  }
  .pli-dropdown-menu {
    font-size: 15px;
  }
  #review-details-dialog-container {
    min-width: 600px;
  }
  #create-review-subheader {
    font-size: 13px;
  }
  #create-review-photo-icon {
    width: 40px;
    height: 40px;
  }
  #create-review-dialog .MuiDialogTitle-root {
    padding: 25px 35px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 35px;
  }
  #create-review-dialog .MuiDialogActions-root {
    justify-content: center;
    padding: 25px 35px;
  }
  #create-review-title {
    font-size: 19px;
  }
  #review-details-media #second {
    height: 150px;
  }
  #second-sm {
    height: 100px;
  }
}

@media (min-width: 1000px) {
  #no-comment-box .MuiTypography-root {
    font-size: 15px;
  }
  #add-comment-input {
    font-size: 16px;
  }
  #comment-reaction-info .MuiTypography-root {
    font-size: 16px;
  }
  #comment-item-box #username {
    font-size: 18px;
  }
  .pli-dropdown-menu {
    font-size: 16px;
  }
  #review-details-dialog-container {
    min-width: 700px;
  }
  #create-review-subheader {
    font-size: 14px;
  }
  #create-review-photo-icon {
    width: 44px;
    height: 44px;
  }
  #create-review-dialog .MuiDialogTitle-root {
    padding: 30px 40px;
    padding-bottom: 0;
  }
  #create-review-dialog .MuiDialogContent-root {
    padding: 0 40px;
  }
  #create-review-dialog .MuiDialogActions-root {
    padding: 30px 40px;
  }
  #create-review-title {
    font-size: 20px;
  }
  #create-review-photo-label {
    font-size: 16px;
  }
  #review-item-photo {
    height: 120px;
    padding-right: 10px;
  }
  #create-post-media-preview {
    height: 145px;
  }
  #create-post-media-caption-input .MuiOutlinedInput-root {
    font-size: 16px;
  }
  #create-post-profile-name {
    font-size: 18px;
  }
  #create-post-blogger-avatar {
    width: 50px;
    height: 50px;
  }
  #business-avatar-lg {
    width: 50px;
    height: 50px;
  }
  #create-post-avatar {
    width: 50px;
    height: 50px;
  }
  #create-post-text-input {
    font-size: 17px;
  }
  #create-post-footer-info {
    display: block;
  }
  #create-post-action-button {
    width: 155px;
    font-size: 16px;
  }
  #edit-post-action-button {
    width: 155px;
    font-size: 16px;
  }
}
