@import '../../css/variable.scss';
#login-alert .MuiPaper-root {
  max-width: 600px !important;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
#login-alert #positive-button {
  width: 80px;
  height: 30px;
  background: #ffb703;
  border-radius: 8px;

  font-family: 'roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

#login-alert #negative-button {
  width: 80px;
  height: 30px;
  border: 1px solid #ffb703;
  box-sizing: border-box;
  border-radius: 8px;

  font-family: 'roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffb703;
  background-color: transparent;
}

.alert-dialog-description {
  font-family: 'roboto';
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #010d18;
}

.root {
  width: 30vw;
  height: 30vw;
}
.paper {
  padding: 0 24px 24px 24px;
}
.footer {
  text-align: center;
  padding-top: 15px;
}
.buttonStyle {
  min-width: 95px !important;
  height: 30px;
  text-transform: uppercase !important;
  background-color: white !important;
  border: 2px solid $primaryColor !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
}

#buttonOutlined {
  min-width: 95px !important;
  height: 30px;
  border-radius: 8px;
  background-color: transparent;
  border: 2px solid $primaryColor;
  color: #010d18;
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  &:hover {
    background-color: transparent;
    border: 2px solid $primaryColor;
  }
}

@media (min-width: 0) {
  .alert-dialog-description {
    font-size: 17px;
  }
}

@media (min-width: 600px) {
  .alert-dialog-description {
    font-size: 18px;
  }
}

@media (min-width: 900px) {
  .alert-dialog-description {
    font-size: 19px;
  }
}

@media (min-width: 1000px) {
  .alert-dialog-description {
    font-size: 20px;
  }
}
