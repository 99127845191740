.spinner {
  padding-top: 20px;
  text-align: center;
}

.nomore-data-info {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
  font-weight: bold;
}
