@import "@fontsource/sarina";
@import "@fontsource/roboto";
@import "./../../../../../../css/variable.scss";

.weather-content-inner-container {
  background-color: rgba(1, 13, 24, 1);
  overflow: hidden;
}

.weather-unavailable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background: #fff;
}

.weather-content {
  padding: 4% 5%;
  background: #fff;
}

.weather-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 35px;
  letter-spacing: 0.04em;
  color: #010d18;
  margin-top: 50px;
}

.unavailable-reload {
  text-decoration: none;
  font-weight: 600;
  color: #000;
  padding-left: 4px;
}

.weather-bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  pointer-events: none;
}

.weather-dialog {
  display: flex;
  overflow: hidden;
  backdrop-filter: blur(2px);
  height: 500px;
}

.weather-info-title {
  color: #fff;
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.04em;
  padding-left: 4%;
  position: absolute;
}

.weather-loading {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
}

.current-weather {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

#current-weather-detail {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.current-weather-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.current-weather-desc {
  color: #fff;
  font-family: "Roboto";
  font-style: normal;
  letter-spacing: 0.04em;
  line-height: normal;
  font-weight: 700;
}

.current-weather-temp {
  color: #fff;
  font-family: "Roboto";
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.04em;
  line-height: normal;
}

.current-weather-date {
  color: #fff;
  font-family: "Roboto";
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04em;
  padding-bottom: 10px;
}

.location-icon {
  color: $primaryColor;
}

.current-state {
  display: flex;
  align-items: center;

  span {
    color: #fff;
    font-size: 30px;
    padding-top: 6px;
    font-family: "Roboto";
    font-style: normal;
    letter-spacing: 0.04em;
  }
}

.small-icon {
  width: 25px;
}

.weather-right-side {
  background: linear-gradient(180deg, rgba(1, 13, 24, 0.3) 0%, rgba(1, 13, 24, 1) 100%);
}

.right-side-top {
  display: flex;
  align-items: center;
}

#weather-howabout-title {
  flex: 0.5;
  text-align: center;
  color: #fff;
  font-family: "Roboto";
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.04em;
}

.weather-select-box {
  flex: 0.5;
}

.form-control {
  margin: 8px !important;
  min-width: 120px !important;
}

.weather-select {
  height: 40px;
  color: #fff !important;
  background-color: #00000033;
  border-radius: 5px !important;
  border: 1px solid #fff !important;
  svg {
    color: #fff !important;
  }
}

.menu-style {
  max-height: calc(100% - 250px) !important;
  background-color: rgba(1, 13, 24, 0.8) !important;
  color: #fff !important;
}

.daily-forecast-item {
  display: flex;
  align-items: center;
  padding: 0 6% 0 6%;
  color: #fff;
  font-family: "Roboto";
  font-style: normal;
  line-height: 5px;
  letter-spacing: 0.02em;
}

.close-button {
  position: absolute !important;
  right: 0;
  top: 0;
  color: #fff !important;
  z-index: 5;
}

.noconnection-close-button {
  position: absolute !important;
  right: 0;
  top: 0;
  color: #000 !important;
  z-index: 5;
}

#icon {
  flex: 0.4;
  align-items: center;
  text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0) {
  .weather-dialog {
    flex-direction: column;
  }

  .weather-title {
    font-size: 18px;
  }

  .weather-unavailable {
    font-size: 16px;
  }

  .weather-unavailable-container {
    flex-direction: column;
  }

  .weather-left-side {
    flex: 0.4;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .current-weather {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .weather-info-title {
    font-size: 16px;
    padding-top: 7%;
  }

  .current-weather-icon {
    width: 80px;
    height: auto;
  }

  .current-weather-desc {
    font-size: 20px;
  }

  .current-weather-temp {
    font-size: 30px;
  }

  .current-weather-date {
    font-size: 12px;
  }

  .location-icon {
    font-size: 20px;
  }

  .current-state {
    span {
      font-size: 12px;
    }
  }

  .weather-right-side {
    flex: 0.6;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .right-side-top {
    padding: 1%;
  }

  #weather-howabout-title {
    font-size: 14px;
  }

  .daily-forecast-container {
    padding-bottom: 1%;
  }

  .daily-forecast-item {
    font-size: 9px;
    height: 30px;
  }

  #day {
    flex: 0.3;
  }

  #temp {
    flex: 0.3;
    text-align: right;
  }

  #icon {
    img {
      width: 40px;
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .weather-dialog {
    flex-direction: row;
  }
  .weather-title {
    font-size: 20px;
  }
  .weather-unavailable {
    font-size: 18px;
  }
  .current-weather {
    padding-top: 0;
  }
  .current-weather-desc {
    font-size: 30px;
  }
  .current-weather-temp {
    font-size: 60px;
  }
  .weather-left-side {
    flex: 0.6;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .weather-info-title {
    font-size: 20px;
    padding-top: 3%;
  }
  .current-weather-icon {
    width: 170px;
  }

  .current-weather-date {
    font-size: 14px;
  }
  .location-icon {
    font-size: 30px;
  }
  .current-state {
    span {
      font-size: 16px;
    }
  }
  .weather-right-side {
    flex: 0.5;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .right-side-top {
    padding: 7%;
  }
  #weather-howabout-title {
    font-size: 20px;
  }
  .daily-forecast-container {
    padding-bottom: 7%;
  }
  .daily-forecast-item {
    height: 50px;
    font-size: 12px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .weather-info-title {
    font-size: 26px;
  }
  .weather-title {
    font-size: 20px;
  }
  .current-state span {
    font-size: 16px;
  }
  .weather-unavailable-container {
    flex-direction: row;
  }
  #weather-howabout-title {
    font-size: 22px;
  }
  .daily-forecast-item {
    font-size: 15px;
  }
  #icon {
    img {
      width: 60px;
      height: auto;
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .current-weather-icon {
    width: 200px;
  }

  .weather-title {
    font-size: 22px;
  }

  .current-weather-desc {
    font-size: 40px;
  }

  .current-weather-temp {
    font-size: 80px;
  }

  .current-state {
    span {
      font-size: 17px;
    }
  }

  .daily-forecast-item {
    font-size: 20px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .weather-title {
    font-size: 24px;
  }
  .weather-left-side {
    flex: 0.6;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .weather-right-side {
    flex: 0.4;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .current-weather-icon {
    width: 250px;
  }
}

@media (min-width: 1600px) {
  .weather-left-side {
    flex: 0.8;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .weather-right-side {
    flex: 0.6;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
