@import './../../css/variable.scss';
.details-page-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.icon {
  color: $primaryColor !important;
}
