@import "./../../../css/variable.scss";

.MuiAlert-root {
  /*background-color: #2e7d32 !important;*/
  color: #fff !important;
}

.business-profile-container {
  background: #ffffff;
}

.business-profile-container #back-icon {
  position: fixed;
  top: 70px;
  left: 2%;
  z-index: 1;
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.save-icon-button {
  width: 40px;
  height: 40px;
  background-color: $primaryColor !important;
  color: white !important;
}

.follow-button {
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 160px;
  height: 40px;
}
.follow {
  margin: 0 10px 0 20px;
}
#follow-spinner {
  color: $primaryColor;
}
#unfollow-spinner {
  color: #fff;
}
.following-button {
  background-color: $primaryColor;
  color: #fff;
}

.business-profile-container .banner {
  width: 100%;
  position: relative;
}

.business-profile-container .banner .shading {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(1, 13, 24, 0) 76.79%, rgba(1, 13, 24, 0.85) 100%);
}

.business-profile-container .banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.smoove-ic-container {
  background-color: $primaryColor;
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 50%;
}

.business-profile-container #profile-avatar {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 4px solid $primaryColor;
  border-radius: 6px;
  pointer-events: none;
}

.profile {
  position: relative;
}

.business-profile-container .content .profile .left {
  width: 100%;
  display: flex;
}

.business-profile-container .content .profile .res-left {
  width: 100%;
  display: flex;
}

.business-profile-container .content .profile .res-left .info {
  padding-bottom: 0 !important;
}

.business-profile-container .content .profile .left .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 15px;
  z-index: 1;
}

.business-profile-container .content .profile .left .info #name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
}

.business-profile-container .content .profile .left .info #city {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  color: #010d18;
  text-shadow: 0px 0px 4px rgba(224, 224, 224, 0.25);
}

.business-profile-container .content .profile .right {
  padding-top: 3px;
  display: flex;
  align-items: center;
}

.business-profile-container .content .profile .right .export {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.business-profile-container .content .profile .right .export img {
  width: 25px;
  height: 25px;
}

.business-profile-container .content .profile .right .save {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.business-profile-container .content .main-content {
  padding-top: 4%;
}

#tabs {
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(1, 13, 24, 0.1);
  border-radius: 6px;
  background: #ffffff;
  margin-right: 10px;
  min-height: 0;
  button {
    min-width: 150px !important;
    text-transform: capitalize;
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    text-align: center;
    border: 1px solid #e0e0e0;
    &:global(.Mui-selected) {
      border-radius: 5px;
      color: #fff;
      background: $primaryColor;
    }
  }
}

#tabs {
  div {
    span {
      background-color: #00000000;
    }
  }
}

.my-tab-container {
  padding-top: 2%;
  padding-bottom: 3%;
}

.my-tab-container .left {
  position: sticky;
}

.my-tab-container .right {
  margin-right: 10px;
}

.my-tab-container .reach-us {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.my-tab-container .reach-us svg {
  color: $primaryColor;
}

.my-tab-container .reach-us #location-icon {
  color: #ff0000;
}

.my-tab-container .reach-us .label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: #010d18;
}

.my-tab-container .reach-us .item {
  padding: 3%;
  display: flex;
  align-items: center;
}

.my-tab-container .reach-us .item span {
  padding-left: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #000000;
  max-width: 100%;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  word-break: break-all;
}

.map {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  position: relative;
}

.slider-container {
  display: none;
}
.my-tab-container .reach-us {
  padding: 5px;
}
.my-tab-container .reach-us .label {
  font-size: 14px;
}
.my-tab-container .reach-us .item {
  padding: 1%;
}
.my-tab-container .reach-us .item span {
  font-size: 12px;
}
.my-tab-container .reach-us img {
  width: 17px;
  height: 17px;
}
.my-tab-container .reach-us .sm {
  width: 15px;
  height: 15px;
}
.business-profile-container .main-content > .MuiTabs-root {
  min-height: 30px;
}
#tabs {
  button {
    font-size: 14px;
    min-height: 30px !important;
  }
}

.business-profile-container .content .profile .right .export {
  margin-right: 10px;
}
.business-profile-container .content .profile .left {
  flex-direction: column;
  margin-top: -60px;
  text-align: center;
}
.business-profile-container .content .profile .res-left {
  flex-direction: column;
  margin-top: -60px;
  text-align: center;
}
.business-profile-container .content .profile .right {
  justify-content: center;
}
.business-profile-container .banner {
  height: 220px;
}
.business-profile-container #profile-avatar {
  width: 120px;
  height: 120px;
}
.business-profile-container #profile-badge {
  width: 32px;
  height: 32px;
}

.restaurant-status {
  position: relative;
  display: flex;
  align-items: center;
}

.restaurant-status .wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
}

.restaurant-status img {
  width: 100px;
  padding-bottom: 3px;
}

.restaurant-status .label {
  position: absolute;

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
}

.slider-container .view-more {
  width: 100%;
  padding-top: 3%;
  display: flex;
  text-align: right;
}

.slider-container .view-more #label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #010d18;
  flex: 1;
  padding-right: 1%;
  cursor: pointer;
  margin-top: 3px;
}

.slider-container .view-more img {
  width: 18px;
  height: 12px;
  cursor: pointer;
}

.alert-success {
  background-color: $secondaryColor !important;
}

@media (min-width: 0) {
  .my-tab-container .map {
    height: 300px;
  }
  .restaurant-status {
    justify-content: center;
  }
  .business-profile-container .content .profile .left .info {
    padding-left: 0;
    padding-top: 20px;
  }
  .business-profile-container .content .profile .left .info #name {
    font-size: 22px;
    color: #010d18;
  }
  .business-profile-container .content .profile .left .info #city {
    font-size: 14px;
  }

  .slider-container {
    display: none;
  }
  .left {
    padding-right: 10px;
  }
  .business-profile-container .content .profile .right {
    padding-right: 15px;
  }
}

@media (min-width: 400px) {
}
@media (min-width: 600px) {
  .my-tab-container .map {
    height: 100%;
  }
  .my-tab-container .reach-us {
    padding: 5px;
  }
  .my-tab-container .reach-us .label {
    font-size: 15px;
  }
  .my-tab-container .reach-us .item {
    padding: 3%;
  }
  .my-tab-container .reach-us .item span {
    font-size: 13px;
  }
  .my-tab-container .reach-us img {
    width: 22px;
    height: 22px;
  }
  .my-tab-container .reach-us .sm {
    width: 17px;
    height: 17px;
  }
  .business-profile-container .main-content > .MuiTabs-root {
    min-height: 48px;
  }
  #tabs {
    button {
      font-size: 15px;
      height: 48px !important;
      min-height: 48px !important;
    }
  }

  .business-profile-container .content .profile .right .export {
    margin-right: 20px;
  }
  .business-profile-container .content .profile .left {
    margin-top: -80px;
  }
  .business-profile-container .content .profile .res-left {
    margin-top: -80px;
  }
  .business-profile-container .banner {
    height: 300px;
  }
  .business-profile-container #profile-avatar {
    width: 165px;
    height: 165px;
  }
  .business-profile-container #profile-badge {
    width: 40px;
    height: 40px;
  }
  .business-profile-container .content .profile .left .info #name {
    font-size: 24px;
  }
  .business-profile-container .content .profile .left .info #city {
    font-size: 15px;
  }
}

@media (min-width: 900px) {
  .restaurant-status {
    justify-content: left;
  }

  .my-tab-container .left {
    top: -70%;
  }
  .my-tab-container .map {
    height: 300px;
  }
  .slider-container {
    display: block;
  }
  .my-tab-container .reach-us {
    padding: 10px;
  }
  .my-tab-container .reach-us .label {
    font-size: 17px;
  }
  .my-tab-container .reach-us .item span {
    font-size: 15px;
  }
  .my-tab-container .reach-us img {
    width: 24px;
    height: 24px;
  }
  .my-tab-container .reach-us .sm {
    width: 19px;
    height: 19px;
  }
  #tabs {
    button {
      font-size: 17px;
    }
  }
  .business-profile-container .content .profile .res-left {
    margin-top: -55px !important;
    flex-direction: row;
    text-align: left;
  }
  .business-profile-container .content .profile .left {
    flex-direction: row;
    margin-top: -95px;
    text-align: left;
  }
  .business-profile-container .content .profile .right {
    justify-content: flex-end;
  }
  .business-profile-container .banner {
    height: 350px;
  }
  .business-profile-container #profile-avatar {
    width: 158px;
    height: 158px;
  }
  .business-profile-container #profile-badge {
    width: 43px;
    height: 43px;
  }
  .business-profile-container .content .profile .left .info {
    padding-top: 60px;
    padding-left: 30px;
  }
  .business-profile-container .content .profile .left .info #name {
    font-size: 24px;
    color: #010d18;
  }
  .business-profile-container .content .profile .left .info #city {
    font-size: 17px;
    padding: 5px 0;
  }
  .left {
    padding-right: 0;
  }

  .business-profile-container .content .profile .right {
    position: absolute;
    right: 5px;
    padding-right: 0;
  }
}
@media (min-width: 1000px) {
  .my-tab-container .left {
    top: -50%;
  }
  .my-tab-container .reach-us {
    padding: 10px;
  }
  .my-tab-container .reach-us .label {
    font-size: 18px;
  }
  .my-tab-container .reach-us .item span {
    font-size: 16px;
  }
  .my-tab-container .reach-us img {
    width: 25px;
    height: 25px;
  }
  .my-tab-container .reach-us .sm {
    width: 20px;
    height: 20px;
  }
  #tabs {
    button {
      font-size: 18px;
    }
  }
  .business-profile-container .banner {
    height: 360px;
  }
  .business-profile-container #profile-avatar {
    width: 160px;
    height: 160px;
  }
  .business-profile-container #profile-badge {
    width: 45px;
    height: 45px;
  }
  .business-profile-container .content .profile .left .info #name {
    font-size: 28px;
  }
  .business-profile-container .content .profile .left .info #city {
    font-size: 18px;
  }
}
