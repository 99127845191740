@import "../../css/variable.scss";

.container {
    width: 100%; 
    height: 100vh; 
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.content {
    position: relative;
}

.text-box {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 320px;
    width: 100%;
}

.text-box h2 {
    color: $grayColor;
    font-size: 20px;
    padding-bottom: 5px;
    margin: 0;
    text-transform: capitalize;
}

.text-box p {
    color: $grayColor;
}

.offline-img {
    width: 100%; 
    height: 100%;
    object-fit: contain;
}

#btn {
    font-size: 16px;
    text-transform: capitalize;
    background-color: $primaryColor;
    color: $whiteColor;
    padding: 6px 12px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0px) {
    .content {
        width: 100%;
    }

    .offline-img {
        display: none;
    }

    .text-box p {
        margin: 5px 0;
    }

    #btn {
        margin-top: 20px;
    }
}

@media screen and (min-width: 400px) {
    .offline-img {
        display: block;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .content {
        width: 90%;
    }

    .text-box p {
        margin: 10px 0;
    }

    #btn {
        margin-top: 30px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .content {
        width: 80%;
    }

    .text-box h2 {
        font-size: 20px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .content {
        position: relative;
        width: 70%;
    }

    .text-box h2 {
        font-size: 20px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .content {
        position: relative;
        width: 60%;
    }

    .text-box h2 {
        font-size: 25px;
    }
}