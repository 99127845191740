@import "./../../../../css/variable.scss";

.about-tab-container #icon {
  width: 20px;
  height: 20px;
  filter: invert(75%) sepia(34%) saturate(267%) hue-rotate(116deg) brightness(89%) contrast(91%);
  -webkit-filter: invert(75%) sepia(34%) saturate(267%) hue-rotate(116deg) brightness(89%) contrast(91%);
}

.about-tab-container .description {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 2% 3%;
  margin-bottom: 3%;
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}

.about-tab-container .description #title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  color: #010d18;
  padding-bottom: 2%;
  white-space: pre-line;
}

.about-tab-container .description #des {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  color: #010d18;
  white-space: pre-line;
  width: 100%;
}

.about-tab-container .features {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 2%;
}

.about-tab-container .features #title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: #010d18;
}

.about-tab-container .features #features-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: #010d18;
}

.about-tab-container .features #features-title-padding {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: #010d18;
  padding-top: 2%;
}

.about-tab-container .features #label {
  font-family: "Roboto";
  font-style: normal;
  /* font-weight: bold; */
  line-height: 19px;
  color: #000000;
  padding-left: 10px;
}

.about-tab-container .features .item {
  display: flex;
  align-items: center;
  min-width: 150px;
}

.about-tab-container .features #text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #000000;
}

.about-tab-container .features .resto-feature {
  display: flex;
  align-items: center;
  padding-top: 2%;
}

.about-tab-container .features .resto-feature #feature {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #000000;
  padding-left: 10px;
}

.about-tab-container #nearby-landmarks {
  background: #ffffff;
  border: 1px solid #ced2d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 3% 0;
  padding-bottom: 3%;
  margin-right: -16%;
}

.about-tab-container #nearby-landmarks #title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: #010d18;
  padding: 2%;
}

.about-tab-container {
  #nearby-landmarks {
    button {
      min-width: 33px !important;
      min-height: 33px !important;
    }
  }
}

.about-tab-container #nearby-landmarks .nearby-landmarks-item {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 6px;
  cursor: pointer;
  aspect-ratio: 5 / 6;
}

.about-tab-container #nearby-landmarks .nearby-landmarks-item .icon {
  position: absolute;
  z-index: 1;
  right: 7px;
  top: 15px;
  cursor: pointer;
  background: $primaryColor;
}

.about-tab-container #nearby-landmarks .nearby-landmarks-item .icon:hover {
  background: $primaryColor;
}

.about-tab-container #nearby-landmarks .nearby-landmarks-item .article-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 8px;
  border-radius: 6px;
  object-fit: cover;
  border: 1px solid #ced2d9;
}

.about-tab-container #nearby-landmarks .nearby-landmarks-item #description {
  position: absolute;
  bottom: 0;
  padding: 15px 5px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%) !important;
}

.about-tab-container #nearby-landmarks .nearby-landmarks-item #description span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.feature_done_icon {
  font-size: 17px !important;
  color: $primaryColor;
}

.popular-articles-item {
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  aspect-ratio: 5 / 7;
}

.popular-articles-item #protected-img-box {
  position: relative;
}
/* 
  .popular-articles-item #protected-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  } */

.popular-articles-item .icon {
  position: absolute;
  top: 5%;
  z-index: 1;
  right: 6px;
  cursor: pointer;
  background: $primaryColor;
}

.popular-articles-item .icon:hover {
  background: $primaryColor;
}

.save-icon-style {
  fill: #fff !important;
}

.popular-articles-item .article-image {
  width: 100%;
  height: calc(100% - 9px);
  position: absolute;
  border-radius: 6px;
  object-fit: cover;
  border: 1px solid #ced2d9;
  pointer-events: none;
}

.popular-articles-item #description {
  position: absolute;
  width: 100%;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.popular-articles-item #description span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.alert-success {
  background-color: $secondaryColor !important;
}

.nomore-data-info {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
  font-weight: bold;
}

.nearby-landmark-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  background-color: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin: 2%;
  z-index: 5;
}

#cuisines-icon {
  color: $primaryColor;
}

@media (min-width: 0) {
  .popular-articles-item #description {
    bottom: 5px;
  }
  .popular-articles-item {
    margin-left: 2px;
    margin-right: 2px;
  }
  .popular-articles-item #description {
    padding: 5px;
  }
  .popular-articles-item #description span {
    font-size: 12px;
  }
  /* .popular-articles-item .icon {
    width: 20px;
  } */
  .popular-articles-item .article-image {
    top: 5px;
  }
  .about-tab-container .features #text {
    font-size: 13px;
  }
  .review-count-label {
    font-size: 14px;
  }
  .about-tab-container .description #title {
    font-size: 16px;
  }
  .about-tab-container .description #des {
    font-size: 14px;
  }
  .about-tab-container .features #title {
    font-size: 14px;
  }
  .about-tab-container .features #label {
    font-size: 13px;
  }
  .about-tab-container .features #features-title {
    font-size: 14px;
  }

  .about-tab-container .features #features-title-padding {
    font-size: 14px;
  }

  .about-tab-container .features .resto-feature #feature {
    font-size: 12px;
  }
  .about-tab-container #nearby-landmarks {
    width: 100%;
  }
  .about-tab-container #nearby-landmarks #title {
    font-size: 14px;
  }

  .about-tab-container #nearby-landmarks .nearby-landmarks-item #description span {
    font-size: 12px;
  }

  .button {
    width: 100px;
    height: 22px;
    font-size: 11px;
  }
}

@media (min-width: 600px) {
  .popular-articles-item {
    margin-left: 3px;
    margin-right: 3px;
  }
  .popular-articles-item #description {
    padding: 10px;
  }
  .popular-articles-item #description span {
    font-size: 14px;
  }
  /* .popular-articles-item .icon {
        width: 22px;
      } */
  .popular-articles-item .article-image {
    top: 5px;
  }
  .about-tab-container .features #text {
    font-size: 13px;
  }
  .review-count-label {
    font-size: 15px;
  }
  .about-tab-container .description #title {
    font-size: 17px;
  }
  .about-tab-container .description #des {
    font-size: 15px;
  }
  .about-tab-container .features #title {
    font-size: 15px;
  }
  .about-tab-container .features #label {
    font-size: 13px;
  }
  .about-tab-container .features #features-title {
    font-size: 15px;
  }

  .about-tab-container .features #features-title-padding {
    font-size: 15px;
  }

  .about-tab-container .features .resto-feature #feature {
    font-size: 13px;
  }
  .about-tab-container #nearby-landmarks {
    width: 100%;
  }
  .about-tab-container #nearby-landmarks #title {
    font-size: 15px;
  }

  .about-tab-container #nearby-landmarks .nearby-landmarks-item #description span {
    font-size: 13px;
  }

  .button {
    width: 125px;
    height: 25px;
    font-size: 12px;
  }
}

@media (min-width: 800px) {
  .popular-articles-item #description {
    bottom: 0;
  }
  .popular-articles-item {
    margin-left: 5px;
    margin-right: 5px;
  }
  .popular-articles-item #description {
    padding: 15px 5px;
  }
  .popular-articles-item #description span {
    font-size: 16px;
  }
  /* .popular-articles-item .icon {
    width: 30px;
  } */
  .popular-articles-item .article-image {
    top: 9px;
  }

  .button {
    width: 135px;
    height: 30px;
    font-size: 12px;
  }
}

@media (min-width: 900px) {
  .popular-articles-item #description {
    bottom: 5px;
  }
  .popular-articles-item {
    margin-left: 3px;
    margin-right: 3px;
  }
  .popular-articles-item #description {
    padding: 10px;
  }
  .popular-articles-item #description span {
    font-size: 14px;
  }
  /* .popular-articles-item .icon {
    width: 22px;
  } */
  .popular-articles-item .article-image {
    top: 5px;
  }
  .about-tab-container .features #text {
    font-size: 15px;
  }
  .review-count-label {
    font-size: 17px;
  }
  .about-tab-container .description #title {
    font-size: 19px;
  }
  .about-tab-container .description #des {
    font-size: 17px;
  }
  .about-tab-container .features #title {
    font-size: 17px;
  }
  .about-tab-container .features #label {
    font-size: 15px;
  }
  .about-tab-container .features #features-title {
    font-size: 17px;
  }

  .about-tab-container .features #features-title-padding {
    font-size: 17px;
  }

  .about-tab-container .features .resto-feature #feature {
    font-size: 15px;
  }
  .about-tab-container #nearby-landmarks {
    width: auto;
  }
  .about-tab-container #nearby-landmarks #title {
    font-size: 17px;
  }

  .about-tab-container #nearby-landmarks .nearby-landmarks-item #description span {
    font-size: 15px;
  }

  .button {
    width: 150px;
    height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 1000px) {
  .popular-articles-item #description {
    bottom: 0;
  }
  .popular-articles-item {
    margin-left: 5px;
    margin-right: 5px;
  }
  .popular-articles-item #description {
    padding: 15px 5px;
  }
  .popular-articles-item #description span {
    font-size: 16px;
  }
  /* .popular-articles-item .icon {
    width: 30px;
  } */
  .popular-articles-item .article-image {
    top: 9px;
  }
  .about-tab-container .features #text {
    font-size: 16px;
  }
  .review-count-label {
    font-size: 18px;
  }
  .about-tab-container .description #title {
    font-size: 20px;
  }
  .about-tab-container .description #des {
    font-size: 18px;
  }
  .about-tab-container .features #title {
    font-size: 18px;
  }
  .about-tab-container .features #label {
    font-size: 16px;
  }
  .about-tab-container .features #features-title {
    font-size: 18px;
  }

  .about-tab-container .features #features-title {
    font-size: 18px;
  }

  .about-tab-container .features .resto-feature #feature {
    font-size: 16px;
  }
  .about-tab-container #nearby-landmarks {
    width: auto;
  }
  .about-tab-container #nearby-landmarks #title {
    font-size: 18px;
  }

  .about-tab-container #nearby-landmarks .nearby-landmarks-item #description span {
    font-size: 16px;
  }

  .button {
    width: 160px;
    height: 40px;
    font-size: 16px;
  }
}
