@import "./../../css/variable.scss";

.customButton {
  border-radius: 6px;
  color: #fff;
  border: 2px solid $primaryColor;
  background-color: $primaryColor;
  text-transform: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  &:hover {
    background-color: $primaryColor;
    border: 2px solid $primaryColor;
    border-radius: 6px;
  }
}

#btn-see-all {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  text-align: center;
  color: #fff;
  background: $primaryColor;
  border-radius: 8px;
  text-transform: none;
  width: 160px;
  height: 40px;
  font-size: 16px;
}
