.highlights-container {
  margin-top: 10px !important;
  width: 100%;
}
.highlights-title {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.04em;
  color: #010d18;
}
.highlight {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  margin-top: 20px;
  box-shadow: 0 8px 8px -4px rgb(184, 200, 206);
  word-break: break-word;
}
.hightlight-label {
  position: absolute;
  font-family: 'roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 0px 4px #010d18;
}
