@import './../../../../../css/variable.scss';
#mui-card-header .MuiCardHeader-action {
  align-self: center;
}

#mui-card-header {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}

#mui-card-header .MuiCardHeader-avatar {
  margin-right: 5px;
}
#mui-card-header #more-vert-icon {
  font-size: 1.25rem;
}
.article-header-title {
  font-family: 'roboto';
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}
.attraction-header-subheader {
  display: flex;
  align-items: center;
  line-height: 1.2;
  padding-bottom: 5px;
}
.attraction-header-subheader #location {
  font-family: 'roboto';
  font-style: normal;
  line-height: 19px;
  cursor: pointer;
}
#protected-img-box {
  position: relative;
  cursor: pointer;
}

.article-media {
  width: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  object-fit: cover;
  pointer-events: none;
  aspect-ratio: 26 / 17;
}
#protected-img {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.pli-description {
  font-family: 'roboto';
  font-style: normal;
  font-weight: normal;
  line-height: 157.69%;
  align-items: center;
  text-align: justify;
  color: #010d18;
  cursor: pointer;
}

.pli-description textarea {
  font-family: 'roboto';
  font-style: normal;
  font-weight: normal;
  line-height: 157.69%;
  align-items: center;
  color: #010d18;
  cursor: pointer;
}

.pli-description a {
  cursor: pointer;
  color: #3794eb;
}
#article-footer {
  padding-left: 0;
  padding-right: 0;
}
.save-icon-button {
  width: 40px;
  height: 40px;
  background-color: $primaryColor !important;
}
.mui-save-icon-style {
  color: #ffffff;
  cursor: pointer;
}

.mui-save-icon-style-filled {
  color: #fff;
  cursor: pointer;
}
#article-footer-span {
  font-family: 'roboto';
  font-style: normal;
  line-height: 18px;
}
.alertColor {
  width: 100%;
  background-color: #e68b2e !important;
}

.sharedStyle {
  padding-left: 8%;
  padding-right: 8%;
  margin-bottom: 10px;
  box-shadow: none !important;
  background-color: rgba(185, 209, 231, 0.15);
}
.root {
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 3%;
  border: 1px solid $muteColor;
  box-shadow: 0px 0px 4px rgba(1, 13, 24, 0.1) !important;
  //margin-right: theme.spacing(13),
}
.header {
  padding: 0 !important;
}
.noHorizontalPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdownStyle {
  margin-right: -12px;
  align-self: center;
}
.menuItemStyle {
  display: flex;
}
.menuIconSm {
  width: 13px;
}
.menuIconStyle {
  width: 17px;
}
.articleTitleContainer {
  text-align: center;
}
.actionIconSm {
  cursor: pointer;
}
.shareIcon {
  flex: 1;
  display: flex;
}
.city {
  padding-right: 10px;
}
.content {
  padding: 0 !important;
  cursor: pointer;
}

@media (min-width: 0) {
  #mui-card-header {
    padding-top: 10px;
  }
  #attr-typo {
    line-height: 1 !important;
    padding-top: 12px !important;
  }
  .article-header-title {
    font-size: 13px;
  }
  .attraction-header-subheader #location {
    font-size: 11px;
  }
  #mui-rating {
    font-size: 1rem;
    align-self: flex-end;
    padding-top: 5px;
  }
  .pli-description {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .pli-description textarea {
    font-size: 12px;
    padding-bottom: 0;
  }
  #article-footer {
    padding-top: 7px;
    padding-bottom: 12px;
  }
  #article-footer-span {
    font-size: 10px;
  }
}

@media (min-width: 441px) {
  .article-header-title {
    font-size: 14px;
  }
  .attraction-header-subheader #location {
    font-size: 13px;
  }
  .pli-description {
    font-size: 13px;
  }
  .pli-description textarea {
    font-size: 13px;
  }
  #article-footer-span {
    font-size: 12px;
  }
}
@media (min-width: 600px) {
  #mui-card-header #more-vert-icon {
    font-size: 1.3rem;
  }
  #mui-card-header {
    padding-top: 15px;
  }
  
  .article-header-title {
    font-size: 16px;
  }
  .attraction-header-subheader #location {
    font-size: 15px;
  }
  #mui-rating {
    font-size: 1.09rem;
  }
  .pli-description {
    font-size: 15px;
  }
  .pli-description textarea {
    font-size: 15px;
  }
  #article-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #article-footer-span {
    font-size: 14px;
  }
}

@media (min-width: 800px) {
  #mui-card-header .MuiCardHeader-avatar {
    margin-right: 10px;
  }
  #mui-card-header #more-vert-icon {
    font-size: 1.5rem;
  }
  #attr-typo {
    line-height: 1.334 !important;
  }
  .article-header-title {
    font-size: 18px;
  }
  .attraction-header-subheader #location {
    font-size: 16px;
  }
  .pli-description {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pli-description textarea {
    font-size: 16px;
    /* padding-bottom: 10px; */
  }
  #article-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #article-footer-span {
    font-size: 15px;
  }
}

@media (min-width: 900px) {
  #mui-card-header #more-vert-icon {
    font-size: 1.3rem;
  }
  #mui-card-header {
    padding-top: 15px;
  }
  #attr-typo {
    line-height: 1.334 !important;
  }
  .article-header-title {
    font-size: 16px;
  }
  .attraction-header-subheader #location {
    font-size: 15px;
  }
  #mui-rating {
    font-size: 1.09rem;
  }
  .pli-description {
    font-size: 15px;
  }
  .pli-description textarea {
    font-size: 15px;
  }
  #article-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #article-footer-span {
    font-size: 14px;
  }
}

@media (min-width: 1000px) {
  #mui-card-header .MuiCardHeader-avatar {
    margin-right: 10px;
  }
  #mui-card-header #more-vert-icon {
    font-size: 1.5rem;
  }
  #attr-typo {
    line-height: 1.334 !important;
  }
  .article-header-title {
    font-size: 18px;
  }
  .attraction-header-subheader #location {
    font-size: 16px;
  }
  .pli-description {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pli-description textarea {
    font-size: 16px;
    /* padding-bottom: 10px; */
  }
  #article-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #article-footer-span {
    font-size: 15px;
  }
}
