@import './../../../../css/variable.scss';

.gallery-tab-container #gallery-tabs {
  margin-top: -2%;
}

.gallery-tab-container #gallery-tabs .MuiTabs-indicator {
  display: none;
}

#gallery-tabs {
  &:global(.MuiButtonBase-root) {
    text-transform: capitalize;
    font-family: 'roboto';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    color: #6d787e;
    opacity: 1;
    border-bottom: 1px solid #e0e0e0;
  }
}

.gallery-tab-container #gallery-tabs #indicator {
  border-bottom: 2px solid #010d18;
}

.tabHeader {
  text-transform: capitalize !important;
}

#gallery-tabs {
  div {
    span {
      background-color: #00000000;
    }
  }
}

.gallery-tab-container #gallery-tabs .Mui-selected {
  font-weight: bold;
  color: #010d18;
}

.gallery-tab-container .photos-grid-container img {
  border-radius: 6px;
  cursor: pointer;
}

.gallery-tab-container .videos-grid-container .videos-grid {
  padding: 30px 0;
}

.gallery-tab-container .videos-grid-container .video-item {
  position: relative;
  cursor: pointer;
  background-color: #010d18;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin: 1% 0;
}

.gallery-tab-container .videos-grid-container .video-item video {
  width: 100%;
}

.gallery-tab-container .videos-grid-container .video-item img {
  position: absolute;
}

.gallery-tab-container .albums-tab-container {
  padding-top: 2%;
}

.gallery-tab-container .albums-tab {
  padding-top: 2%;
  padding-bottom: 2%;
}

.gallery-tab-container .albums-tab-container .info {
  padding: 2% 5%;
}

.gallery-tab-container .albums-tab-container .info #name {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  color: #010d18;
}

.gallery-tab-container .albums-tab-container .info #count {
  font-family: 'roboto';
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #6d787e;
}

@media (min-width: 0) {
  .gallery-tab-container .albums-tab-container .info #name {
    font-size: 14px;
  }
  .gallery-tab-container .albums-tab-container .info #count {
    font-size: 12px;
  }
  .gallery-tab-container .albums-tab-container .info {
    padding: 3px 6px;
  }
  .gallery-tab-container #gallery-tabs .MuiButtonBase-root {
    font-size: 13px;
    min-height: 25px;
    min-width: 80px;
  }
  .gallery-tab-container #gallery-tabs {
    min-height: 25px;
  }
}

@media (min-width: 600px) {
  .gallery-tab-container .albums-tab-container .info {
    padding: 6px 13px;
  }
  .gallery-tab-container .albums-tab-container .info #name {
    font-size: 16px;
  }
  .gallery-tab-container .albums-tab-container .info #count {
    font-size: 14px;
  }
  .gallery-tab-container #gallery-tabs .MuiButtonBase-root {
    font-size: 15px;
    min-width: 90px;
  }
}

@media (min-width: 900px) {
  .gallery-tab-container .albums-tab-container .info #name {
    font-size: 17px;
  }
  .gallery-tab-container .albums-tab-container .info #count {
    font-size: 15px;
  }
  .gallery-tab-container #gallery-tabs .MuiButtonBase-root {
    font-size: 17px;
  }
}

@media (min-width: 1000px) {
  .gallery-tab-container .albums-tab-container .info #name {
    font-size: 18px;
  }
  .gallery-tab-container .albums-tab-container .info #count {
    font-size: 16px;
  }
  .gallery-tab-container #gallery-tabs .MuiButtonBase-root {
    font-size: 18px;
  }
}
