@import '@fontsource/sarina';
@import '@fontsource/roboto';
@import '../../../../css/variable.scss';

/* Common */
#description-box {
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.read-more {
  cursor: 'pointer';
}

/* AttractionListItme & ArticleListItem */
#filtered-result-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}

#filtered-result-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  line-height: 1.5;
  color: #000000;
  cursor: pointer;
  text-align: left;
  padding-bottom: 5px;
}

.filtered-items{
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.at-icon{
  width: 30px;
  display: flex;
  align-items: center;
}

#attraction-icon-c {
  font-size: 22px;
  color: $primaryColor;
}

.filtered-result-info-icon {
  display: flex;
}

#filtered-result-info {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  word-break: break-all;
  text-transform: capitalize;
  width: 100%;
}

#filtered-result-description {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  color: #010d18;
  text-align: left;
  padding-top: 2%;
}

/* BusinessListItem */
#filtered-result-business-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #000000;
  line-height: 1.2;
  cursor: pointer;
  padding-top: 1%;
}

#filtered-result-business-city {
  font-family: 'Roboto';
  font-style: normal;
  text-transform: capitalize;
}

#filtered-result-business-review {
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

#filtered-result-business-tagline {
  font-family: 'Roboto';
  font-style: normal;
  color: #010d18;
  word-break: break-all;
}

#filtered-result-business-description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  color: #010d18;
  word-break: break-all;
}

#rating{
  color: $secondaryColor;
}

@media (min-width: 0) {
  /* AttractionListItme & ArticleListItem */
  #filtered-result-root {
    display: flex;
    flex-direction: column;
  }

  #filtered-result-details {
    padding-top: 0;
  }

  #filtered-result-title {
    font-size: 18px;
  }

  #filtered-result-info, #filtered-result-description {
    font-size: 14px;
  }

  /* BusinessListItem */

  #filtered-result-business-name {
    font-size: 18px;
  }

  #filtered-result-business-city {
    font-size: 14px;
  }

  #filtered-result-business-review {
    font-size: 12px;
  }

  #filtered-result-business-tagline {
    font-size: 14px;
  }

  #filtered-result-business-description {
    font-size: 14px;
  }

  .text-blue{
    font-size: 14px;
  }
}

@media (min-width: 600px) {
  /* AttractionListItme & ArticleListItem */
  #filtered-result-root {
    display: flex;
    flex-direction: row;
  }

  #filtered-result-details {
    padding-left: 0;
    padding-top: 16px;
  }

  /* BusinessListItem */

  #filtered-result-business-review {
    font-size: 12px;
  }
}

@media (min-width: 900px) {
  /* AttractionListItme & ArticleListItem */
  #filtered-result-title {
    font-size: 20px;
  }

  #filtered-result-info, #filtered-result-description {
    font-size: 16px;
  }

  /* BusinessListItem */
  #filtered-result-business-name {
    font-size: 20px;
  }

  #filtered-result-business-city {
    font-size: 16px;
  }

  #filtered-result-business-review {
    font-size: 13px;
  }

  #filtered-result-business-tagline {
    font-size: 16px;
  }

  #filtered-result-business-description {
    font-size: 16px;
  }

  .text-blue{
    font-size: 16px;
  }
}

@media (min-width: 1000px) {

  /* BusinessListItem */

  #filtered-result-business-review {
    font-size: 14px;
  }
}
