@import '@fontsource/sarina';
@import '@fontsource/roboto';
@import './../../css/variable.scss';

.spinner {
  padding-top: 15px;
  text-align: center;
}

.nomore-data-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
  padding-top: 15px;
}

#list-root {
  min-height: 100vh;
  padding-top: 80px;
  background: #e5e5e5;
}

#listing-filter-card {
  padding: 16px;
  position: sticky !important;
  top: 80px;
}

#listing-filter-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.04em;
  color: #000000;
}

// #rating-button-group {
//     border: 0.5px solid $primaryColor;
// }

#button {
  border: 0.2px solid $primaryColor !important;
  color: rgba(0, 0, 0, 0.5) !important;
  text-transform: Capitalize !important;
}

#button-active {
  background: $primaryColor !important;
  border: 0.2px solid $primaryColor !important;
  color: #ffffff !important;
  text-transform: Capitalize !important;
}

#listing-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #000000;
  padding-bottom: 15px;
}

#listing-filter-placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
}

#listing-filter-menu-item {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  color: #000000;
}

@media (min-width: 0) {
  #listing-title {
    font-size: 22px;
  }

  #listing-filter-title {
    font-size: 15px;
  }

  #listing-filter-placeholder {
    font-size: 13px;
  }

  #listing-filter-menu-item {
    font-size: 13px;
  }
}

@media (min-width: 600px) {
  #listing-title {
    font-size: 24px;
  }

  #listing-filter-title {
    font-size: 17px;
  }

  #listing-filter-placeholder {
    font-size: 15px;
  }

  #listing-filter-menu-item {
    font-size: 15px;
  }
}

@media (min-width: 900px) {
  #listing-title {
    font-size: 25px;
  }

  #listing-filter-title {
    font-size: 18px;
  }

  #listing-filter-placeholder {
    font-size: 16px;
  }

  #listing-filter-menu-item {
    font-size: 16px;
  }
}

@media (min-width: 1000px) {
  #listing-title {
    font-size: 25px;
  }

  #listing-filter-title {
    font-size: 18px;
  }

  #listing-filter-placeholder {
    font-size: 16px;
  }

  #listing-filter-menu-item {
    font-size: 16px;
  }
}
