@import "../../css/variable.scss";

#media-viewer-dialog {
  max-width: none !important;
  max-height: none !important;
  position: relative;
  height: auto;
}

#media-viewer-dialog .MuiCardHeader-avatar {
  margin-right: 5px;
}

.arrow-icon {
  color: white;
  background-color: $primaryColor;
  border-radius: 20px;
  font-size: 25px !important;
}

.badge {
  width: 20px;
  height: 20px;
}

.closeButton {
  position: absolute !important;
  right: 5px;
  top: 5px;
  color: #000 !important;
}

.downloadBtn {
  position: absolute !important;
  right: 55px;
  top: 5px;
  color: #000 !important;
  padding: 8px;
}

.media-viewer {
  display: flex;
  flex-direction: column;
}

.media-viewer .header #card-header .MuiCardHeader-action {
  align-self: center;
  cursor: pointer;
}

#business-avatar-lg {
  cursor: pointer;
  border: 2px solid $primaryColor;
}

.media-viewer .media-item-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 2fr auto;
}

.media-viewer .media-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#media-img {
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
  pointer-events: none;
}

.media-viewer .media-item video {
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: scale-down;
  border-radius: 6px;
}

.media-viewer .media-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-viewer .media-view #arrow-icon-left {
  position: absolute;
  left: 10px;
  padding: 5px 1px 5px 5px;
}

.media-viewer .media-view #arrow-icon-right {
  position: absolute;
  right: 10px;
  padding: 5px;
}

.media-viewer .media-view .arrow-icon .empty-div {
  //   width: 30px;
  //   height: 30px;
  margin: 0 20px 20px 20px;
}

.media-viewer .header .user-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.media-viewer .media-menu-icon {
  text-align: right;
  padding-right: 7vw;
  display: flex;
  justify-content: right;
}

.media-viewer .media-menu-icon > svg {
  cursor: pointer;
}

#create-post-profile-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  color: #010d18;
  line-height: 1;
}
.media-menu {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: #6d787e;
}

.media-menu > svg {
  margin-right: 0.5vw;
}
.media-item #spinner {
  position: absolute;
}

.card-media-caption-lg {
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
  padding-top: 10px;
}

.badge {
  width: 20px;
  height: 20px;
}
.closeButton {
  position: absolute !important;
  right: 5px;
  top: 5px;
  color: #000 !important;
}

.downloadBtn {
  position: absolute !important;
  right: 55px;
  top: 5px;
  color: #000 !important;
  padding: 8px !important;
}

@media screen and (max-width: 600px) {
  #media-viewer-dialog {
    display: flex;
    justify-content: center;
  }

  .media-item-container {
    height: 320px;
  }
}

@media (min-width: 0) {
  .media-viewer .media-view {
    width: 80vw;
  }
  .media-menu {
    font-size: 12px;
  }
  #create-post-profile-name {
    font-size: 15px;
  }
  .media-viewer .header #card-header {
    padding: 10px 60px 0px 20px;
  }
  .arrow-icon {
    // width: 35px;
    // height: 35px;
    margin: 0 10px 10px 10px;
    cursor: pointer;
  }
  .media-viewer .media-view {
    width: 77vw;
  }
  #long-menu .MuiMenuItem-root {
    min-height: 30px;
    font-size: 14px;
  }
  #business-avatar-lg {
    width: 40px;
    height: 40px;
  }

  #create-post-badge {
    width: 17px;
    height: 17px;
  }

  #media-img {
    width: 100%;
    height: 200px;
  }

  .media-viewer .media-view {
    padding: 35px 20px;
  }
}

@media (min-width: 600px) {
  .media-menu {
    font-size: 14px;
  }
  #create-post-profile-name {
    font-size: 16px;
  }
  .media-viewer .header #card-header {
    padding: 35px 80px 0px 35px;
  }
  .arrow-icon {
    // width: 40px;
    // height: 40px;
    margin: 0 25px 25px 25px;
    cursor: pointer;
  }
  #long-menu .MuiMenuItem-root {
    min-height: 38px;
    font-size: 15px;
  }
  #business-avatar-lg {
    width: 45px;
    height: 45px;
  }
  #create-post-badge {
    width: 18px;
    height: 18px;
  }

  #media-img {
    width: 65vw;
    height: 40vh;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #media-viewer-dialog {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .media-view {
    min-height: 420px;
  }
}

@media (min-width: 900px) {
  .media-menu {
    font-size: 15px;
  }
  #create-post-profile-name {
    font-size: 17px;
  }
  #long-menu .MuiMenuItem-root {
    min-height: 48px;
    font-size: 16px;
  }
  #business-avatar-lg {
    width: 50px;
    height: 50px;
  }
  #create-post-badge {
    width: 20px;
    height: 20px;
  }

  #media-img {
    width: 65vw;
    height: 65vh;
  }
}

@media (min-width: 1000px) {
  .media-menu {
    font-size: 16px;
  }
  #create-post-profile-name {
    font-size: 18px;
  }
  #business-avatar-lg {
    width: 50px;
    height: 50px;
  }
  #create-post-badge {
    width: 20px;
    height: 20px;
  }

  .card-media-caption-lg {
    padding: 10px 15% 0;
  }
}
