@import "@fontsource/sarina";
@import "@fontsource/roboto";
@import "./../../../../css/variable.scss";

/* Common */
.media-view-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px !important;
}

#title-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $cardColor;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 15px 5px;
}

/* Media Section - All Mode */
#all-media-root {
  position: relative;
  border-radius: 4px;
  cursor: pointer;
}

#inner-box {
  position: relative;
  border-radius: 4px;
}

#all-media-root #title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.04em;
  color: #fff;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#filtered-result-media-info {
  flex: 1;
  text-align: right;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  color: #fff;
}

/* Media Section - List Mode */
#filtered-result-rootbox {
  position: relative;
  border-radius: 4px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#listing-save-icon {
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 2;
  cursor: pointer;
  background: $primaryColor;
}

.save-icon-style {
  fill: #fff !important;
}

#inner-box-lg {
  position: relative;
  border-radius: 4px;
}

#inner-title-Box {
  width: 100%;
  display: flex;
  align-items: center;
}

@media (min-width: 0) {
  /* Media Section - All Mode */
  #all-media-root {
    height: 125px;
  }

  #inner-box {
    height: 125px;
  }

  #all-media-root #title {
    font-size: 12px;
  }

  #inner-box-lg {
    width: 100%;
    height: 170px;
  }

  #filtered-result-media-info {
    font-size: 14px;
  }
}

@media (min-width: 600px) {
  /* Media Section - All Mode */
  #all-media-root {
    height: 140px;
  }

  #inner-box {
    height: 140px;
  }

  #all-media-root #title {
    font-size: 14px;
  }

  #inner-box-lg {
    width: 250px;
    height: 170px;
  }
}

@media (min-width: 900px) {
  /* Media Section - All Mode */
  #all-media-root {
    height: 150px;
  }

  #inner-box {
    height: 150px;
  }

  #all-media-root #title {
    font-size: 14px;
  }

  #inner-box-lg {
    width: 280px;
    height: 180px;
  }

  #filtered-result-media-info {
    font-size: 16px;
  }
}

@media (min-width: 1000px) {
  /* Media Section - All Mode */
  #all-media-root {
    height: 170px;
  }

  #inner-box {
    height: 170px;
  }

  #all-media-root #title {
    font-size: 15px;
  }

  #inner-box-lg {
    width: 300px;
    height: 200px;
  }
}
