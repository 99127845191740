@import "../../css/variable.scss";

#app-bar-container {
  padding: 0 !important;
}

.homeWappper {
  background-color: $primaryColor !important;
}

.toolbar {
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  justify-content: space-between;
}

/* Logo */
.logo-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.logo-container img {
  cursor: pointer;
  width: auto;
}

/* Search */
.searchBox {
  margin-right: 15px;
  align-self: center;
}

.searchIcon {
  color: #ffffff !important;
}

.inputBaseStyles {
  background-color: rgba(255, 255, 255, 0.113);
  &:hover {
    background-color: rgba(255, 255, 255, 0.205);
  }

  border-radius: 10px;
}

#css-text-box {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 10px 10%;
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $primaryColor;
  width: 100vw;
}

/* Language */
.switchContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  margin-right: 10px;
  padding-left: 10px;
}

.switchLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 1.1;
}

/* Account */
.guest-account {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
}

.accountIcon {
  cursor: pointer;
}

.accountLabel {
  margin-left: 1px;
  line-height: 1.4;
  cursor: pointer;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.post_avatar {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid $grayColor !important;
  cursor: pointer;
}

.badge {
  width: 20px;
  height: 20px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.bloggerAvatar {
  width: 36px !important;
  height: 36px !important;
  cursor: pointer;
  border: 2px solid #fa3f58;
}

/* Notification */

.notification_box {
  min-height: 400px;
  max-height: 85vh;
  border-radius: 8px;
  padding: 10px;
}

/* Menu */
.menuIcon {
  color: $primaryColor;
  min-width: 38px !important;
}

.popover_text_selected {
  color: #010d18;
}

.popover_text_default {
  color: #010d18;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

#app-bar-icon-btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

#app-bar-icon-btn:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0) {
  .logo-container img {
    height: 30px;
  }

  .notification_box {
    width: 90vw;
  }
}

@media only screen and (min-width: 420px) {
  .notification_box {
    width: 450px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .logo-container img {
    height: 32px;
  }
}

@media only screen and (min-width: 992px) {
  .searchInput{
    width: 35vw !important;
  }
}


