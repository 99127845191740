@import "../../../../css/variable.scss";

#account-control-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

#account-control-box {
    display: flex;
    flex-direction: column;
}

#account-control-paper {
    display: flex;
    align-items: center;
    height: 30px;
    color: #000;
}

/* AccountOwnershipAndControl */
.radio-item {
    padding: 10px 0;
    label {
        margin: 0 !important;
        padding: 0 !important;
        width: 100%;
        justify-content: space-between;
    }
}

.radio-item:nth-child(1) {
    padding-top: 0;
}

.deactivate-radio-item {
    padding: 10px 20% 0 20%;
    label {
        margin: 0 !important;
        padding: 0 !important;
        width: 100%;
        justify-content: space-between;
    }
}

.radio-title {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 5px;
}

.radio-item-container {
    border-radius: 5px;
}

.radio-duration-item {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    label {
        margin: 0 !important;
    }
}

.radio-duration-title {
    color: #000;
    font-size: 18px;
    padding-bottom: 5px;
}

.close-button {
    position: absolute !important;
    right: 0;
    top: 0;
    color: #000 !important;
}

#account-control-btn {
    background-color: $primaryColor;
    color: #fff;
    min-width: 160px;
    height: 40px;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 16px;
    margin: 20px 0;
    align-self: center;
}

.input-items-container {
    display: grid;
}

.main-content h3 {
    font-size: 16px;
    margin: 0;
}

.main-content p {
    font-size: 16px;
    margin: 10px 0;
    color: #000;
}

.main-content ul {
    margin: 0;
    li {
        color: #000;
        font-size: 16px;
    }
}

.content-input-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 5px;
}

.content-skip {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
}

.mian-content-bottom textarea {
    width: 100%;
    resize: none;
    font-size: 16px;
    font-family: "Roboto";
    padding: 10px;
}

.main-content-btn-container {
    display: flex;
    justify-content: center;
}

#error-message {
    color: red;
    margin: 0;
}

/* Delete Deactivate Confirmation Modal */
.confirmation-dialog-title {
    text-align: center;
    margin-top: 30px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.confirmation-dialog-content {
    text-align: center !important;
}

.verification-form {
    padding: 0;
}

.input-group {
    padding-top: 15px;
}

.input-box {
    width: 100%;
}

.flag-img {
    width: 20px;
    padding-right: 2px;
}

.phone-container {
    display: flex;
}

#phone-inbox-select {
    width: 160px !important;
    border: 1px solid #bebebe;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}

.input-items {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.input-box-group {
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    width: 100%;
}

#phone-input-box {
    padding-left: 3px;
}

.loading-spinner {
    color: #fff !important;
}

.loading-spinner-modal {
    color: #f03049 !important;
}

.input-item-label {
    margin-left: -12px;
    color: #000 !important;
    line-height: 2 !important;

    span {
        color: red;
    }
}

#confirmation-dialog-btn-container {
    display: flex;
    justify-content: center !important;
    color: red;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 20px;
}

/* Social Account Verification */

.verify-social-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-icon {
    color: #fff;
    font-size: 25px;
    margin-right: 6px;
}

.verify-social-container {
    #account-control-btn {
        text-transform: none;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0px) {
    .input-item-label {
        width: 100%;
        margin-bottom: 5px;
    }
    .input-items {
        flex-direction: column;
    }

    .error-message {
        justify-self: start;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .input-item-label {
        width: 250px;
        margin-bottom: 0;
    }

    .input-items {
        flex-direction: row;
    }

    .error-message {
        justify-self: end;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
