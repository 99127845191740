@import "../../css/variable.scss";

.icon-button {
    width: 40px;
    height: 40px;
    background-color: $primaryColor !important;
}

.unsave-icon-style{
    fill: #fff !important;
}