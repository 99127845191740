@import "@fontsource/sarina";
@import "@fontsource/roboto";
@import "./../../../../css/variable.scss";

/* Business Media Section - All Mode */
#all-business-media-root {
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  height: 188px;
}

#business-inner-box {
  position: relative;
  border-radius: 4px;
  height: 188px;
}

#title-box {
  width: 90%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  padding: 0 5% 5% 5%;
}

#title-box-sm {
  width: 65%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  padding: 0 5% 5% 5%;
}

#search-business-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.04em;
  color: #fff;
}

.diagonal-box {
  width: 100%;
  height: 190px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: rgba(134, 190, 177, 0.5);
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  /* backdrop-filter: blur(2px); */
}

/* Business Media Section - List Mode */
#filtered-result-rootbox {
  position: relative;
  border-radius: 4px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#listing-save-icon {
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 2;
  cursor: pointer;
  background: $primaryColor;
}

.save-icon-style {
  fill: #fff !important;
}

#business-inner-box-lg {
  position: relative;
  border-radius: 4px;
}

.business-media-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px !important;
}

@media (min-width: 0) {
  /* Business Media Section - All Mode */
  #search-business-title {
    font-size: 12px;
  }

  /* Business Media Section - List Mode */
  #business-inner-box-lg {
    width: 100%;
    height: 190px;
  }
}

@media (min-width: 600px) {
  /* Business Media Section - All Mode */
  #search-business-title {
    font-size: 13px;
  }

  /* Business Media Section - List Mode */
  #business-inner-box-lg {
    width: 230px;
    height: 190px;
  }
}

@media (min-width: 900px) {
  /* Business Media Section - All Mode */
  #search-business-title {
    font-size: 14px;
  }
  /* Business Media Section - List Mode */
  #business-inner-box-lg {
    width: 260px;
    height: 200px;
  }
}

@media (min-width: 1000px) {
  /* Business Media Section - All Mode */
  #search-business-title {
    font-size: 15px;
  }

  /* Business Media Section - List Mode */
  #business-inner-box-lg {
    width: 280px;
    height: 220px;
  }
}
