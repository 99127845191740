@import './../../css/variable.scss';

.inbox-container {
  margin-top: 65px;
}
.mail_box {
  background-color: $primaryColor !important;
}
.inbox-title {
  padding-left: 7%;
  font-family: Roboto;
  font-size: 20px;
  /* text-align: justify; */
}

.inbox-img {
  margin-right: 10px;
  width: 20px;
  height: 15px;
}

.inbox-list {
  display: flex;
}

.inbox-listbody {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* text-align: justify; */
  color: #000000;
}

b {
  font-size: 20px;
}

.inbox-date {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
  color: #6d787e;
}
.spinner {
  text-align: center;
}

.searchBoxField {
  div {
    border: none;
  }
  div:focus {
    border: none;
  }
  div:hover {
    border: none !important;
  }
}
/* xs */
@media (min-width: 0) {
  .inbox-title {
    font-size: 14px;
    /* padding-left: 10px; */
  }
  .inbox-listbody {
    font-size: 12px;
  }
  b {
    font-size: 14px;
  }
  .inbox-date {
    font-size: 12px;
    text-align: justify;
  }
}

/* sm */
@media (min-width: 600px) {
  .inbox-title {
    font-size: 16px;
    /* padding-left: 30px; */
  }
  .inbox-listbody {
    font-size: 12px;
  }
  b {
    font-size: 16px;
  }
  .inbox-date {
    font-size: 12px;
    text-align: justify;
  }
}

/* md */
@media (min-width: 900) {
  .inbox-title {
    font-size: 18px;
    /* padding-left: 90px; */
  }
  .inbox-listbody {
    font-size: 14px;
  }
  b {
    font-size: 18px;
  }
  .inbox-date {
    font-size: 14px;
    text-align: justify;
  }
}

/* lg */
@media (min-width: 1000px) {
  .inbox-title {
    font-size: 20px;
    /* padding-left: 80px; */
  }
  .inbox-listbody {
    font-size: 16px;
  }
  b {
    font-size: 20px;
  }
  .inbox-date {
    font-size: 16px;
    text-align: justify;
  }
}
