@import "../../../css/variable.scss";

.reactIconBox {
    display: flex;
    margin-right: 8px;
}

.reactContainer {
    display: flex;
}

.reactIcon{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.reactionIconContainer {
    display: flex;
}

.reactionIconContainer:not(:nth-last-child(1)){
    padding-right: 8px;
}

.reactionPopup{
    display: flex;
    justify-content: center;
    align-items: center;
    background: $whiteColor;
    border: 1px solid $muteColor;
    padding: 6px 8px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: -10px;
    box-shadow: 0px 0px 4px rgba(1, 13, 24, 0.1);
}

.reactionIconSm,
.reactionIcon{
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    object-fit: cover;
}

.reactionIconSm:hover,
.reactionIcon:hover {
    scale: 1.1;
}