.home-root-container {
  background: #fff;
  min-height: 100vh;
}
.mainGridContainer {
  margin-top: 10px !important;
}

/* Banner Section */
.banner-container {
  max-height: 70vh;
  margin-top: 45px;
  position: relative;
  aspect-ratio: 3 / 1;
}

.banner-img {
  aspect-ratio: 3 / 1;
  object-fit: cover;
  pointer-events: none;
  width: 100%;
}

.alertColor {
  width: 100%;
  background-color: #e68b2e;
}

#sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: -20px;
}
.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: -20px;
}
.spinner {
  text-align: center;
}

.nomore-data-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
}

.nomore-data-info span {
  padding-left: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
}

.for-shalgoers-container {
  z-index: 1000;
  position: absolute;
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0px) {
  /* Banner Section */
  .banner-container {
    width: 100%;
    height: auto;
  }

  .banner-img {
    min-height: 180px;
    border-radius: 0px;
  }

  .for-shalgoers-container {
    right: 50%;
    top: 60%;
    transform: translate(50%, 50%);
  }

  .nomore-data-info span {
    font-size: 12px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .banner-img {
    height: auto;
  }

  .for-shalgoers-container {
    right: 7%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .nomore-data-info span {
    font-size: 14px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .banner-img {
    border-radius: 5px;
  }
}
