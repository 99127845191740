@import "../../../css/variable.scss";

// .reaction-header {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
// }

// .title {
//   font-weight: 900;
//   font-size: 23px;
//   margin: 0 !important;
// }

// #closeButton {
//   color: $blackColor;
//   background-color: rgba(0, 0, 0, 0.04);
// }

// #closeButton:hover {
//   background-color: rgba(0, 0, 0, 0.08);
// }

// .itemContainer{
//   overflow: auto;
//   height: calc(100% - 75px);
//   margin-top: 15px;
// }

// .reaction-list-container {
//   display: flex;
//   justify-content: center;
// }

// .detail-reaction-list-container {
//   display: flex;
//   justify-content: center;
// }

// .icon-img {
//   width: 25px;
//   height: 25px;
//   object-fit: contain;
//   margin-right: 8px;
//   margin-bottom: 0 !important;
// }

// .reaction-list {
//   height: 80vh;
//   box-sizing: border-box;
//   border-radius: 8px;
//   padding: 15px;
// }

// .list-tabs {
//   height: calc(100% - 35px);
//   overflow: hidden;
// }

// .loading-container {
//   width: 100%;
//   text-align: center;
//   margin-top: 15px;
// }

// #loading {
//   color: $primaryColor;
// }

// .infiniteScrollStyle {
//   height: 100% !important
// }

// .user-container {
//   display: flex;
//   justify-content: left;
//   align-items: center;
// }

// .user-container:not(:first-of-type) {
//   margin: 15px 0;
// }

// .user-avatar {
//   margin-right: 8px;
// }

// .user-name {
//   color: $blackColor;
// }

// .reaction-list #tabs {
//   margin-top: 10px;
//   position: sticky;
//   top: 0;
//   background-color: #f2f2ee;
//   z-index: 1;
// }

// .reaction-list #tab-panel > div {
//   max-height: 80%;
//   position: absolute;
//   overflow: scroll;
//   width: calc(100% - 25px);
// }

// .reaction-list .infinite-scroll-component__outerdiv {
//   height: 100%;
// }

// .reaction-list .infinite-scroll-component {
//   height: 100% !important;
// }

// .reaction-list #tab-item {
//   height: 100%;
// }

// /* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
//   .reaction-list {
//     width: 80vw;
//     padding: 15px;
//   }
// }

/* || REACTION LIST */

.reaction-list-container {
  position: absolute;
  top: 20vh;
  left: 50%;
  display: flex;
  justify-content: center;
}
.detail-reaction-list-container {
  position: absolute;
  top: 20vh;
  left: 39%;
  display: flex;
  justify-content: center;
}

.reaction-list {
  width: 25vw;
  height: 63vh;
  background: #f2f2ee;
  border: 1px solid #979da0;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(1, 13, 24, 0.3);
  border-radius: 8px;
  padding: 15px;
  overflow: hidden;
}

.reaction-list .title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 16px !important;
  line-height: 19px;
  color: #575757;
  margin: 0 !important;
}

.list-tabs {
  margin-top: 5px;
  height: calc(100% - 25px);
  overflow: scroll;
}

.reaction-list #tabs {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #3794eb;
  position: sticky;
  top: 0;
  background-color: #f2f2ee;
  z-index: 1;
}

.reaction-list #tab-panel > div {
  max-height: 80%;
  position: absolute;
  overflow: scroll;
  width: calc(100% - 25px);
}

.reaction-list .infinite-scroll-component__outerdiv {
  height: 100%;
}

.reaction-list .infinite-scroll-component {
  height: 100% !important;
}

.reaction-list #tab-item {
  height: 100%;
}

.reaction-list #tabs #simple-tab-1 img {
  margin-right: 5px;
  margin-bottom: 5px;
}

.reaction-list #tabs #simple-tab-2 {
  color: red;
}

.reaction-list #tabs #simple-tab-2 img {
  margin: 0 5px 0 0;
}

.reaction-list #tabs #simple-tab-3 img {
  margin: 0 5px 0 0;
}

.reaction-list #tabs span {
  flex-direction: row;
}

.reaction-list .name {
  padding-left: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #575757;
  text-align: start;
}

@media (max-width: 570px) {
  .detail-reaction-list-container,
  .reaction-list-container {
    left: 10% !important;
  }

  .reaction-list {
    width: 80vw !important;
  }
  .name {
    font-size: 14px !important;
  }
}

@media (min-width: 571px) and (max-width: 899px) {
  .detail-reaction-list-container,
  .reaction-list-container {
    left: 23% !important;
  }
}

@media (max-width: 1025px) {
  .reaction-list {
    width: 50vw;
  }
  .detail-reaction-list-container,
  .reaction-list-container {
    left: 40%;
  }
}

@media (min-width: 1026px) and (max-width: 1300px) {
  .reaction-list {
    width: 40vw !important;
  }
  .detail-reaction-list-container,
  .reaction-list-container {
    left: 40%;
  }
}
