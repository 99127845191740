.parentRoot {
  padding-left: 8%;
  padding-right: 8%;
  margin-bottom: 10px;
  background-color: rgba(185, 209, 231, 0.15);
  box-shadow: none !important;
}
.post_media {
  height: 339;
  border-radius: 10;
  object-fit: 'cover';
}
.arrowStyle {
  cursor: pointer;
}
