#album-details img {
  border-radius: 0;
}

.details-ablum-top-section {
  display: flex;
  align-items: flex-start;
  padding-top: 2%;
}

.details-ablum-top-section .left-side {
  flex: 1;
  text-align: center;
}

.details-ablum-top-section .left-side #album-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 1.5;
  color: #112a3a;
}

.details-ablum-top-section .left-side #description {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  color: #010d18;
}

.details-ablum-top-section .left-side #updated-date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: #6d787e;
}

.menu-item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: #6d787e;
}
.details-album-container {
  display: flex;
  align-items: center;
  padding-top: 2%;
}

.details-album-container #venus {
  flex: 1;
  display: flex;
  align-items: center;
}
.details-album-container #mars {
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: flex;
  align-items: center;
  text-align: right;
  color: #3794eb;
}
.grid_icon {
  border-radius: 0 !important;
}
.details-album-container img {
  cursor: pointer;
}

.details-album-name {
  flex: 1;
}

.details-album-name #label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 1.5;
  color: #010d18;
}

.details-album-name #name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #010d18;
}

@media (min-width: 0) {
  .details-ablum-top-section .left-side #album-name {
    font-size: 16px;
  }
  .details-ablum-top-section .left-side #updated-date {
    font-size: 12px;
  }
  #long-menu {
    background-color: #f2f2ee;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    .MuiMenuItem-root {
      min-height: 30px;
      font-size: 14px;
    }
  }
  .menu-item {
    font-size: 13px;
  }
  .menu-item > img {
    width: 14px;
    margin-right: 5px;
  }
  .details-album-container #mars {
    font-size: 13px;
  }

  .details-album-name #label {
    font-size: 13px;
  }
  .details-album-name #name {
    font-size: 13px;
  }
  .details-album-container img {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 400px) {
  .details-album-container img {
    width: 16px;
    height: 16px;
  }
}

@media (min-width: 600px) {
  .details-album-container #mars {
    font-size: 14px;
  }
  .details-ablum-top-section .left-side #album-name {
    font-size: 17px;
  }
  .details-ablum-top-section .left-side #updated-date {
    font-size: 13px;
  }
  #long-menu {
    background-color: #f2f2ee;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    .MuiMenuItem-root {
      min-height: 38px;
      font-size: 15px;
    }
  }
  .menu-item {
    font-size: 14px;
  }
  .menu-item > img {
    width: 16px;
    margin-right: 5px;
  }
  .details-album-container img {
    width: 16px;
    height: 16px;
  }
  .details-album-name #label {
    font-size: 15px;
  }
  .details-album-name #name {
    font-size: 15px;
  }
}

@media (min-width: 800px) {
  .details-album-container img {
    width: 16px;
    height: 16px;
  }
}

@media (min-width: 900px) {
  .details-album-container #mars {
    font-size: 15px;
  }
  .details-ablum-top-section .left-side #album-name {
    font-size: 18px;
  }
  .details-ablum-top-section .left-side #updated-date {
    font-size: 14px;
  }
  #long-menu {
    background-color: #f2f2ee;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    .MuiMenuItem-root {
      min-height: 48px;
      font-size: 16px;
    }
  }
  .menu-item {
    font-size: 15px;
  }
  .menu-item > img {
    width: 17px;
    margin-right: 10px;
  }
  .details-album-container img {
    width: 18px;
    height: 18px;
  }
  .details-album-name #label {
    font-size: 17px;
  }
  .details-album-name #name {
    font-size: 17px;
  }
}

@media (min-width: 1000px) {
  .details-album-container #mars {
    font-size: 16px;
  }
  .details-ablum-top-section .left-side #album-name {
    font-size: 19px;
  }
  .details-ablum-top-section .left-side #updated-date {
    font-size: 15px;
  }
  .menu-item {
    font-size: 16px;
  }
  .menu-item > img {
    width: 18px;
    margin-right: 10px;
  }
  .details-album-container img {
    width: 20px;
    height: 20px;
  }
  .details-album-name #label {
    font-size: 18px;
  }
  .details-album-name #name {
    font-size: 18px;
  }
}
