@import "./../../../../../../css/variable.scss";

.save-icon-style {
  fill: #fff !important;
}

.icon {
  position: absolute !important;
  z-index: 1;
  right: 7px;
  top: 15px;
  cursor: pointer !important;
  background: $primaryColor !important;
}

.icon:hover {
  background: $primaryColor;
}

.popular-articles-item {
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  aspect-ratio: 5 / 7;
  margin-bottom: 5px;
}

#protected-img-box {
  position: relative;
}
/* 
    .popular-articles-item #protected-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    } */

.icon {
  position: absolute;
  top: 5%;
  z-index: 1;
  right: 6px;
  cursor: pointer;
  background: $primaryColor;
}

.icon:hover {
  background: $primaryColor;
}

.article-image {
  width: 100%;
  height: calc(100% - 9px);
  position: absolute;
  border-radius: 6px;
  object-fit: cover;
  border: 1px solid #ced2d9;
  pointer-events: none;
}

#description {
  position: absolute;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  background: $cardColor;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

#description span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 1px 0;
}

#protected-img-box {
  position: relative;
  cursor: pointer;
}

#protected-img {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.hotels-image {
  width: 100%;
  position: absolute;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  object-fit: cover;
  border: 1px solid #ced2d9;
  pointer-events: none;
}

.hotels-description {
  padding: 15px 10px !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  border: 1px solid #ced2d9;
  box-sizing: border-box;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: none;
  text-align: right;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
}

.hotels-description .name {
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #010d18;
}
.mui-rating {
  text-align: right !important;
  padding-top: 5px;
}

#protected-img-business {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 0) {
  #description {
    bottom: 5px;
  }
  .popular-articles-item {
    margin-left: 2px;
    margin-right: 2px;
  }
  #description {
    padding: 5px;
  }
  #description span {
    font-size: 12px;
  }
  /* .popular-articles-item .icon {
      width: 20px;
    } */
  .article-image {
    top: 5px;
  }
  .hotels-image {
    top: 5px;
    height: calc(100% - 45px);
  }
  .hotels-description {
    /* height: 55px; */
    padding: 5px;
  }
  .hotels-description .name {
    font-size: 12px;
  }
}

@media (min-width: 600px) {
  .popular-articles-item {
    margin-left: 3px;
    margin-right: 3px;
  }
  #description {
    padding: 10px;
  }
  #description span {
    font-size: 14px;
  }
  /* .popular-articles-item .icon {
          width: 22px;
        } */
  .article-image {
    top: 5px;
  }
  .hotels-image {
    top: 5px;
  }
}

@media (min-width: 800px) {
  #description {
    bottom: 0;
  }
  .popular-articles-item {
    margin-left: 5px;
    margin-right: 5px;
  }
  #description {
    padding: 15px 5px;
  }
  #description span {
    font-size: 16px;
  }
  /* .popular-articles-item .icon {
      width: 30px;
    } */
  .article-image {
    top: 9px;
  }
  .hotels-image {
    top: 9px;
    height: calc(100% - 55px);
  }
  .hotels-description {
    /* height: 65px; */
    padding: 5px;
  }
  .hotels-description .name {
    font-size: 16px;
  }
}

@media (min-width: 900px) {
  #description {
    bottom: 5px;
  }
  .popular-articles-item {
    margin-left: 3px;
    margin-right: 3px;
  }
  #description {
    padding: 10px;
  }
  #description span {
    font-size: 14px;
  }
  /* .popular-articles-item .icon {
      width: 22px;
    } */
  .article-image {
    top: 5px;
  }
  .hotels-image {
    top: 5px;
    height: calc(100% - 55px);
  }
  .hotels-description {
    /* height: 65px; */
    padding: 5px;
  }
  .hotels-description .name {
    font-size: 12px;
  }
}

@media (min-width: 1000px) {
  #description {
    bottom: 0;
  }
  .popular-articles-item {
    margin-left: 5px;
    margin-right: 5px;
  }
  #description {
    padding: 15px 5px;
  }
  #description span {
    font-size: 16px;
  }
  /* .popular-articles-item .icon {
      width: 30px;
    } */
  .article-image {
    top: 9px;
  }
  .hotels-image {
    top: 9px;
    height: calc(100% - 55px);
  }
  .hotels-description {
    /* height: 65px; */
    padding: 5px;
  }
  .hotels-description .name {
    font-size: 16px;
  }
}
