@import "../../css//variable.scss";

.container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    width: 100%;
}

.img{
    max-width: 400px;
    width: 100%;
    height: auto;
}

.wrapper-group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.title{
    color: $grayColor;
}

.button{
    min-width: 270px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid $primaryColor;
    color: #fff;
    background-color: $primaryColor;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .title{
        font-size: 20px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .title{
        font-size: 25px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .title{
        font-size: 30px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .title{
        font-size: 35px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}