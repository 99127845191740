@import './../../css/variable.scss';

.floatIcon {
  width: 53px;
  cursor: pointer;
  color: white;
  font-size: 25px !important;
}
.root {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 10000;
  background-color: $primaryColor;
  padding: 6px 8px 4px 8px;
  border-radius: 22px;
}
