.end-message-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  color: #3794eb;
  padding-top: 15px;
}

.end-message {
  padding-left: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}
