.policy-container {
  margin-top: 65px;
}

.policy-title {
  margin-top: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-align: center;

  color: #000000;
}

.policy-rule {
  margin: 20px 0px;
  font-family: Roboto;
  /* font-style: normal;
    font-weight: normal; */
  font-size: 16px;
  text-align: justify;
  text-indent: 50px;
  color: #242424;
}

/* xs */
@media (min-width: 0) {
  .policy-title {
    font-size: 16px;
  }
  
  .policy-rule {
    font-size: 12px;
  }
}

/* sm */
@media (min-width: 600px) {
    .policy-rule {
    font-size: 12px;
  }
}

/* md */
@media (min-width: 900) {
  .policy-title {
    font-size: 18px;
  }

  .policy-rule {
    font-size: 14px;
  }
}

/* lg */
@media (min-width: 1000px) {
  .policy-title {
    font-size: 20px;
  }

  .policy-rule {
    font-size: 16px;
  }
}
