@import "~@fontsource/roboto";
@import "~@fontsource/roboto/100.css";
@import "~@fontsource/roboto/300.css";
@import "~@fontsource/roboto/400.css";
@import "~@fontsource/roboto/500.css";
@import "~@fontsource/roboto/700.css";
@import "~@fontsource/roboto/900.css";
@import "./variable.scss";

body {
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  background-color: #fff !important;
  font-family: "Roboto", sans-serif;
}

/* Scrollbar Style in Chrome, Edge, Safari */

/* width */
*::-webkit-scrollbar {
  width: 0.3em;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

body::-webkit-scrollbar-thumb {
  border-radius: 0px;
}

/* Scrollbar Style in Firefox */
* {
  outline-color: $secondaryColor;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.ic-service-container {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primaryColor;
}

.ic-service-container:hover .ic-service {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
}
.for-shalgoers-icon {
  color: #ffff;
  font-size: 28px !important;
  padding-top: 14px;
}
.for-shalgoers-icon-small {
  width: 65%;
  height: auto;
}

.react-multiple-carousel__arrow {
  background: $primaryColor;
}

.react-multiple-carousel__arrow:hover {
  background: $primaryColor;
}

#section-mobile .MuiAutocomplete-root {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $primaryColor;
  width: 100vw;
}

#section-mobile .MuiOutlinedInput-root {
  width: 100% !important;
}

#mui-rating {
  font-size: 1rem;
  align-self: flex-end;
  padding-top: 5px;
}

.MuiRating-iconFilled {
  color: $secondaryColor;
}
.MuiRating-iconEmpty {
  color: $secondaryColor !important;
}

.MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-active {
  color: $primaryColor;
}

.MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-completed {
  color: $primaryColor;
}

.loading_spinner {
  color: $primaryColor !important;
}

.text-gray {
  color: $grayColor !important;
  font-weight: 400 !important;
}

.text-blue {
  color: #3794eb !important;
  font-size: 16px !important;
}

.text-bold {
  font-weight: bold !important;
}

.account-control-container .MuiButtonBase-root.Mui-disabled {
  border: 1px solid $primaryColor;
  background-color: $muteColor !important;
  color: $grayColor !important;
}

.attraction-gallery .react-multi-carousel-track .react-multi-carousel-item--active {
  filter: blur(0px) !important;
  margin-top: 0% !important;
  margin-bottom: 0% !important;
}

.attraction-gallery .react-multi-carousel-track .react-multi-carousel-item {
  filter: blur(2px);
  margin-top: 2.8em;
  margin-bottom: 0.5em;
}

.attraction-gallery .react-multi-carousel-track .react-multi-carousel-item--active .img_div {
  width: 100%;
}

.attraction-gallery .react-multi-carousel-track .react-multi-carousel-item .img_div {
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0) {
  .ic-service-container {
    width: 35px;
    height: 35px;
  }

  .attraction-gallery .react-multi-carousel-track .react-multi-carousel-item--active .img_div {
    height: 220px !important;
  }
  .attraction-gallery .react-multi-carousel-track .react-multi-carousel-item .img_div {
    height: 150px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .ic-service-container {
    width: 30px;
    height: 30px;
  }
  .attraction-gallery {
    margin: 0% 10% 0% 10% !important;
  }

  .attraction-gallery .react-multi-carousel-track .react-multi-carousel-item--active .img_div {
    height: 250px !important;
  }
  .attraction-gallery .react-multi-carousel-track .react-multi-carousel-item .img_div {
    height: 170px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ic-service-container {
    width: 40px;
    height: 40px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ic-service-container {
    width: 50px;
    height: 50px;
  }

  .attraction-gallery .react-multi-carousel-track .react-multi-carousel-item--active .img_div {
    height: 270px !important;
  }
  .attraction-gallery .react-multi-carousel-track .react-multi-carousel-item .img_div {
    height: 190px;
  }

  #section-desktop input {
    width: 200px;
  }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ic-service-container {
    width: 60px;
    height: 60px;
  }

  .attraction-gallery .react-multi-carousel-track .react-multi-carousel-item--active .img_div {
    height: 300px !important;
  }
  .attraction-gallery .react-multi-carousel-track .react-multi-carousel-item .img_div {
    height: 220px;
  }
}
